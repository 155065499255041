<template>
    <div class="JsonSchemaBooleanView">
        <div class="JsonSchemaBooleanView__title">
            {{ form.schema.title }}
        </div>
        <div class="JsonSchemaTextView__valueWithError">
            <div :class="displayedValueAdditionalClass"
                class="JsonSchemaBooleanView__value"
            >
                <div class="JsonSchemaBooleanView__valueContainer">
                    <div class="JsonSchemaBooleanView__iconContainer">
                        <FontAwesomeIcon v-if="value" :icon="faTimes" />
                    </div>
                    <span>Yes</span>
                </div>
                <div class="JsonSchemaBooleanView__valueContainer">
                    <div class="JsonSchemaBooleanView__iconContainer">
                        <FontAwesomeIcon v-if="!value" :icon="faTimes" />
                    </div>
                    <span>No</span>
                </div>
            </div>
            <EvaluationError
                v-if="evaluationError && !evaluationError.isMultiFieldCriterion"
                :evaluation-error="evaluationError"
                v-on="$listeners"
            />
        </div>
    </div>
</template>

<script lang="ts">
    import { Component } from '@evidentid/vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faTimes } from '@fortawesome/free-solid-svg-icons';
    import { JsonSchemaBoolean } from '@evidentid/json-schema/interfaces/JsonSchema';
    import { RadioOption } from '@evidentid/dashboard-commons/components/RadioOption';
    import { JsonFormBase } from '@evidentid/json-schema/interfaces/JsonForm';
    import EvaluationError from '@/modules/entity-details/components/EvaluationError/EvaluationError.vue';
    import AbstractJsonSchemaView
        from '@/modules/entity-details/components/JsonSchemaView/elements/AbstractJsonSchemaView';

    type JsonFormBoolean = JsonFormBase<JsonSchemaBoolean>;

    @Component({
        components: { EvaluationError, FontAwesomeIcon, RadioOption },
        inheritAttrs: false,
    })
    export default class JsonSchemaBooleanView extends AbstractJsonSchemaView<JsonFormBoolean, boolean> {
        private faTimes = faTimes;
    }
</script>

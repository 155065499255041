import { CustomProperty, CustomPropertyType } from '@evidentid/tprm-portal-lib/models/dashboard';
import { isCollateralCustomProperty } from '@/utils/isCollateralCustomProperty';
import { omitBy, range, sample } from 'lodash';
import { isArray } from '@evidentid/json-schema/schemaChecks';
import { JsonSchemaBase } from '@evidentid/json-schema/interfaces/JsonSchema';
import {
    sampleBooleans,
    sampleContactNames,
    sampleIntegers,
    sampleNumbers,
    sampleStrings,
    sampleUuid,
    specialTypeSamplePool,
} from '@/modules/entity-management/utils/csv-sample/constants';
import { SampleInsured, SampleValues } from '@/modules/entity-management/utils/csv-sample/models';
import { getItemType, getOptionalData } from '@/modules/entity-management/utils/csv-sample/helpers/common';

const arrayMaxLength = 5;

function generateSampleEmails(domain: string): string[] {
    return sampleContactNames.map((contact) => `${contact.emailPrefix}${domain}`);
}

function generateSampleArray(itemType: string, primitiveTypes: Record<string, string[]>): string {
    return [ ...range(1, Math.random() * arrayMaxLength + 1) ]
        .map(() => sample(primitiveTypes[itemType] || []))
        .join('|');
}

function generateSampleCollaterals(): string {
    const sampling = () => JSON.stringify(
        omitBy({
            description: sample(specialTypeSamplePool.collaterals.description),
            category: sample(specialTypeSamplePool.collaterals.category),
            limitRequired: sample(specialTypeSamplePool.collaterals.limitRequired),
            uniqueIdentifier: getOptionalData(sample(specialTypeSamplePool.collaterals.uniqueIdentifier)),
            maximumDeductible: getOptionalData(sample(specialTypeSamplePool.collaterals.maximumDeductible)),
        }, (v) => v == null || v === ''),
    );
    const maxSampleSize = 3;
    return [ ...range(1, Math.random() * maxSampleSize + 1) ]
        .map(sampling)
        .join('|');
}

function generateSampleAddress(): string {
    const sampleAddress = sample(specialTypeSamplePool.address);
    return JSON.stringify(
        omitBy(sampleAddress, (v) => v == null || v === ''),
    );
}

export function generateSampleValue(field: CustomProperty, values: SampleValues): string {
    if (isCollateralCustomProperty(field)) {
        return getOptionalData(values.specialTypes.collaterals || '') as string;
    } else {
        const typeFromCustomProperty = field.type === CustomPropertyType.credentialTypes
            ? 'uuid'
            : null;
        return sample(isArray(field.schema)
            ? values.arrays[typeFromCustomProperty || getItemType(field.schema)]
            : values.primitives[(field.schema as JsonSchemaBase).type] || [],
        ) || '';
    }
}

export function generateSampleValues(insured: SampleInsured): SampleValues {
    // as of right now this function does not need to be recursive, as insured field types are statically limited
    const samplePrimitives = {
        string: sampleStrings,
        uuid: sampleUuid,
        integer: sampleIntegers,
        number: sampleNumbers,
        boolean: sampleBooleans,
        email: generateSampleEmails(insured.domain || 'example.com'),
    };

    const sampleArrays = {
        string: range(0, 5).map(() => generateSampleArray('string', samplePrimitives)),
        uuid: range(0, 5).map(() => generateSampleArray('uuid', samplePrimitives)),
        email: range(0, 5).map(() => generateSampleArray('email', samplePrimitives)),
    };

    return {
        primitives: samplePrimitives,
        arrays: sampleArrays,
        specialTypes: {
            collaterals: generateSampleCollaterals(),
            address: generateSampleAddress(),
        },
    };
}

export enum JsonRuleOperator {
    equal = '==',
    notEqual = '!=',
    greaterThan = '>',
    greaterThanOrEqual = '>=',
    lessThan = '<',
    lessThanOrEqual = '<=',
    contains = 'contains',
    containsAnyFrom = 'contains_any_credential_from_requirement',
    startsWith = 'starts_with',
    endsWith = 'ends_with',
}

const ruleOperatorLabels: Record<JsonRuleOperator, string> = {
    [JsonRuleOperator.equal]: 'Equals',
    [JsonRuleOperator.notEqual]: 'Does not equal',
    [JsonRuleOperator.greaterThan]: 'Greater than',
    [JsonRuleOperator.greaterThanOrEqual]: 'Greater than or equal to',
    [JsonRuleOperator.lessThan]: 'Less than',
    [JsonRuleOperator.lessThanOrEqual]: 'Less than or equal to',
    [JsonRuleOperator.contains]: 'Contains',
    [JsonRuleOperator.containsAnyFrom]: 'Contains any from',
    [JsonRuleOperator.startsWith]: 'Starts with',
    [JsonRuleOperator.endsWith]: 'Ends with',
};

export const collateralCategoryOperators: { label: string, value: string }[] = [
    { label: ruleOperatorLabels[JsonRuleOperator.equal], value: JsonRuleOperator.equal },
    { label: ruleOperatorLabels[JsonRuleOperator.contains], value: JsonRuleOperator.contains },
    { label: ruleOperatorLabels[JsonRuleOperator.startsWith], value: JsonRuleOperator.startsWith },
    { label: ruleOperatorLabels[JsonRuleOperator.endsWith], value: JsonRuleOperator.endsWith },
];

export const credentialTypesOperators: { label: string, value: string }[] = [
    { label: ruleOperatorLabels[JsonRuleOperator.containsAnyFrom], value: JsonRuleOperator.containsAnyFrom },
];

export const booleanAssignmentOperators: { label: string, value: string }[] = [
    { label: ruleOperatorLabels[JsonRuleOperator.equal], value: JsonRuleOperator.equal },
];

export const stringAssignmentOperators: { label: string, value: string }[] = [
    { label: ruleOperatorLabels[JsonRuleOperator.equal], value: JsonRuleOperator.equal },
    { label: ruleOperatorLabels[JsonRuleOperator.notEqual], value: JsonRuleOperator.notEqual },
];

export const allBaseAssignmentOperators: { label: string, value: string }[] = [
    { label: ruleOperatorLabels[JsonRuleOperator.equal], value: JsonRuleOperator.equal },
    { label: ruleOperatorLabels[JsonRuleOperator.notEqual], value: JsonRuleOperator.notEqual },
    { label: ruleOperatorLabels[JsonRuleOperator.greaterThan], value: JsonRuleOperator.greaterThan },
    { label: ruleOperatorLabels[JsonRuleOperator.greaterThanOrEqual], value: JsonRuleOperator.greaterThanOrEqual },
    { label: ruleOperatorLabels[JsonRuleOperator.lessThan], value: JsonRuleOperator.lessThan },
    { label: ruleOperatorLabels[JsonRuleOperator.lessThanOrEqual], value: JsonRuleOperator.lessThanOrEqual },
    { label: ruleOperatorLabels[JsonRuleOperator.contains], value: JsonRuleOperator.contains },
    { label: ruleOperatorLabels[JsonRuleOperator.startsWith], value: JsonRuleOperator.startsWith },
    { label: ruleOperatorLabels[JsonRuleOperator.endsWith], value: JsonRuleOperator.endsWith },
];

export const allAssignmentOperators: { label: string, value: string }[] = [
    { label: ruleOperatorLabels[JsonRuleOperator.equal], value: JsonRuleOperator.equal },
    { label: ruleOperatorLabels[JsonRuleOperator.notEqual], value: JsonRuleOperator.notEqual },
    { label: ruleOperatorLabels[JsonRuleOperator.greaterThan], value: JsonRuleOperator.greaterThan },
    { label: ruleOperatorLabels[JsonRuleOperator.greaterThanOrEqual], value: JsonRuleOperator.greaterThanOrEqual },
    { label: ruleOperatorLabels[JsonRuleOperator.lessThan], value: JsonRuleOperator.lessThan },
    { label: ruleOperatorLabels[JsonRuleOperator.lessThanOrEqual], value: JsonRuleOperator.lessThanOrEqual },
    { label: ruleOperatorLabels[JsonRuleOperator.contains], value: JsonRuleOperator.contains },
    { label: ruleOperatorLabels[JsonRuleOperator.containsAnyFrom], value: JsonRuleOperator.containsAnyFrom },
    { label: ruleOperatorLabels[JsonRuleOperator.startsWith], value: JsonRuleOperator.startsWith },
    { label: ruleOperatorLabels[JsonRuleOperator.endsWith], value: JsonRuleOperator.endsWith },
];

<template>
    <FormElement :label-for="id" :label="form.schema.title" :required="required">
        <template v-slot:requiredIndicator>
            {{ translate('requiredLabel') }}
        </template>
        <FormInput :invalid="accessed && hasError" force-error-slot>
            <input
                :id="id"
                v-model="viewValue"
                type="text"
                :disabled="disabled"
                :placeholder="form.schema.description || description"
                @input="onChange"
                @blur="touch"
            >
            <template v-if="accessed && error" v-slot:errorMessage>
                {{ error }}
            </template>
        </FormInput>
    </FormElement>
</template>

<script lang="ts">
    import { Component, Watch } from '@evidentid/vue-property-decorator';
    import { JsonFormBase } from '@evidentid/json-schema/interfaces/JsonForm';
    import { JsonSchemaBoolean } from '@evidentid/json-schema/interfaces/JsonSchema';
    import AbstractJsonSchemaForm
        from '@evidentid/dashboard-commons/components/JsonSchemaForm/elements/AbstractJsonSchemaForm';
    import { FormElement, FormInput } from '@evidentid/dashboard-commons/components/Form';

    type JsonFormBoolean = JsonFormBase<JsonSchemaBoolean>;

    @Component({
        components: { FormInput, FormElement },
        inheritAttrs: false,
    })
    export default class BulkImportBooleanField extends AbstractJsonSchemaForm<JsonFormBoolean, boolean> {
        private valuesMap: Record<string, boolean | null> = { '': null, 'yes': true, 'no': false };
        private viewValue: string = '';

        protected get parsedViewValue(): any {
            const viewValue = this.viewValue.trim().toLowerCase();
            return viewValue in this.valuesMap ? this.valuesMap[viewValue] : viewValue;
        }

        protected override getCustomError(): string | null {
            return typeof this.value === 'boolean' ? null : 'It should be "yes" or "no"';
        }

        @Watch('value', { immediate: true })
        private onExternalValueChange() {
            if (this.parsedViewValue !== this.value) {
                this.viewValue = this.value === true ? 'yes' : this.value === false ? 'no' : '';
            }
        }

        @Watch('viewValue')
        private onChange() {
            this.$emit('input', this.parsedViewValue);
        }
    }
</script>

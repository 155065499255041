<template>
    <div class="EntityDetailsAddressForm">
        <div v-if="!editing" class="EntityDetailsAddressForm__displayValue">
            <FormElement :label="form.schema.title">
                <FormInput
                    class="EntityDetailsAddressForm__displayValueFormInput"
                    :invalid="showAddressRequiredError"
                >
                    <div
                        class="EntityDetailsAddressForm__displayAddressText"
                        data-test-id="EntityDetailsAddressForm__displayAddressText"
                    >
                        {{ displayAddress }}
                    </div>
                    <template v-if="showAddressRequiredError" #errorMessage>
                        This field is required.
                    </template>
                </FormInput>
                <div class="EntityDetailsAddressForm__actionContainer">
                    <EidIcon
                        data-test-id="EntityDetailsAddressForm__editButton"
                        class="EntityDetailsAddressForm__actionButton"
                        :class="{'EntityDetailsAddressForm__actionButton--disabled': false}"
                        :icon-src="pencilIcon"
                        :svg-props="{color: trustGreen}"
                        alt="edit"
                        @click="edit"
                    />
                    <EidIcon
                        v-if="!required"
                        data-test-id="EntityDetailsAddressForm__deleteButton"
                        alt="delete"
                        class="EntityDetailsAddressForm__actionButton"
                        :class="{'EntityDetailsAddressForm__actionButton--disabled': false}"
                        :icon-src="binIcon"
                        :svg-props="{color: trustGreen}"
                        @click="deleteAddress"
                    />
                </div>
            </FormElement>
        </div>
        <div v-else class="EntityDetailsAddressForm__addressForm">
            <FormElement :label="form.schema.title">
                <FormInput>
                    <JsonSchemaAddressForm
                        :id="id"
                        :form-element-component="formElementComponent"
                        :depth="depth + 1"
                        :form="form"
                        :disabled="disabled || form.schema.readOnly"
                        :custom-component-input="customComponentInput"
                        :value="localValue"
                        :strings="strings"
                        required
                        v-bind="$attrs"
                        @input="onInput"
                        @change="onChange"
                    />
                </FormInput>

                <div class="flex mx-8 h-full items-start">
                    <EidIcon
                        data-test-id="EntityDetailsAddressForm__confirmButton"
                        class="EntityDetailsAddressForm__actionButton text-eidTrustGreen"
                        :class="{'EntityDetailsAddressForm__actionButton--disabled': !isValid}"
                        :icon-src="faCheck"
                        alt="confirm"
                        @click="saveEdit"
                    />
                    <EidIcon
                        data-test-id="EntityDetailsAddressForm__cancelButton"
                        class="EntityDetailsAddressForm__actionButton text-eidDangerRed"
                        alt="cancel"
                        :icon-src="faTimes"
                        @click="cancelEdit"
                    />
                </div>
            </FormElement>
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Watch } from '@evidentid/vue-property-decorator';
    import { JsonFormObject } from '@evidentid/json-schema/interfaces/JsonForm';
    import AbstractJsonSchemaForm
        from '@evidentid/dashboard-commons/components/JsonSchemaForm/elements/AbstractJsonSchemaForm';
    import { FormElement, FormInput } from '@evidentid/dashboard-commons/components/Form';
    import JsonSchemaAddressForm
        from '@evidentid/dashboard-commons/components/JsonSchemaForm/customElements/JsonSchemaAddressForm.vue';
    import {
        JsonSchemaFormElement,
    } from '@evidentid/dashboard-commons/components/JsonSchemaForm';
    import { EntityAddress } from '@evidentid/tprm-portal-lib/models/dashboard';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
    import binIcon from '@/assets/icons/bin-icon.svg';
    import pencilIcon from '@/assets/icons/icon-pencil-hollow.svg';
    import { tailwindColors } from '@/styles/variables/tailwind-colors/tailwindColors';
    import { getDisplayAddress } from '@/modules/entity-details/utils/getDisplayAddress';

    @Component({
        components: { EidIcon, FormInput, FormElement, JsonSchemaAddressForm, JsonSchemaFormElement },
        inheritAttrs: false,
    })
    export default class EntityDetailsAddressForm
        extends AbstractJsonSchemaForm<JsonFormObject, EntityDetailsAddressForm | undefined> {
        private editing = false;
        private localValue = this.form.getValue(this.value, true);
        // no longer needed once Address rendering children component statically
        private formElementComponent = JsonSchemaFormElement;
        private faTimes = faTimes;
        private faCheck = faCheck;
        private binIcon = binIcon;
        private pencilIcon = pencilIcon;
        private trustGreen = tailwindColors.eidTrustGreen.DEFAULT;

        private get displayAddress(): string {
            return getDisplayAddress({
                schema: this.form.schema,
                value: this.localValue as EntityAddress,
                customLabels: this.strings?.customLabels,
            });
        }

        private get isEmptyAddress(): boolean {
            const { street, city, state, zipCode, country } = this.localValue;
            return !street && !city && !state && !zipCode && !country;
        }

        private get isValid(): boolean {
            return this.form.isValid(this.form.getValue(this.localValue, true), true);
        }

        private get showAddressRequiredError(): boolean {
            return this.required && (!this.value || this.isEmptyAddress);
        }

        @Watch('value', { immediate: true })
        private onValueChanges(): void {
            this.localValue = this.form.getValue(this.value, true);
        }

        private edit(): void {
            this.editing = true;
            if (!this.localValue || this.isEmptyAddress) {
                this.initializeAddress();
            }
        }

        private cancelEdit(): void {
            this.editing = false;
            this.localValue = this.form.getValue(this.value, true);
        }

        private saveEdit(): void {
            if (this.isValid) {
                this.editing = false;
                this.$emit('input', this.form.getValue(this.localValue, true));
                this.$emit('change');
            }
        }

        private deleteAddress(): void {
            this.localValue = undefined;
            this.$emit('input', undefined);
            this.$emit('change');
        }

        private initializeAddress() {
            const newVal = this.form.getValue(undefined, true);
            if ('country' in newVal && !newVal.country) {
                newVal.country = 'US';
            }
            this.localValue = this.form.getValue(newVal, true);
        }

        private onInput(val: EntityAddress) {
            this.localValue = val;
        }

        private onChange(val: EntityAddress) {
            this.localValue = val;
        }
    }
</script>

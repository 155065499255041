<template>
    <FormElement
        class="EnumFilter"
        :label="label"
    >
        <FormInput>
            <Dropdown
                :multi-select="isArrayEnum(schema)"
                :options="enumOptions"
                :selected="selectedEnumOption"
                @input="onInput"
                @open="$emit('open')"
            />
        </FormInput>
    </FormElement>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import { Dropdown } from '@evidentid/dashboard-commons/components/Dropdown';
    import JsonSchema, { JsonSchemaArray, JsonSchemaEnum } from '@evidentid/json-schema/interfaces/JsonSchema';
    import { DropdownOption } from '@evidentid/dashboard-commons/components/Dropdown/types';
    import { isArray, isEnum } from '@evidentid/json-schema/schemaChecks';
    import { FormElement, FormInput } from '@evidentid/dashboard-commons/components/Form';

    export default Vue.extend({
        name: 'EnumFilter',
        components: {
            FormElement, FormInput,
            Dropdown,
        },
        props: {
            value: {
                type: [ String, Array ] as PropType<string | string[]>,
                default: undefined,
            },
            label: {
                type: String as PropType<string>,
                default: '',
            },
            schema: {
                type: Object as PropType<JsonSchemaEnum | JsonSchemaArray<JsonSchemaEnum>>,
                default: undefined,
            },
        },
        computed: {
            enumItems(): string[] {
                return this.isArrayEnum(this.schema) ? this.schema.items.enum : this.schema.enum;
            },
            enumLabels(): string[] | null {
                return this.isArrayEnum(this.schema)
                    ? this.schema.items.enumLabels || null
                    : this.schema.enumLabels || null;
            },
            enumOptions(): DropdownOption[] {
                return this.enumItems.map((item, index) => ({
                    label: this.enumLabels?.[index] || item,
                    value: item,
                }));
            },
            selectedEnumOption(): DropdownOption[] {
                if (Array.isArray(this.value)) {
                    return this.value.map(this.enumValToOption);
                } else if (typeof this.value === 'string' && this.value) {
                    return [ this.enumValToOption(this.value) ];
                } else {
                    return [];
                }
            },
        },
        methods: {
            enumValToOption(val: string): DropdownOption {
                const labelIndex = this.enumItems.indexOf(val);
                const label = this.enumLabels?.[labelIndex] || val;
                return { label, value: this.value };
            },
            isEnumType(schema: JsonSchema): schema is JsonSchemaEnum {
                return schema && isEnum(schema);
            },
            isArrayEnum(schema: JsonSchema): schema is JsonSchemaArray<JsonSchemaEnum> {
                return schema && isArray(schema) && isEnum(schema.items as JsonSchema);
            },
            onInput(inputVal: DropdownOption[]): void {
                this.$emit('input', inputVal.map((x) => x.value).join(','));
            },
        },
    });
</script>

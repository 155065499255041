import { JsonSchemaBasicObject, JsonSchemaType } from '@evidentid/json-schema/interfaces/JsonSchema';
import transform from 'lodash/transform';
import omitBy from 'lodash/omitBy';
import startCase from 'lodash/startCase';

function isEmptyValue(value: any) {
    return value == null ||
        value === '' ||
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (Array.isArray(value) && value.length === 0);
}

function getTitleForKey(key: string): string {
    return startCase(key);
}

function objectToProperty(object: Record<string, any>, value: any, key: string): any {
    if (isEmptyValue(value)) {
        return {};
    }
    const type = Array.isArray(value) ? JsonSchemaType.array : typeof value;
    const properties = type === JsonSchemaType.object ? transform(value, objectToProperty, {}) : null;
    const itemsForm = type === JsonSchemaType.array ? { type: typeof value[0] } : null;
    const itemProps = itemsForm && itemsForm.type === JsonSchemaType.object
        ? { properties: transform(value[0], objectToProperty, {}) }
        : null;
    const items = itemsForm ? { ...itemsForm, ...itemProps } : null;
    object[key] = omitBy({ type, title: getTitleForKey(key), properties, items }, (x) => x === null);
    return object;
}

export function getBasicSchemaByValue(value: Record<string, any>): JsonSchemaBasicObject {
    return { type: JsonSchemaType.object, properties: transform(value, objectToProperty, {}) };
}

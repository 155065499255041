import { Component, Prop, Vue, Mixins, Inject, InjectReactive } from '@evidentid/vue-property-decorator';
import { PropType } from 'vue';
import JsonSchema from '@evidentid/json-schema/interfaces/JsonSchema';
import {
    EidJsonSchemaFormCustomComponentInput,
    FormErrors,
    SchemaError,
} from '@evidentid/dashboard-commons/components/EidJsonSchemaForm/types';
import {
    formErrorsInjectKey,
    formValueInjectKey,
    setSchemaVisibleInjectKey,
} from '@evidentid/dashboard-commons/components/EidJsonSchemaForm/jsonSchemaProvideInject';
import { JsonSchemaFormStrings } from '@evidentid/dashboard-commons/components/JsonSchemaForm';
import { translate } from '@evidentid/dashboard-commons/utils/translate';

@Component
class AbstractCriterionInput<T extends JsonSchema = JsonSchema, V = any> extends Vue {
    @Prop({ type: String as PropType<string>, required: true })
    protected valuePath!: string;

    @Prop({ type: [ String, Number, Object, Array, Boolean ] as PropType<V>, default: undefined })
    protected value?: V;

    @Prop({ type: [ String, Number, Object, Array, Boolean ] as PropType<V>, default: undefined })
    protected initialValue?: V;

    @Prop({ type: [ String, Number, Object, Array, Boolean ] as PropType<V>, default: undefined })
    protected defaultValue?: V;

    @Prop({ type: Object as PropType<T>, required: true })
    protected schema!: T;

    @Prop({ type: Array as PropType<SchemaError[]> })
    protected parentSchemaErrors?: SchemaError[];

    @Prop({ type: Boolean as PropType<boolean>, default: false })
    protected required!: boolean;

    @Prop({ type: String, default: '' })
    protected id!: string;

    @Prop({ type: Boolean, default: false })
    protected deletable!: boolean;

    @Prop({ type: Boolean, default: false })
    protected touched!: boolean;

    @Prop({ type: Boolean, default: false })
    private disabled!: boolean;

    @Prop({ type: Boolean, default: false })
    protected showError!: boolean;

    @Prop({ type: Boolean, default: false })
    protected hideTitle!: boolean;

    @Prop({ type: Number, default: 0 })
    protected depth!: number;

    @Prop({ type: [ Object, Function ] as PropType<EidJsonSchemaFormCustomComponentInput> })
    protected customComponentInput?: Record<string, Vue.Component>;

    @Prop({ type: String as PropType<string> })
    protected schemaPath!: string;

    @Inject(setSchemaVisibleInjectKey)
    protected setSchemaVisible?: (schemaPath: string, visible: boolean) => void;

    @InjectReactive(formValueInjectKey)
    protected formValue?: Record<string, any>;

    @InjectReactive(formErrorsInjectKey)
    protected formErrors?: FormErrors;

    protected localValue = this.value || null;
    protected localTouched = false;
    protected focused = false;

    protected get accessed(): boolean {
        return this.touched || this.localTouched;
    }

    protected get schemaErrors(): SchemaError[] | undefined {
        return this.formErrors?.filter((error) => error.dataPath === this.valuePath);
    }

    protected get hasError(): boolean {
        return Boolean(this.errorMessage);
    }

    protected get errorMessage(): string | undefined {
        const isRequiredError = this.parentSchemaErrors?.find((error) => error.keyword === 'required')
            || this.schemaErrors?.find((error) => error.keyword === 'required');
        const isMinLengthError = this.schemaErrors?.find((error) => error.keyword === 'minLength');
        const isMaxLengthError = this.schemaErrors?.find((error) => error.keyword === 'maxLength');
        if (
            isRequiredError ||
            (this.required && (this.value == null || (this.value as any) === ''))
        ) {
            return this.translate('error.required');
        }
        if (isMinLengthError) {
            return this.translate('error.text.tooShort');
        }
        if (isMaxLengthError) {
            return this.translate('error.text.tooLong');
        }
        if (this.parentSchemaErrors?.length || this.schemaErrors?.length) {
            return this.translate('error.unknown');
        }
    }

    protected touch(): void {
        this.localTouched = true;
        this.focused = false;
        this.$emit('touch');
        this.$emit('blur');
    }

    protected onFocus(): void {
        this.focused = true;
        this.$emit('focus');
    }

    protected translate(phrase: keyof JsonSchemaFormStrings) {
        return translate({
            phrase,
            schema: this.schema,
        });
    }
}

const AbstractCriterionInputMixin = Mixins(AbstractCriterionInput) as typeof AbstractCriterionInput;
export default AbstractCriterionInputMixin;

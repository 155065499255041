import { createApplicationBuilder, createModuleBuilder } from '@evidentid/dashboard-commons/app';
import { createIdleLogoutModule } from '@evidentid/dashboard-commons/modules/idle-logout';
import { createSnackbarModule } from '@evidentid/dashboard-commons/modules/snackbar';
import { createPersistingErrorModule } from '@evidentid/dashboard-commons/modules/persisting-error';
import { createPersistVueErrorsModule } from '@evidentid/dashboard-commons/modules/persist-vue-errors';
import { createAuthModule } from '@evidentid/dashboard-commons/modules/auth';
import { createAuthConnectorModule } from '@evidentid/dashboard-commons/modules/auth-connector';
import { createAuthConnectorNotifySnackbarModule } from '@evidentid/dashboard-commons/modules/auth-connector-notify-snackbar';
import { createRpWebApiClientModule } from '@evidentid/dashboard-commons/modules/rpweb-api-client';
import { createLoggerModule } from '@evidentid/dashboard-commons/modules/logger';
import { createTrackerModule } from '@evidentid/dashboard-commons/modules/tracker';
import { createTrackRoutesModule } from '@evidentid/dashboard-commons/modules/track-routes';
import { createUserManagementModule } from '@evidentid/dashboard-commons/modules/user-management';
import { createConfigApiClientModule } from '@evidentid/dashboard-commons/modules/config-api-client';
import { createOnlineChatModule } from '@evidentid/dashboard-commons/modules/online-chat';
import TrackerUserData from '@evidentid/tracker/TrackerUserData';
import { dashboardModule } from '@/modules/dashboard';
import { dashboardConfigurationModule } from '@/modules/dashboard-configuration';
import { entityManagementModule } from '@/modules/entity-management';
import { entityFilteringModule } from '@/modules/entity-filtering';
import { exportEntitiesModule } from '@/modules/export-entities';
import { entityDocumentsModule } from '@/modules/entity-documents';
import { entityRequestModule } from '@/modules/entity-request';
import { localStorageModule } from '@/modules/local-storage';
import { entityDetailsModule } from '@/modules/entity-details';
import { decisioningCriteriaModule } from '@/modules/decisioning-criteria';
import { entityActionsReviewModule } from '@/modules/entity-actions-review';
import { createNotificationsConfigurationModule } from '@/modules/notifications-configuration';
import { apiSettingsModule } from '@/modules/api-settings';
import { createSupportModule } from '@evidentid/dashboard-commons/modules/support';
import { createGatewayApiClientModule } from '@/modules/gateway-api-client';

const base = createModuleBuilder()
    .registerRoutes(() => [
        {
            path: '/',
            redirect: () => ({ name: 'dashboard' }),
        },
        {
            // For now there exists no home page.
            path: '*',
            name: '404',
            redirect: () => ({ name: 'error', params: { reason: 'page-not-found' }, query: {} }),
        },
    ])
    .end();

export const createInsuranceApplication = createApplicationBuilder()
    .use(createTrackRoutesModule())
    .use(createSnackbarModule())
    .use(createPersistingErrorModule())
    .use(createPersistVueErrorsModule())
    .use(createAuthModule())
    .use(createAuthConnectorModule())
    .use(createAuthConnectorNotifySnackbarModule())
    .use(createRpWebApiClientModule())
    .use(createLoggerModule())
    .use(createTrackerModule<TrackerUserData & { currentRp?: string | null, rpsCount?: number }>())
    .use(createIdleLogoutModule())
    .use(createUserManagementModule())
    .use(createConfigApiClientModule())
    .use(createGatewayApiClientModule())
    .use(createOnlineChatModule())
    .use(createSupportModule())
    .use(dashboardModule)
    .use(dashboardConfigurationModule)
    .use(decisioningCriteriaModule)
    .use(entityManagementModule)
    .use(entityFilteringModule)
    .use(exportEntitiesModule)
    .use(entityDocumentsModule)
    .use(entityRequestModule)
    .use(localStorageModule)
    .use(entityDetailsModule)
    .use(entityActionsReviewModule)
    .use(apiSettingsModule)
    .use(createNotificationsConfigurationModule())
    .use(base)
    .createFactory();

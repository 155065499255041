import { TprmRequirementType } from '@evidentid/tprm-portal-lib/models/entity-details';
import moment from 'moment';
import mapValues from 'lodash/mapValues';
import { trimFieldValue } from '@/modules/entity-management/utils/trimFieldValue';
import { BaseEntityInput } from '@/modules/entity-management/types';
import { CustomProperty, EntityInput, EntityInputApiModel } from '@evidentid/tprm-portal-lib/models/dashboard';
import {
    CustomPropertyValue,
    CustomPropertyValues,
} from '@evidentid/tprm-portal-lib/models/dashboard/CustomProperty.model';

function isPastDate(date: string): boolean {
    return moment(date).isBefore(moment(), 'day');
}

export function hasPastExpirationDate(entity: EntityInput | BaseEntityInput): boolean {
    return Object.values(entity.exceptions || {}).some(isPastDate);
}

export function trimEntity(
    entity: EntityInput | BaseEntityInput): EntityInput | BaseEntityInput {
    const baseFields = mapValues(entity, trimFieldValue);
    const customProperties = mapValues(entity.insuredFields, trimFieldValue);
    return { ...baseFields, insuredFields: { ...customProperties } } as unknown as EntityInput;
}

export function filterEntityEmptyItems(
    entity: EntityInput | BaseEntityInput,
    customProperties: CustomProperty[]
): EntityInput | BaseEntityInput {
    const filterArrayEmptyItems = (value: CustomPropertyValue): CustomPropertyValue =>
        (Array.isArray(value) ? (value as []).filter(Boolean) : value);
    const baseFields = mapValues(entity, filterArrayEmptyItems);

    const optional = customProperties.filter((x) => !x.required);
    const insuredFieldValues = Object.entries(entity.insuredFields || {})
        .reduce((acc, [ key, val ]) => {
            const newVal = optional.find((x) => x.key === key) ? filterArrayEmptyItems(val) : val;
            return { ...acc, [key]: newVal } as CustomPropertyValues;
        }, {} as CustomPropertyValues);
    return { ...baseFields, insuredFields: { ...insuredFieldValues } } as unknown as EntityInput;
}

export function convertEntityInputViewToData(entity: EntityInput): EntityInputApiModel {
    const viewExceptions = entity.exceptions || {};
    const exceptions = Object.keys(viewExceptions).map((key) => ({
        coverageType: key as TprmRequirementType,
        until: viewExceptions[key],
    }));
    return { ...entity, exceptions };
}

export function convertEntityInputDataToView(entity: EntityInputApiModel): EntityInput {
    const dataExceptions = entity.exceptions || [];
    const exceptions = dataExceptions.reduce((accu, exception) => ({
        ...accu,
        [exception.coverageType]: exception.until,
    }), {});
    return { ...entity, exceptions };
}

<template>
    <div class="MatchingSelectionReviewBase">
        <ConfirmationModal
            v-if="showConfirmationModal"
            additional-class-name="MatchingSelectionReviewBase__confirmationModal"
            title="Save Action Review"
            :yes-icon="null"
            :no-icon="null"
            @input="onModalAction"
        >
            Future global actions will be automatically reviewed based on your review. Saving this action cannot be
            undone.
        </ConfirmationModal>
        <div class="MatchingSelectionReviewBase__container">
            <div class="MatchingSelectionReviewBase__form">
                <Table class="MatchingSelectionReviewBase__table">
                    <template #head>
                        <HeadCell class="MatchingSelectionReviewBase__fieldHeader">
                            Field
                        </HeadCell>
                        <HeadCell class="MatchingSelectionReviewBase__valueHeader">
                            Value
                        </HeadCell>
                    </template>
                    <Row hoverable>
                        <Cell
                            data-test-id="MatchingSelectionReviewBase__providedFieldHeader"
                            class="MatchingSelectionReviewBase__fieldHeader"
                        >
                            {{ providedSubjectTitle }}
                        </Cell>
                        <Cell
                            data-test-id="MatchingSelectionReviewBase__providedValueHeader"
                            class="MatchingSelectionReviewBase__valueHeader"
                        >
                            <span v-if="provided">
                                {{ provided }}
                            </span>
                            <EmptyValueDashSymbol v-else />
                        </Cell>
                    </Row>
                    <Row hoverable>
                        <Cell
                            data-test-id="MatchingSelectionReviewBase__matchingFieldHeader"
                            class="MatchingSelectionReviewBase__fieldHeader"
                        >
                            {{ matchingSubjectTitle }}
                        </Cell>
                        <Cell
                            data-test-id="MatchingSelectionReviewBase__matchingValueHeader"
                            class="MatchingSelectionReviewBase__valueHeader"
                        >
                            <Dropdown
                                :placeholder="dropdownPlaceholder"
                                :options="options"
                                :selected="selectedOptions"
                                :disabled="matchingNotListed"
                                @input="onMatchingSelection"
                            />
                            <Checkbox
                                class="MatchingSelectionReviewBase__matchingNotListed"
                                @input="setMatchingNotFoundStatus"
                            >
                                {{ noMatchesText }}
                            </Checkbox>
                        </Cell>
                    </Row>
                </Table>
                <Button
                    class="MatchingSelectionReviewBase__saveButton"
                    type="primary"
                    :disabled="saveDisabled"
                    @click="onSave"
                >
                    Save
                </Button>
            </div>

            <div class="MatchingSelectionReviewBase__documents">
                <LogoLoader v-if="loading" />
                <DocumentsViewer v-if="!loading" :documents="docsStatus.list" />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from '@evidentid/vue-property-decorator';
    import BinaryDataDisplay from '@/components/binary-data-display/BinaryDataDisplay.vue';
    import { CoiDocumentStatus } from '@/modules/entity-actions-review/vuex';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import { ActionReview } from '@evidentid/tprm-portal-lib/models/entity-actions-review';
    import DocumentsViewer from '@/modules/entity-actions-review/components/DocumentsViewer/DocumentsViewer.vue';
    import { Cell, HeadCell, Row, Table } from '@evidentid/dashboard-commons/components/Table';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import { LogoLoader } from '@evidentid/dashboard-commons/components/LogoLoader';
    import { Dropdown } from '@evidentid/dashboard-commons/components/Dropdown';
    import EmptyValueDashSymbol
        from '@/modules/entity-actions-review/components/EmptyValueDashSymbol/EmptyValueDashSymbol.vue';
    import { Checkbox } from '@evidentid/dashboard-commons/components/Checkbox';
    import { DropdownOption } from '@evidentid/dashboard-commons/components/Dropdown/types';
    import { ConfirmationModal } from '@evidentid/dashboard-commons/components/ConfirmationModal';

    @Component({
        components: {
            ConfirmationModal,
            EmptyValueDashSymbol,
            Button,
            BinaryDataDisplay,
            Checkbox,
            Dropdown,
            DocumentsViewer,
            HeadCell,
            LogoLoader,
            Table,
            Cell,
            Row,
        },
    })
    export default class MatchingSelectionReviewBase extends Vue {
        @Prop({ type: Object, default: () => ({}) })
        private actionReview!: ActionReview;

        @Prop({ type: Array, default: () => [] })
        private matchingList!: string[];

        @Prop({ type: String, default: '' })
        private provided!: string;

        @Prop({ type: String, default: 'Provided' })
        private providedSubjectTitle!: string;

        @Prop({ type: String, default: 'Match' })
        private matchingSubjectTitle!: string;

        @Prop({ type: String, default: 'No Match' })
        private noMatchesText!: string;

        private matchingNotListed: boolean = false;
        private selectedOptions: DropdownOption[] = [];
        private options: DropdownOption[] = [];
        private showConfirmationModal: boolean = false;

        private get rpName(): string {
            return this.$rp.current!;
        }

        private get docsStatus(): CoiDocumentStatus {
            if (this.$store.state.insuredActionsReview.rprDocuments[this.rpName] &&
                this.$store.state.insuredActionsReview.rprDocuments[this.rpName][this.actionReview.id]) {
                return this.$store.state.insuredActionsReview.rprDocuments[this.rpName][this.actionReview.id];
            } else {
                return { status: OperationStatus.uninitialized, list: [] };
            }
        }

        private get loading(): boolean {
            return this.docsStatus.status === OperationStatus.loading;
        }

        private get saveDisabled(): boolean {
            return this.selectedOptions.length === 0 && !this.matchingNotListed;
        }

        private get dropdownPlaceholder(): string {
            return this.matchingNotListed ? 'N/A' : `Select ${this.matchingSubjectTitle}`;
        }

        @Watch('matchingList', { immediate: true })
        private onMatchingListChange(): void {
            this.options = [ ...this.matchingList ].sort().map((x) => ({ label: x, value: x }));
        }

        @Watch('actionReview', { immediate: true })
        private onActionReviewChange(): void {
            if (this.docsStatus.status === OperationStatus.uninitialized &&
                this.actionReview.evaluationResults.length > 0 &&
                this.actionReview.evaluationResults[0].verificationId) {
                this.$store.actions.insuredActionsReview.loadRprDocuments({
                    rpName: this.rpName,
                    actionId: this.actionReview.id,
                    // requirement for now is getting the first insure is sufficient thus first result's id is enough
                    verificationIds: [ this.actionReview.evaluationResults[0].verificationId ],
                });
            }
        }

        private setMatchingNotFoundStatus(checked: boolean): void {
            this.matchingNotListed = checked;
            if (checked) {
                this.selectedOptions = [];
            }
        }

        private onMatchingSelection(option: DropdownOption): void {
            this.selectedOptions = [ option ];
        }

        private onSave(): void {
            this.showConfirmationModal = true;
        }

        private onModalAction(confirmed: boolean): void {
            if (confirmed) {
                this.$emit('resolve',
                           this.selectedOptions.length === 0 ? null : this.selectedOptions[0].value,
                );
            }
            this.showConfirmationModal = false;
        }
    }
</script>

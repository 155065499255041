<template>
    <div class="CriterionNumberInput">
        <FormElement :label="hideTitle ? null : form.schema.title">
            <template v-if="form.schema.description" #labelHint>
                <div
                    v-tooltip="form.schema.description"
                    class="CriterionEnumInput__hintIcon"
                >
                    <FontAwesomeIcon :icon="faQuestionCircle" />
                </div>
            </template>
            <FormInput :invalid="(showError || accessed) && hasError" force-error-slot force-action-slot>
                <input
                    :type="temporaryString ? 'text' : 'number'"
                    :value="viewValue"
                    :min="form.schema.minimum"
                    :max="form.schema.maximum"
                    :disabled="disabled"
                    @input="onInput"
                    @blur="touch"
                    @focus="onFocus"
                    @change="onChange"
                >
                <template v-if="(showError || accessed) && error" #errorMessage>
                    {{ error }}
                </template>
                <template v-if="deletable" #actionButtons>
                    <div class="JsonSchemaForm__deleteIcon" @click="$emit('delete')">
                        <EidIcon
                            :icon-src="binIcon"
                            alt="delete"
                            :svg-props="{color: alertRedColor}"
                        />
                    </div>
                </template>
            </FormInput>
        </FormElement>
    </div>
</template>

<script lang="ts">
    import { Component } from '@evidentid/vue-property-decorator';
    import binIcon from '@/assets/icons/bin-icon.svg';
    import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { FormElement, FormInput } from '@evidentid/dashboard-commons/components/Form';
    import AbstractCriterionInput
        from '@/modules/decisioning-criteria/components/CriterionInputComponents/AbstractCriterionInput';
    import { JsonSchemaInteger, JsonSchemaNumber } from '@evidentid/json-schema/interfaces/JsonSchema';
    import { JsonFormBase } from '@evidentid/json-schema/interfaces/JsonForm';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import { tailwindColors } from '@/styles/variables/tailwind-colors/tailwindColors';
    import { numberToString } from '@evidentid/dashboard-commons/utils/numericUtilities';

    type JsonFormNumeric = JsonFormBase<JsonSchemaNumber> | JsonFormBase<JsonSchemaInteger>;

    @Component({
        components: { EidIcon, FormInput, FontAwesomeIcon, FormElement },
    })
    export default class CriterionNumberInput extends AbstractCriterionInput<JsonFormNumeric, number> {
        private binIcon = binIcon;
        private faQuestionCircle = faQuestionCircle;
        private alertRedColor = tailwindColors.eidDangerRed.DEFAULT;

        private get viewValue(): string {
            return this.value == null ? '' : typeof this.value === 'number' ? numberToString(this.value) : this.value;
        }

        // Used to decide if the input shouldn't be temporarily without [type=number] to show actual value
        protected get temporaryString(): boolean {
            const exponentRegex = /[eE]/;
            return isNaN(this.viewValue.replace(exponentRegex, 'x') as any) &&
                this.viewValue.trim().length > 0;
        }

        private onInput(event: InputEvent): void {
            if (!this.disabled) {
                const inputValue = (event.target as HTMLInputElement).value;
                this.$emit('input', this.form.getValue(inputValue));
            }
        }

        private onChange(event: Event): void {
            if (!this.disabled) {
                const inputValue = (event.target as HTMLInputElement).value;
                this.$emit('change', this.form.getValue(inputValue));
            }
        }
    }
</script>

<template>
    <LoadingModal v-if="loading" header="Add or update entity" open @close="close" />
    <ConfirmUpdateEntityModal
        v-else-if="entityExists"
        :entity="existedInsured"
        :saving="saving"
        :external-error="updateError"
        @close="close"
        @updateInsured="updateInsured"
    />
    <AddIndividualEntityModal
        v-else-if="opened"
        :custom-properties="customPropertiesStatus.list"
        :requirement-types="nonMultiInstanceRequirementTypes"
        :saving="saving"
        :alert-config="alertConfig"
        @close="close"
        @submit="addInsured"
    />
</template>

<script lang="ts">
    import { Component, Prop, Vue, Watch } from '@evidentid/vue-property-decorator';
    import { OperationError } from '@evidentid/rpweb-api-client/models';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import { AlertType } from '@evidentid/dashboard-commons/components/Alert/types';
    import { BatchAddEntitiesStatus, BatchUpdateEntitiesStatus } from '@/modules/entity-management/vuex';
    import AddIndividualEntityModal
        from '@/modules/entity-management/components/AddIndividualEntityModal/AddIndividualEntityModal.vue';
    import LoadingModal from '@/components/loading-modal/LoadingModal.vue';
    import ConfirmUpdateEntityModal
        from '@/modules/entity-management/components/ConfirmUpdateEntityModal/ConfirmUpdateEntityModal.vue';
    import { convertEntityInputDataToView } from '@/modules/entity-management/utils/entityProcessing';
    import { CustomProperty, EntityInput } from '@evidentid/tprm-portal-lib/models/dashboard';
    import { TprmRequirementDefinition } from '@evidentid/tprm-portal-lib/models/common';

    @Component({
        components: { AddIndividualEntityModal, LoadingModal, ConfirmUpdateEntityModal },
    })

    export default class AddIndividualEntityProcedure extends Vue {
        @Prop({ type: String, default: '' })
        private rpName!: string;
        private opened: boolean = true;
        private saving: boolean = false;
        private alertConfig: { type: AlertType, title: string } | null = null;
        private updateError: string = '';
        private needCollectEvidence: boolean = false;

        @Watch('addEntitiesStatus')
        private async onAddEntitiesStatusChange(addEntitiesStatus: BatchAddEntitiesStatus) {
            this.saving = addEntitiesStatus.status === OperationStatus.loading;
            if (addEntitiesStatus.status === OperationStatus.success) {
                if (addEntitiesStatus.failures.length === 0) {
                    this.$emit('finish', this.needCollectEvidence);
                } else if (addEntitiesStatus.failures[0].error.error === OperationError.unknown) {
                    this.alertConfig = {
                        type: 'danger',
                        title: 'Cannot connect the entity, an unknown error occurred.',
                    };
                }
            } else if (addEntitiesStatus.status === OperationStatus.error) {
                this.alertConfig = {
                    type: 'danger',
                    title: 'Sorry, something went wrong during add entities. Please try again later.',
                };
            }
        }

        @Watch('updateEntitiesStatus')
        private async onUpdateEntitiesStatusChange(updateEntitiesStatus: BatchAddEntitiesStatus) {
            this.saving = updateEntitiesStatus.status === OperationStatus.loading;
            if (updateEntitiesStatus.status === OperationStatus.success) {
                if (updateEntitiesStatus.failures.length > 0 &&
                    updateEntitiesStatus.failures.every((x) => x.error.error === OperationError.unknown)) {
                    this.updateError = 'Cannot update the entities, unknown errors occurred.';
                } else {
                    this.$emit('finish', this.needCollectEvidence);
                }
            } else if (updateEntitiesStatus.status === OperationStatus.error) {
                this.updateError = 'Sorry, something went wrong during update entities. Please try again later.';
                this.alertConfig = {
                    type: 'danger',
                    title: this.updateError,
                };
            }
        }

        private get addEntitiesStatus(): BatchAddEntitiesStatus {
            return this.$store.state.entityManagement.addEntitiesStatus[this.rpName] || {
                status: OperationStatus.uninitialized,
                count: 0,
                successCount: 0,
                successes: [],
                failures: [],
            };
        }

        private get updateEntitiesStatus(): BatchUpdateEntitiesStatus {
            return this.$store.state.entityManagement.updateEntitiesStatus[this.rpName] || {
                status: OperationStatus.uninitialized,
                successCount: 0,
                failureCount: 0,
                totalCount: 0,
                successes: [],
                failures: [],
            };
        }

        private get customPropertiesStatus(): { status: OperationStatus, list: CustomProperty[] } {
            return this.$store.state.dashboard.customProperties[this.rpName] || {
                status: OperationStatus.uninitialized,
                list: [],
            };
        }

        private get clientRequirementTypesStatus(): { status: OperationStatus, list: string[] } {
            return this.$store.state.dashboard.clientRequirementTypes[this.rpName] || {
                status: OperationStatus.uninitialized,
                list: [],
            };
        }

        private get requirementDefinitions(): Record<string, TprmRequirementDefinition> {
            return this.$store.state.dashboard.tprmRequirementsDefinitions || [];
        }

        private get nonMultiInstanceRequirementTypes(): string[] {
            return this.clientRequirementTypesStatus.list.filter(
                (type) => !this.requirementDefinitions[type]?.multiInstance,
            );
        }

        private get loading(): boolean {
            return this.customPropertiesStatus.status === OperationStatus.loading
                || this.clientRequirementTypesStatus.status === OperationStatus.loading;
        }

        private get entityExists(): boolean {
            return this.addEntitiesStatus.failures.length > 0 &&
                this.addEntitiesStatus.failures[0].error.error === OperationError.keyConflict;
        }

        private get existedInsured(): EntityInput | null {
            return this.entityExists
                ? convertEntityInputDataToView(this.addEntitiesStatus.failures[0].element)
                : null;
        }

        private mounted() {
            if (this.customPropertiesStatus.status !== OperationStatus.loading) {
                this.$store.actions.dashboard.loadCustomProperties({ rpName: this.rpName });
            }
            if (this.clientRequirementTypesStatus.status !== OperationStatus.loading) {
                this.$store.actions.dashboard.loadClientRequirementTypes({ rpName: this.rpName });
            }
        }

        private close(): void {
            this.opened = false;
            this.$store.actions.entityManagement.clearAddEntitiesStatus({ rpName: this.rpName });
            this.$emit('abort');
        }

        private updateInsured(entity: EntityInput) {
            this.$store.actions.entityManagement.updateEntities({
                rpName: this.rpName,
                entities: [ entity ],
            });
        }

        private addInsured(entity: EntityInput, hasPastExpiration: boolean) {
            this.alertConfig = null;
            this.needCollectEvidence = hasPastExpiration;
            this.$store.actions.entityManagement.addEntities({ rpName: this.rpName, entities: [ entity ] });
        }
    }
</script>

<template>
    <div class="ActionsReview">
        <Page :loading="isLoading" title="Actions">
            <div class="ActionsReview__container">
                <ActionableAlert
                    v-if="alertConfig"
                    class="ActionsReview__alert"
                    :title="alertConfig.title"
                    :name="alertConfig.name"
                    :type="alertConfig.type"
                    :icon="alertConfig.icon"
                    :action-title="alertConfig.actionTitle"
                    @action="undoResolvingAction"
                />
                <ActionReviewDetails
                    v-if="currentAction"
                    :action-review="currentAction"
                    :current-position="currentActionIndex == null ? null : currentActionIndex + 1"
                    :max-position="entityActionsStatus.list.length"
                    :endorsement-categories="endorsementCategoriesStatus.list"
                    @back="navigateBack"
                    @previous="previousAction"
                    @next="nextAction"
                    @resolve="resolveAction"
                />
                <div v-else>
                    <div
                        v-if="filters"
                        class="ActionsReview__filters"
                    >
                        <Chip
                            v-if="filters.entityId"
                            :id="filters.entityId"
                            class="ActionsReview__entityFilterChip"
                            :title="entityName"
                            allow-close
                            @closeChip="removeFilter(['entityId', 'insuredId'])"
                        />
                    </div>
                    <EntityActionsTable
                        :entity-actions="entityActionsStatus.list"
                        :sort="sorting"
                        :no-action-message="noActionMessage"
                        @sort="changeSorting"
                        @review="navigateToDetails($event)"
                    />
                </div>
            </div>
        </Page>
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Watch } from '@evidentid/vue-property-decorator';
    import { isEqual, noop, omit, pick } from 'lodash';
    import Page from '@/layouts/Page.vue';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import { EndorsementCategoriesStatus, InsuredActionsStatus } from '@/modules/entity-actions-review/vuex';
    import EntityActionsTable
        from '@/modules/entity-actions-review/components/EntityActionsTable/EntityActionsTable.vue';
    import { DashboardState } from '@/modules/dashboard/vuex/dashboard';
    import {
        ActionResolveInput,
        AcceptanceActionResolveInput,
        ActionReview,
        ActionType,
    } from '@evidentid/tprm-portal-lib/models/entity-actions-review';
    import { deserializeSorting, serializeSorting, Sorting, SortingDirection } from '@evidentid/universal-framework/sorting';
    import ActionReviewDetails
        from '@/modules/entity-actions-review/components/ActionReviewDetails/ActionReviewDetails.vue';
    import { ActionableAlertConfig } from '@/modules/entity-details/components/ActionableAlert/types';
    import { getActionValue } from '@/modules/entity-actions-review/utils/getActionValue';
    import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
    import ActionableAlert from '@/modules/entity-details/components/ActionableAlert/ActionableAlert.vue';
    import { ActionReviewFilters } from '@/modules/entity-actions-review/types';
    import { getActionReviewFilterKeys } from '@/modules/entity-actions-review/utils/actionReviewFilter';
    import { Chip } from '@evidentid/dashboard-commons/components/ChipList';
    import { Entity } from '@evidentid/tprm-portal-lib/models/dashboard';

    @Component({
        components: {
            ActionableAlert,
            ActionReviewDetails,
            Chip,
            EntityActionsTable,
            Page,
        },
    })
    export default class ActionsReview extends Vue {
        private readonly defaultSorting = { column: 'createdAt', direction: SortingDirection.desc };
        private lastRpName!: string;
        private currentAction: ActionReview | null = null;
        private currentActionIndex: number | null = null;
        private undoActionId: string | null = null;
        private alertConfig: ActionableAlertConfig | null = null;
        private alertTimeout: any = null;
        private noEntityFound: boolean = false;

        private get rpName(): string {
            return this.$rp.current!;
        }

        private get entitiesStatus() {
            return (this.$store.state.dashboard as DashboardState).entities[this.rpName] || {
                count: Infinity,
                finished: false,
                loading: false,
                list: [],
            };
        }

        private get entityActionsStatus(): InsuredActionsStatus {
            return this.$store.state.insuredActionsReview.insuredActions[this.rpName] || {
                status: OperationStatus.uninitialized,
                list: [],
            };
        }

        private get endorsementCategoriesStatus(): EndorsementCategoriesStatus {
            return this.$store.state.insuredActionsReview.endorsementCategories[this.rpName] || {
                status: OperationStatus.uninitialized,
                list: [],
            };
        }

        private get resolvingActionStatus(): OperationStatus {
            return this.$store.state.insuredActionsReview.resolvingActionStatus[this.rpName]
                || OperationStatus.uninitialized;
        }

        private get isLoading(): boolean {
            return this.entityActionsStatus.status === OperationStatus.loading
                || this.entitiesStatus.loading
                || this.endorsementCategoriesStatus.status === OperationStatus.loading
                || this.resolvingActionStatus === OperationStatus.loading;
        }

        private get sorting(): Sorting {
            return deserializeSorting(this.$route.query.sort) || this.defaultSorting;
        }

        private get isFirstAction(): boolean {
            return this.currentActionIndex === 0;
        }

        private get isLastAction(): boolean {
            return this.currentActionIndex === this.entityActionsStatus.list.length - 1;
        }

        private get filters(): ActionReviewFilters {
            const { entityId, insuredId } = pick(this.$route.query, getActionReviewFilterKeys());
            if (insuredId || entityId) {
                return { entityId: (insuredId || entityId) as string };
            }
            return {};
        }

        private get entityName(): string {
            return this.filteredByEntity?.displayName || 'Entity';
        }

        private get filteredByEntity(): Entity | null {
            if (!this.filters.entityId) {
                return null;
            }
            return this.entitiesStatus.list.find((x) => this.filters.entityId === x.id) || null;
        }

        private get noActionMessage(): string | null {
            if (!this.filters.entityId) {
                return null;
            }
            return this.filteredByEntity
                ? `All available actions for ${this.filteredByEntity.displayName} have been completed.`
                : `There are no available actions to be completed for this entity.`;
        }

        @Watch('$rp.current', { immediate: true })
        private handleRpChange(rpName: string): void {
            // Save information about the last resource, which should be cleared during destroy
            this.lastRpName = rpName;
            Promise.all([ this.loadInsuredActions(), this.loadEntity() ]);
            this.setCurrentActionAndIndex();
            this.loadEndorsementCategories();
        }

        @Watch('$route.query')
        private handleQueryChange(current: any, previous: any) {
            if (!isEqual(omit(current, 'id'), omit(previous, 'id'))) {
                Promise.all([ this.reloadInsuredActions(), this.loadEntity() ]);
            }
            if (current.id !== previous.id) {
                this.setCurrentActionAndIndex();
            }
        }

        @Watch('entityActionsStatus', { immediate: true })
        private onInsuredActionsStatusChange(): void {
            if (this.entityActionsStatus.status === OperationStatus.success &&
                this.entityActionsStatus.list.length > 0 &&
                this.$route.query?.id) {
                this.setCurrentActionAndIndex();
            }
        }

        private async reloadInsuredActions(): Promise<void> {
            await this.$store.actions.insuredActionsReview.clearInsuredActions({ rpName: this.rpName });
            await this.loadInsuredActions();
        }

        private setCurrentActionAndIndex(): void {
            const actionId = this.$route.query?.id || null;
            const index = this.entityActionsStatus.list.findIndex((x) => x.id === actionId);
            this.currentActionIndex = index >= 0 ? index : null;
            this.currentAction = index >= 0
                ? this.entityActionsStatus.list[index]
                : null;
        }

        private navigateToDetails(id: string): void {
            this.$router.push({
                ...this.$route,
                query: { ...this.$route.query, id },
            } as any).catch(noop);
        }

        private navigateBack(): void {
            this.$router.push({
                ...this.$route,
                query: {
                    ...omit(this.$route.query, 'id'),
                },
            } as any).catch(noop);
        }

        private async loadEntity(): Promise<void> {
            if (this.filters.entityId && !this.entitiesStatus.loading) {
                try {
                    await this.$store.actions.dashboard.loadEntity({
                        rpName: this.rpName,
                        id: this.filters.entityId,
                    });
                    this.noEntityFound = false;
                } catch (err) {
                    this.noEntityFound = true;
                }
            }
        }

        private async loadInsuredActions(): Promise<void> {
            if (this.entityActionsStatus.status !== OperationStatus.loading) {
                await this.$store.actions.insuredActionsReview.loadInsuredActions({
                    rpName: this.rpName,
                    sort: serializeSorting(this.sorting) || null,
                    ...(Object.keys(this.filters).length > 0 && { filters: this.filters }),
                });
            }
        }

        private async loadEndorsementCategories(): Promise<void> {
            if (this.endorsementCategoriesStatus.status !== OperationStatus.loading) {
                await this.$store.actions.insuredActionsReview.loadEndorsementCategories({
                    rpName: this.rpName,
                });
            }
        }

        private changeSorting(sort: Sorting): void {
            this.$router.push({
                ...this.$route,
                query: { ...this.$route.query, sort: serializeSorting(sort) },
            } as any);
        }

        private nextAction(): void {
            if (this.currentActionIndex != null && !this.isLastAction) {
                this.navigateToDetails(this.entityActionsStatus.list[this.currentActionIndex + 1].id);
            }
        }

        private previousAction(): void {
            if (this.currentActionIndex != null && !this.isFirstAction) {
                this.navigateToDetails(this.entityActionsStatus.list[this.currentActionIndex - 1].id);
            }
        }

        private async resolveAction(data: ActionResolveInput): Promise<void> {
            if (this.currentAction && this.currentActionIndex != null) {
                this.undoActionId = this.currentAction.id;
                const actionTobeResolved = this.currentAction;
                const nextAction = this.isLastAction && this.isFirstAction
                    ? null
                    : this.isLastAction
                        ? this.entityActionsStatus.list[this.currentActionIndex - 1]
                        : this.entityActionsStatus.list[this.currentActionIndex + 1];
                await this.$store.actions.insuredActionsReview.resolveInsuredAction({
                    rpName: this.lastRpName,
                    actionId: this.currentAction.id,
                    resolve: data,
                });
                if (this.resolvingActionStatus === OperationStatus.success) {
                    this.showResolvedAlert(actionTobeResolved, data);
                    this.currentActionIndex = null;
                    this.currentAction = null;
                    if (nextAction) {
                        this.navigateToDetails(nextAction.id);
                    } else {
                        this.navigateBack();
                    }
                } else if (this.resolvingActionStatus === OperationStatus.error) {
                    this.alertConfig = {
                        type: 'danger',
                        title: 'Error resolving the action',
                    };
                    this.alertTimeout = setTimeout(() => {
                        this.alertConfig = null;
                    }, 5000);
                }
            }
        }

        private showResolvedAlert(
            resolvedAction: ActionReview,
            resolveData: ActionResolveInput,
        ): void {
            const actionVerb = (resolveData as AcceptanceActionResolveInput).accepted
                ? 'accepted'
                : 'rejected';
            const alertMessage = resolvedAction.action.$action === ActionType.entityNameMatchingV1
                ? `${getActionValue(resolvedAction.action)} ${actionVerb}`
                : `${getActionValue(resolvedAction.action)} was saved and removed`;
            this.alertConfig = {
                type: 'success',
                title: alertMessage,
                // TODO(PRODUCT-17635): enable Undo once BE supports it
                // actionTitle: 'Undo',
                icon: faCheckCircle,
            };
            this.alertTimeout = setTimeout(() => {
                this.alertConfig = null;
            }, 5000);
        }

        private removeFilter(filter: string[]) {
            this.$router.push({
                ...this.$route,
                query: omit(this.$route.query, filter),
            } as any).catch(noop);
        }

        private async undoResolvingAction(): Promise<void> {
            // TODO: enable this feature once BE supports it
            // not sure what API to call to revert. after revert, call loadAll, call navigate to undo Id
            // if (this.undoActionId) {
            //     await this.$store.actions.insuredActionsReview.resolveInsuredAction({
            //         rpName: this.lastRpName,
            //         actionId: this.undoActionId,
            //         // will probably going to be change once API is ready so not worth to change model now
            //         resolve: null as any,
            //     });
            //     clearTimeout(this.alertTimeout);
            //     const backupId = this.undoActionId;
            //     this.alertConfig = null;
            //     this.undoActionId = null;
            //     await this.loadInsuredActions();
            //     this.navigateToDetails(backupId);
            // }
        }

        private destroyed() {
            this.$store.actions.dashboard.clearEntitiesList({ rpName: this.lastRpName });
            this.$store.actions.insuredActionsReview.clearInsuredActions({ rpName: this.lastRpName });
            this.$store.actions.insuredActionsReview.clearResolvingInsuredActionStatus({ rpName: this.lastRpName });
            this.$store.actions.insuredActionsReview.clearEndorsementCategories({ rpName: this.lastRpName });
            clearTimeout(this.alertTimeout);
        }
    }
</script>

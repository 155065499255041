import omitBy from 'lodash/omitBy';
import pickBy from 'lodash/pickBy';
import isNil from 'lodash/isNil';
import JsonSchema, { JsonSchemaBasicObject } from '@evidentid/json-schema/interfaces/JsonSchema';
import { getCoverageAddressObjectString } from '@/modules/entity-details/utils/getCoverageAddressObjectString';
import { formatPhoneNumber } from '@/modules/entity-details/utils/formatPhoneNumber/formatPhoneNumber';
import {
    ComplianceStatus,
    EvaluationResult,
} from '@evidentid/tprm-portal-lib/models/entity-details';
import { EntityRequirement } from '@evidentid/tprm-portal-lib/models/entity-details/EntityRequirement.model';

export function isEmptyValue(value: any): boolean {
    return isNil(value) ||
        (
            typeof value === 'object' &&
            Object.values(value).every((x) => isNil(x))
        );
}

export function isNonCompliantField(fieldName: string, evaluationResults: EvaluationResult[]): boolean {
    return evaluationResults.some(
        (result) =>
            result.usedFields?.some((usedField) => usedField.split('/').pop() === fieldName) &&
            result.result === ComplianceStatus.nonCompliant,
    );
}

export function shouldBeOmitted(key: string, value: any, evaluationResults: EvaluationResult[]): boolean {
    const notEvaluatedEmpty = !isNonCompliantField(key, evaluationResults) && isEmptyValue(value);
    return key === 'coverageStatus' || notEvaluatedEmpty;
}

export function getProducerInfo(
    requirement: EntityRequirement,
): { title: string, value: string | null }[] {
    return [
        {
            title: 'Producer Business Name',
            value: requirement.details.producerName || null,
        },
        { title: 'Producer Contact Name', value: requirement.details.producerContactName || null },
        { title: 'Producer Email', value: requirement.details.producerEmail || null },
        { title: 'Producer Telephone', value: requirement.details.producerTelephone || null },
        {
            title: 'Producer Address',
            value: getCoverageAddressObjectString(requirement.details.producerAddress) || null,
        },
    ];
}

export function isMultiPropsField(property: { [key: string]: JsonSchema } | {}): boolean {
    return 'properties' in property;
}

export function omitMultiPropFieldsFromSchema(schema: JsonSchemaBasicObject): JsonSchemaBasicObject {
    return {
        ...schema,
        properties: 'properties' in schema
            ? omitBy(schema.properties, isMultiPropsField)
            : {},
    };
}

export function pickMultiPropFieldsFromSchema(schema: JsonSchemaBasicObject): JsonSchemaBasicObject {
    return {
        ...schema,
        properties: 'properties' in schema
            ? pickBy(schema.properties, isMultiPropsField)
            : {},
    };
}

<template>
    <div class="JsonSchemaArrayView">
        <div class="JsonSchemaArrayView__title">
            {{ form.schema.title }}
        </div>
        <div class="JsonSchemaArrayView__valueWithError">
            <template
                v-if="form.schema.items.type === JsonFormType.object || form.schema.items.type === JsonFormType.array"
            >
                <div v-for="(item, index) in (value || [])" :key="index" class="JsonSchemaArrayForm__item">
                    <v-component
                        :is="FormElementComponent"
                        :id="`${id}[${index}]`"
                        :key="index"
                        :currency="currency"
                        :custom-component-input="customComponentInput"
                        :depth="depth + 1"
                        :form="form.item"
                        :value="item"
                        v-on="$listeners"
                    />
                </div>
            </template>
            <div v-else
                class="JsonSchemaArrayView__value"
                :class="displayedValueAdditionalClass"
            >
                {{ displayValue }}
            </div>
            <EvaluationError v-if="evaluationError" :evaluation-error="evaluationError" v-on="$listeners" />
        </div>
    </div>
</template>

<script lang="ts">
    import { Component } from '@evidentid/vue-property-decorator';
    import { JsonFormArray, JsonFormType } from '@evidentid/json-schema/interfaces/JsonForm';
    import {
        JsonSchemaBase, JsonSchemaString,
        JsonSchemaType,
    } from '@evidentid/json-schema/interfaces/JsonSchema';
    import AbstractJsonSchemaView
        from '@/modules/entity-details/components/JsonSchemaView/elements/AbstractJsonSchemaView';
    import EvaluationError from '@/modules/entity-details/components/EvaluationError/EvaluationError.vue';

    @Component({
        components: { EvaluationError },
        inheritAttrs: false,
    })
    export default class JsonSchemaArrayView extends AbstractJsonSchemaView<JsonFormArray, any[]> {
        private JsonFormType = JsonFormType;

        /* TODO(PRODUCT-17642): Improvement to make it working more accuratly
        * It should have utilities for each of type, and handle that
        * It should have separate views for each of type, and handle that
        * It should have at least const definition:
        *     Record<JsonSchemaType, (value: any) => string> = {} map with value factories for each type
        * */
        private formatTypes(value: any): string {
            const itemSchema = this.form.item.schema as JsonSchemaBase;
            if (itemSchema.type === JsonSchemaType.string && (itemSchema as JsonSchemaString).format === 'phone') {
                // @ts-ignore:
                return window.intlTelInputUtils.formatNumber(
                    value, null, window.intlTelInputUtils.numberFormat.NATIONAL);
            } else if (itemSchema.type === JsonSchemaType.boolean) {
                return value ? 'Yes' : 'No';
            } else {
                return value;
            }
        }

        private get displayValue(): string {
            return this.value.map(this.formatTypes).join(', ');
        }
    }
</script>

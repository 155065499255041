<template>
    <div class="CredentialTypeMappingResolution">
        <MatchingSelectionReviewBase
            provided-subject-title="Provided Credential"
            matching-subject-title="Credential Type"
            no-matches-text="No Match"
            :action-review="actionReview"
            :provided="actionDetails.providedCredentialType"
            :matching-list="actionDetails.possibleCredentialTypes"
            @resolve="onResolve"
        />
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import {
        ActionReview,
        ActionType,
    } from '@evidentid/tprm-portal-lib/models/entity-actions-review';
    import { typeNarrowing } from '@evidentid/dashboard-commons/utils/typeNarrowing';
    import { WRONG_ACTION_DETAILS_TYPE_MESSAGE } from '@/modules/entity-actions-review/constants';
    import MatchingSelectionReviewBase
        from '@/modules/entity-actions-review/components/MatchingSelectionReviewBase/MatchingSelectionReviewBase.vue';
    import {
        CredentialTypeMappingResolutionActionDetails,
    } from '@evidentid/tprm-portal-lib/models/entity-actions-review/actions/credential-type-mapping-resolution/CredentialTypeMappingResolutionActionDetails.model';
    import {
        CredentialTypeMappingResolutionActionResolveInput,
    } from '@evidentid/tprm-portal-lib/models/entity-actions-review/actions/credential-type-mapping-resolution/CredentialTypeMappingResolutionActionResolveInput.model';

    @Component({
        components: { MatchingSelectionReviewBase },
    })
    export default class CredentialTypeMappingResolution extends Vue {
        @Prop({ type: Object, default: () => ({}) })
        private actionReview!: ActionReview;

        private get actionDetails(): CredentialTypeMappingResolutionActionDetails {
            return typeNarrowing(
                this.actionReview.action,
                this.actionReview.action.$action === ActionType.credentialTypeMappingResolution,
                WRONG_ACTION_DETAILS_TYPE_MESSAGE,
            );
        }

        private onResolve(val: string | null): void {
            const data: CredentialTypeMappingResolutionActionResolveInput = {
                $action: ActionType.credentialTypeMappingResolution,
                credentialType: val,
            };
            this.$emit('resolve', data);
        }
    }
</script>

import createCustomXhrErrorFactory from '@evidentid/universal-framework/createCustomXhrErrorFactory';
import parseJsonConditionally from '@evidentid/universal-framework/parseJsonConditionally';
import url from '@evidentid/universal-framework/url';
import {
    TprmCredentialType,
    TprmRequirementDefinition,
} from '@evidentid/tprm-portal-lib/models/common';

const statusErrorFactories: Record<number, (xhr?: XMLHttpRequest) => Error> = {
    401: createCustomXhrErrorFactory('unauthorized', 'You are not authorized for selected operation'),
    403: createCustomXhrErrorFactory('forbidden', 'You are not authorized for selected operation'),
    404: createCustomXhrErrorFactory('not-found', 'The requested resource is not found'),
    440: createCustomXhrErrorFactory('session-expired', 'Your session has expired'),
};

interface ApiClientTokens {
    accessToken: string | null;
    idToken: string | null;
}

export class GatewayApiClient {
    protected baseUrl: string;
    protected getTokens: () => Promise<ApiClientTokens> = () => Promise.resolve({
        accessToken: null,
        idToken: null,
    });

    public constructor(baseUrl: string) {
        this.baseUrl = baseUrl.replace(/\/+$/, '');
    }

    public setTokens(token: () => (Promise<ApiClientTokens> | ApiClientTokens)): this {
        this.getTokens = () => Promise.resolve(token());
        return this;
    }

    public getTprmRequirements(): Promise<TprmRequirementDefinition[]> {
        return this.request('GET', url`/insurance-ui/models/requirements`);
    }

    public getTprmCredentialTypes(): Promise<TprmCredentialType[]> {
        return this.request('GET', url`/insurance/models/credentials`);
    }

    private async request<T = any>(
        method: string,
        requestUrl: string,
        data?: any,
        allowedAdditionalHttpStatuses: number[] = [],
    ): Promise<T> {
        // Retrieve current auth token
        const { accessToken, idToken } = await this.getTokens();

        return new Promise((resolve, reject) => {
            // Initialize XHR object
            const xhr = new XMLHttpRequest();
            xhr.open(method, `${this.baseUrl}${requestUrl}`);
            xhr.setRequestHeader('content-type', 'application/json');
            if (accessToken) {
                xhr.setRequestHeader('authorization', `Bearer ${accessToken}`);
            }
            if (idToken) {
                xhr.setRequestHeader('idtoken', idToken);
            }
            xhr.setRequestHeader('cache-control', 'no-cache');

            // Handle valid response (read as JSON, but fallback to auth error or responseText)
            xhr.addEventListener('load', () => {
                const statusErrorFactory = statusErrorFactories[xhr.status];
                if (statusErrorFactory) {
                    return reject(statusErrorFactory(xhr));
                }
                const result = parseJsonConditionally(xhr.responseText);
                const success = (
                    (xhr.status >= 200 && xhr.status < 300) ||
                    allowedAdditionalHttpStatuses.includes(xhr.status)
                );
                const finish = success ? resolve : reject;
                return finish(result);
            });

            // Handle connection problem
            xhr.addEventListener('error', (error) => {
                console.error(`Config Api Client request error: ${requestUrl} [${xhr.status}]`, error);
                reject(error);
            });

            // Initialize request
            if (data === undefined) {
                xhr.send();
            } else {
                xhr.send(JSON.stringify(data));
            }
        });
    }
}

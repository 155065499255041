import { createModuleBuilder } from '@evidentid/dashboard-commons/app';
import Dashboard from '@/modules/dashboard/views/Dashboard.vue';
import RpWebApiClient from '@evidentid/rpweb-api-client';
import Tracker from '@evidentid/tracker';
import Logger from '@evidentid/logger';
import { GatewayApiClient } from '@evidentid/gateway-api-client';
import { observeTrackAndLog } from '@/utils/vuex-common/observeTrackAndLog';
import vuexDashboardModule from './vuex/dashboard';

function trackAndLog(app: any): void {
    const module = 'Dashboard';
    observeTrackAndLog(app,
        { start: 'startEntitiesListUpdate', finish: 'pushToEntitiesList', fail: 'failEntitiesListUpdate' },
        { module, title: 'Entities', action: 'Get' },
    );
    observeTrackAndLog(app,
        { start: 'startLoadingCustomProperties', finish: 'finishLoadingCustomProperties', fail: 'failLoadingCustomProperties' },
        { module, title: 'Custom Properties', action: 'Get' },
    );
    observeTrackAndLog(app,
        { start: 'startLoadingRequirementTypes', finish: 'finishLoadingRequirementTypes', fail: 'failLoadingRequirementTypes' },
        { module, title: 'Requirement Types', action: 'Get' },
    );

    observeTrackAndLog(app,
        { start: 'startLoadingEntityStatistics', finish: 'finishLoadingEntityStatistics', fail: 'failLoadingEntityStatistics' },
        { module, title: 'Entity Statistics', action: 'Get' },
    );
    observeTrackAndLog(app,
        { start: 'startLoadingRequestsConfig', finish: 'finishLoadingRequestsConfig', fail: 'failLoadingRequestsConfig' },
        { module, title: 'Requests Config', action: 'Get' },
    );
    observeTrackAndLog(app,
        { finish: 'updateRiskRequirementDefinitions' },
        { module, title: 'Risk Requirement Definitions', action: 'Get' },
    );
    observeTrackAndLog(app,
        { start: 'startLoadingCredentialTypes', finish: 'finishLoadingCredentialTypes', fail: 'failLoadingCredentialTypes' },
        { module, title: 'Credential Types', action: 'Get' },
    );
}

export const dashboardModule = createModuleBuilder()
    .registerRoutes(() => [
        {
            path: '/:rpId?/dashboard',
            name: 'dashboard',
            component: Dashboard as any,
            meta: { title: 'Dashboard' },
        },
    ])
    .demandContext<{
        rpweb: RpWebApiClient;
        gatewayClient: GatewayApiClient;
        tracker?: Tracker<any>;
        logger?: Logger<any>;
    }>()
    .registerVuex({ dashboard: vuexDashboardModule.instantiateModule })
    .execute((app) => {
        app.observer.onChange((state) => (state as any).route, (newRoute, oldRoute) => {
            if (newRoute.params.rpId !== oldRoute.params.rpId) {
                app.store.actions.dashboard.loadCategorizedEnumLabels({ rpName: newRoute.params.rpId });
            }
        });
        app.store.actions.dashboard.loadRiskRequirementDefinitions();
        app.store.actions.dashboard.loadCredentialTypes();
        trackAndLog(app);
    })
    .end();

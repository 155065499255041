<template>
    <div class="CountryFilterInput">
        <FormElement
            ref="countryDropdownRef"
            :label="label"
        >
            <FormInput>
                <Dropdown
                    :options="options"
                    :selected="currentOption"
                    placeholder="Select Country"
                    @input="inputFilter"
                    @open="scrollToElement('countryDropdownRef')"
                >
                    <template v-if="value" #prepend>
                        <CountryFlag :country="value" />
                    </template>
                </Dropdown>
            </FormInput>
        </FormElement>
    </div>
</template>

<script lang="ts">
    import { PropType } from 'vue';
    import { Vue } from '@evidentid/vue-property-decorator';
    import { FormElement, FormInput } from '@evidentid/dashboard-commons/components/Form';
    import { Dropdown } from '@evidentid/dashboard-commons/components/Dropdown';
    import { DropdownOption } from '@evidentid/dashboard-commons/components/Dropdown/types';
    import CountryFlag from 'vue-country-flag';

    export default Vue.extend({
        name: 'CountryFilterInput',
        components: {
            CountryFlag,
            Dropdown,
            FormElement,
            FormInput,
        },
        props: {
            label: {
                type: String as PropType<string>,
                default: '',
            },
            value: {
                type: String as PropType<string>,
                default: '',
            },
            countries: {
                type: Array as PropType<string[]>,
                default: () => [],
            },
            countryLabels: {
                type: Array as PropType<string[]>,
                default: () => [],
            },
        },
        data() {
            return {
                options: this.countries.map((country, index) => ({ value: country, label: this.countryLabels[index] })),
            };
        },
        computed: {
            currentOption(): DropdownOption[] {
                if (!this.value) {
                    return [];
                }
                const label =
                    this.countryLabels[this.countries.findIndex((label) => label === this.value)] || this.value;
                return [ { value: this.value, label } ];
            },
        },
        methods: {
            emitScrollDistance(distance: number): void {
                this.$emit('scroll-to', distance);
            },
            inputFilter(option: DropdownOption): void {
                this.$emit('input', option.value);
            },
            scrollToElement(refName: string): void {
                // refs returns array when it's within v-for
                const formElementList = this.$refs[refName] as FormElement;
                if (formElementList) {
                    this.$nextTick(() => {
                        this.emitScrollDistance((formElementList.$el as HTMLElement).offsetTop);
                    });
                }
            },
        },
    });
</script>

import { camelCase, startCase, upperFirst } from 'lodash';
import { observeTrackAndLog } from '@/utils/vuex-common/observeTrackAndLog';

export interface TrackAndLogEntry {
    subjectName: string;
    action: string;
    mutationNames?: { start?: string, finish?: string, fail?: string };
}

export interface TrackAndLogConfig {
    module: string;
    entries: TrackAndLogEntry[];
}

const actionVerbs: Record<string, string> = {
    get: 'Loading',
    load: 'Loading',
    create: 'Creating',
    update: 'Updating',
    delete: 'Deleting',
    patch: 'Patching',
};

export function observeTrackAndLogPerModule(app: any, config: TrackAndLogConfig) {
    const pascalCase = (input: string): string => upperFirst(camelCase(input));
    config.entries.forEach((entry) => {
        let mutationNames = entry.mutationNames;
        if (!mutationNames) {
            const actionVerb = actionVerbs[entry.action.toLowerCase()] || pascalCase(entry.action);
            const subjectName = pascalCase(entry.subjectName);
            mutationNames = {
                start: `start${actionVerb}${subjectName}`,
                finish: `finish${actionVerb}${subjectName}`,
                fail: `fail${actionVerb}${subjectName}`,
            };
        }
        observeTrackAndLog(app, mutationNames, {
            module: config.module,
            title: startCase(entry.subjectName),
            action: startCase(entry.action),
        });
    });
}

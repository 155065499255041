<template>
    <div class="ApiKeySection">
        <div
            data-test-id="ApiKeySection__sectionTitle"
            class="font-medium mb-2"
        >
            API Key
        </div>
        <div
            data-test-id="ApiKeySection__sectionContainer"
            class="px-6 py-4 bg-white"
        >
            <div
                data-test-id="ApiKeySection__infoRow"
                class="flex items-center"
            >
                <FormElement label="Account Name">
                    <FormInput>
                        <input
                            data-test-id="ApiKeySection__accountNameInput"
                            type="text"
                            disabled
                            :value="accountName"
                        >
                    </FormInput>
                </FormElement>
                <FormElement label="API Key">
                    <FormInput>
                        <input
                            data-test-id="ApiKeySection__apiKeyInput"
                            disabled
                            :type="passwordOrTextType"
                            :value="apiKey"
                        >
                        <template #icon>
                            <EidIcon
                                data-test-id="ApiKeySection__copyButton"
                                class="text-eidTrustGreen cursor-pointer hover:opacity-75 select-none"
                                :icon-src="copyIcon"
                                alt="copyIcon"
                                @click="copyKey"
                            />
                        </template>
                    </FormInput>
                </FormElement>
                <div
                    data-test-id="ApiKeySection__viewKeyButton"
                    class="mt-6 text-eidTrustGreen cursor-pointer hover:opacity-75 select-none"
                    @click="toggleShowPassword"
                >
                    {{ viewOrHideText }}
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { Vue } from '@evidentid/vue-property-decorator';
    import { PropType } from 'vue';
    import { IconDefinition } from '@fortawesome/fontawesome-svg-core'
    import { faCheck } from '@fortawesome/free-solid-svg-icons';
    import { faCopy } from '@evidentid/dashboard-commons/assets/custom-icon-definitions';
    import { FormElement, FormInput } from '@evidentid/dashboard-commons/components/Form';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';

    const PASSWORD = 'password';
    const TEXT = 'text';
    const VIEW = 'View';
    const HIDE = 'Hide';

    export default Vue.extend({
        name: 'ApiKeySection',
        components: { FormElement, FormInput, EidIcon },
        props: {
            accountName: {
                type: String as PropType<string>,
                default: '',
                required: true,
            },
            apiKey: {
                type: String as PropType<string>,
                default: '',
                required: true,
            },
        },
        data() {
            return {
                passwordOrTextType: PASSWORD,
                viewOrHideText: VIEW,
                copyIcon: faCopy as IconDefinition,
                copiedTimout: null as any,
            };
        },
        methods: {
            toggleShowPassword(): void {
                this.passwordOrTextType = this.passwordOrTextType === PASSWORD ? TEXT : PASSWORD;
                this.viewOrHideText = this.viewOrHideText === VIEW ? HIDE : VIEW;
            },
            copyKey(): void {
                navigator.clipboard.writeText(this.apiKey);
                this.copyIcon = faCheck as IconDefinition;
                if (this.copiedTimout) {
                    clearTimeout(this.copiedTimout);
                }
                this.copiedTimout = setTimeout(() => {
                    this.copyIcon = faCopy;
                }, 2000);
            },
        },
    });
</script>

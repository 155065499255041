import Severity from '@evidentid/logger/Severity';
import startCase from 'lodash';

// eslint-disable-next-line max-params
function addCommonTracker(
    app: any, rpName: string | null, module: string, title: string, action: string, status: 'Request' | 'Finish' | 'Failed',
): void {
    const forRpStr = rpName ? ` for ${rpName}` : '';
    if (app.tracker) {
        app.tracker.track({
            name: `${startCase(action)} ${startCase(title)}`,
            description: `[${status}] ${startCase(action)} ${title.toLowerCase()}${forRpStr}`,
            rpName,
        });
    }
    if (app.logger) {
        app.logger.addBreadcrumb({
            ...(status === 'Failed' && { level: Severity.error }),
            category: `${startCase(module)} - ${startCase(title)}`,
            message: `[${status}] ${startCase(action)} ${title.toLowerCase()}${forRpStr}`,
            data: { rpName },
        });
    }
}

// eslint-disable-next-line max-params
export function addCommonStartTracker(
    app: any, rpName: string | null, module: string, title: string, action: string
): void {
    addCommonTracker(app, rpName, module, title, action, 'Request');
}

// eslint-disable-next-line max-params
export function addCommonFinishTracker(
    app: any, rpName: string | null, module: string, title: string, action: string
): void {
    addCommonTracker(app, rpName, module, title, action, 'Finish');
}

// eslint-disable-next-line max-params
export function addCommonFailTracker(
    app: any, rpName: string | null, module: string, title: string, action: string
): void {
    addCommonTracker(app, rpName, module, title, action, 'Failed');
}


import { CustomProperty } from '@evidentid/tprm-portal-lib/models/dashboard';
import { range } from 'lodash';
import {
    getFlattenedCustomPropertyNames,
} from '@/modules/entity-management/utils/get-flattened-custom-property-names/getFlattenedCustomPropertyNames';
import { standardColumnNames } from '@/modules/entity-management/utils/csv-sample/constants';
import { generateSampleRow } from '@/modules/entity-management/utils/csv-sample/helpers/generateSampleRow';

export function generateSampleCsvData(
    customProperties: CustomProperty[], requirementTypes: string[] = []
): (string | number)[][] {
    const getCoverageExpirationColumnLabel =
        (requirementType: string): string => `Existing ${requirementType} Policy Expiration Date`;
    return [
        [
            ...standardColumnNames,
            ...getFlattenedCustomPropertyNames(customProperties),
            ...requirementTypes.map(getCoverageExpirationColumnLabel),
        ],
        ...range(0, 10).map(() => generateSampleRow(customProperties, requirementTypes),
        ),
    ];
}

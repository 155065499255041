import {
    ActionDetails,
    ActionType,
    EvaluatedCollateralEntity,
    EvaluatedEntityMappingActionDetails,
} from '@evidentid/tprm-portal-lib/models/entity-actions-review';

function getEvaluatedEntityMappingActionValue(entityMappingAction: EvaluatedEntityMappingActionDetails): string {
    // [entityMappingExpansion]: currently only collateral type support, when expanded, add cases per type
    const requiredEntity = entityMappingAction.requiredEntity as EvaluatedCollateralEntity;
    return requiredEntity.description;
}

export function getActionValue(actionDetails: ActionDetails): any {
    switch (actionDetails.$action) {
        case ActionType.entityNameMatchingV1:
            return actionDetails.given;
        case ActionType.endorsementFormNumberV1:
            return actionDetails.formNumber;
        case ActionType.evaluatedEntityMappingV1:
            return getEvaluatedEntityMappingActionValue(actionDetails);
        case ActionType.nonExtractionCriterionResolutionV1:
            return actionDetails.insuredName;
        case ActionType.complianceWithCategoryResolution:
            return actionDetails.providedValue;
        case ActionType.credentialTypeMappingResolution:
            return actionDetails.providedCredentialType;
        default:
            return null;
    }
}

import vuex from './vuex';
import { createModuleBuilder } from '@evidentid/dashboard-commons/app';
import type { PersistingErrorModule } from '@evidentid/dashboard-commons/modules/persisting-error';
import type RpWebApiClient from '@evidentid/rpweb-api-client';
import Tracker from '@evidentid/tracker';
import Logger from '@evidentid/logger';
import EntityDocumentsListProcedure from './views/EntityDocumentsListProcedure.vue';
import EntityRequirementDocumentProcedure from '@/modules/entity-documents/views/EntityRequirementDocumentProcedure.vue';
import { TprmRequirementType } from '@evidentid/tprm-portal-lib/models/entity-details';
import { TprmRequirementModel } from '@evidentid/tprm-portal-lib/models/entity-details/TprmRequirementModel.model';

export const entityDocumentsModule = createModuleBuilder()
    .demand<PersistingErrorModule>()
    .demandContext<{ rpweb: RpWebApiClient }>()
    .registerVuex({ entityDocuments: vuex.instantiateModule })
    .registerProcedures<{
        showEntityRprDocuments: { rpName: string, email: string, requirementModels?: TprmRequirementModel[] };
        showEntityRequirementRprDocuments: {
            rpName: string;
            email: string;
            requirementType?: TprmRequirementType;
            requirementInstanceId?: string | null;
            requirementTypeLabel?: string;
            rprId?: string;
            entityId?: string;
            submissionDate?: string;
            verificationId?: string;
        };
    }>({
        showEntityRprDocuments: EntityDocumentsListProcedure,
        showEntityRequirementRprDocuments: EntityRequirementDocumentProcedure,
    })
    .demandContext<{ tracker?: Tracker<any>, logger?: Logger<any> }>()
    .execute((app) => {
        function onLoadDocumentsRequest(rpName: string, email: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Entities RprDocuments requested',
                    description: `Entities (${rpName}: ${email}) RprDocuments requested`,
                    rpName,
                    email,
                });
            }

            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'entity-rpr-documents',
                    message: '[Requested] Entities RprDocuments',
                    data: { rpName, email },
                });
            }
        }

        function onLoadDocumentsFinish(rpName: string, email: string, types: string[]) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Entities RprDocuments finished',
                    description: `Entities (${rpName}: ${email}) RprDocuments finished (${types.length})`,
                    rpName,
                    email,
                    count: types.length,
                });
            }

            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'entity-rpr-documents',
                    message: '[Finished] Entities RprDocuments',
                    data: { rpName, email, types },
                });
            }
        }

        function onLoadDocumentsFailure(rpName: string, email: string) {
            if (app.tracker) {
                app.tracker.track({
                    name: 'Entities RprDocuments failed',
                    description: `Entities (${rpName}: ${email}) RprDocuments failed`,
                    rpName,
                    email,
                });
            }

            if (app.logger) {
                app.logger.addBreadcrumb({
                    category: 'entity-rpr-documents',
                    message: '[Failed] Entities RprDocuments',
                    data: { rpName, email },
                });
            }
        }

        app.observer.onMutation('startLoadingRprDocument', (x) => onLoadDocumentsRequest(x.rpName, x.email));
        app.observer.onMutation('finishLoadingRprDocument', (x) => onLoadDocumentsFinish(x.rpName, x.email, x.content.map((a) => a.id)));
        app.observer.onMutation('failLoadingRprDocument', (x) => onLoadDocumentsFailure(x.rpName, x.email));
    })
    .end();

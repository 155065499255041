<template>
    <Row class="DocumentSubmissionHistoryTableRow" hoverable>
        <Cell
            data-test-id="DocumentSubmissionHistoryTableRow__requestDate"
        >
            {{ requestDate }}
        </Cell>
        <Cell data-test-id="DocumentSubmissionHistoryTableRow__submissionDateCell">
            <div class="flex justify-start item-center">
                <EidIcon
                    v-if="submission.isNoResponse || !submission.submissionDate || declineReason"
                    v-tooltip="tooltipMessage"
                    class="text-eidDangerRed mr-1.5"
                    data-test-id="DocumentSubmissionHistoryTableRow__submissionErrorIcon"
                    :icon-src="faExclamationTriangle"
                />
                <span data-test-id="DocumentSubmissionHistoryTableRow__submissionDateText">
                    {{ declineReason || submissionDate }}
                </span>
            </div>
        </Cell>
        <Cell data-test-id="DocumentSubmissionHistoryTableRow__requirementTypeCell">
            <div class="flex justify-start item-center">
                <EidIcon
                    v-if="submission.isCurrentSubmission"
                    v-tooltip="'Current document used for compliance.'"
                    class="text-eidTrustGreen mr-1.5"
                    data-test-id="DocumentSubmissionHistoryTableRow__currentIcon"
                    :icon-src="faStar"
                />
                <EidIcon
                    v-else-if="submission.isNotProvided"
                    v-tooltip="'Requirement was not provided in submission.'"
                    class="text-eidDangerRed mr-1.5"
                    data-test-id="DocumentSubmissionHistoryTableRow__coverageTypeErrorIcon"
                    :icon-src="faExclamationTriangle"
                />
                <span data-test-id="DocumentSubmissionHistoryTableRow__coverageTypeText">
                    {{ requirementTypeLabel }}
                </span>
            </div>
        </Cell>
        <Cell
            v-tooltip="{ classes: 'VTooltip--dark', content: viewDocumentTooltipMsgOrBlank }"
            data-test-id="DocumentSubmissionHistoryTableRow__viewDocumentCell"
            :class="{'cursor-not-allowed opacity-30': shouldDisableDocument}"
        >
            <DocumentDataOnlyBadge v-if="isShowingDataOnlyBadge" />
            <DocumentViewButton
                v-else-if="isShowingDocumentViewButton"
                :disable="shouldDisableDocument"
                @click="showDocument"
            />
            <div
                v-else
                class="text-sm inline-flex"
            >
                <EidIcon
                    v-tooltip="'Insured did not respond to this request.'"
                    class="text-eidDangerRed"
                    data-test-id="DocumentSubmissionHistoryTableRow__viewDocumentErrorIcon"
                    :icon-src="faExclamationTriangle"
                />
                <span class="inline-block ml-1.5">
                    -
                </span>
            </div>
        </Cell>
    </Row>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import moment from 'moment';
    import { faExclamationTriangle, faStar } from '@fortawesome/free-solid-svg-icons';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import { Cell, Row } from '@evidentid/dashboard-commons/components/Table';
    import { HistoricDocumentSubmission } from '@/models/HistoricDocumentSubmission.model';
    import { DECLINE_REASON_TEXTS, DECLINE_REASON_TOOLTIP_TEXTS } from './config';
    import { SubmissionMethod } from '@evidentid/tprm-portal-lib/models/common/SubmissionMethod.model';
    import DocumentDataOnlyBadge
        from '@/modules/entity-details/components/DocumentDataOnlyBadge/DocumentDataOnlyBadge.vue';
    import DocumentViewButton from '@/modules/entity-details/components/DocumentViewButton/DocumentViewButton.vue';
    import {
        isFieldImportApiOrWithoutDocumentUpload,
    } from '@/modules/entity-details/utils/isFieldImportApiOrWithoutDocumentUpload/isFieldImportApiOrWithoutDocumentUpload';

    export default Vue.extend({
        name: 'DocumentSubmissionHistoryTableRow',
        components: {
            DocumentViewButton,
            DocumentDataOnlyBadge,
            Cell,
            EidIcon,
            Row,
        },
        props: {
            submission: {
                type: Object as PropType<HistoricDocumentSubmission>,
                required: true,
            },
        },
        data() {
            return {
                faExclamationTriangle,
                faStar,
                SubmissionMethod,
            };
        },
        computed: {
            submissionHasResponse(): boolean {
                return !this.submission.isNoResponse;
            },
            shouldShowDocument(): boolean {
                return !this.submission.isNoResponse;
            },
            isFieldImportApiOrWithoutDocumentUploadVal(): boolean {
                return isFieldImportApiOrWithoutDocumentUpload({
                    submissionMethod: this.submission.submissionMethod,
                    tprmRequirementType: this.submission.coverageType,
                });
            },
            isShowingDataOnlyBadge(): boolean {
                return this.submissionHasResponse && this.isFieldImportApiOrWithoutDocumentUploadVal;
            },
            isShowingDocumentViewButton(): boolean {
                return this.submissionHasResponse && !this.isFieldImportApiOrWithoutDocumentUploadVal;
            },
            requestDate(): string {
                return this.submission.requestDate?.split('T')[0] || '-';
            },
            submissionDate(): string {
                const date = this.submission.submissionDate?.split('T')[0] || '-';
                return !this.submission.isNoResponse && date || '-';
            },
            shouldDisableDocument(): boolean {
                if (
                    !this.submission.submissionDate
                    || this.submission.isNoResponse
                    || (
                        !this.submission.isNoResponse
                        && this.submission.submissionMethod === SubmissionMethod.FieldImportApi
                    )
                ) {
                    return false;
                }
                const submissionMoment = moment(new Date(this.submission.submissionDate));
                const supportingDateMoment = moment(new Date('2022-06-01'));
                return submissionMoment.isBefore(supportingDateMoment);
            },
            viewDocumentTooltipMsgOrBlank(): string | null {
                return this.shouldDisableDocument ? 'Document not viewable. Contact support@evidentid.com to access.' : null;
            },
            declineReason(): string | null {
                if (!this.submission.declineReason) {
                    return null;
                }
                return DECLINE_REASON_TEXTS[this.submission.declineReason];
            },
            tooltipMessage(): string {
                const noSubmissionMsg = 'Insured did not respond to this request.';
                if (!this.submission.declineReason) {
                    return noSubmissionMsg;
                }
                return this.declineReason
                    ? DECLINE_REASON_TOOLTIP_TEXTS[this.submission.declineReason]
                    : noSubmissionMsg;
            },
            requirementTypeLabel(): string {
                return this.submission.requirementInstanceLabel
                    ? `${this.submission.coverageTypeLabel} - ${this.submission.requirementInstanceLabel}`
                    : this.submission.coverageTypeLabel;
            },
        },
        methods: {
            showDocument(): void {
                if (this.shouldShowDocument) {
                    this.$emit('show-document', this.submission);
                }
            },
        },
    });
</script>

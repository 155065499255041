<template>
    <JsonSchemaPhoneForm v-if="form.schema.format === 'phone'" v-bind="$props" disable-auto-format v-on="$listeners">
        <template v-slot:icon>
            <slot name="icon" />
        </template>
    </JsonSchemaPhoneForm>
    <JsonSchemaEmailForm v-else-if="form.schema.format === 'email'" v-bind="$props" v-on="$listeners">
        <template v-slot:icon>
            <slot name="icon" />
        </template>
    </JsonSchemaEmailForm>
    <JsonSchemaDateForm v-else-if="form.schema.format === 'date'"
        v-bind="$props"
        show-invalid-date
        v-on="$listeners"
    >
        <template v-slot:icon>
            <slot name="icon" />
        </template>
    </JsonSchemaDateForm>
    <JsonSchemaTextForm v-else v-bind="$props" v-on="$listeners">
        <template v-slot:icon>
            <slot name="icon" />
        </template>
    </JsonSchemaTextForm>
</template>

<script lang="ts">
    import { Component } from '@evidentid/vue-property-decorator';
    import { JsonFormBase } from '@evidentid/json-schema/interfaces/JsonForm';
    import { JsonSchemaString } from '@evidentid/json-schema/interfaces/JsonSchema';
    import { FormElement } from '@evidentid/dashboard-commons/components/Form';
    import AbstractJsonSchemaForm
        from '@evidentid/dashboard-commons/components/JsonSchemaForm/elements/AbstractJsonSchemaForm';
    import JsonSchemaPhoneForm
        from '@evidentid/dashboard-commons/components/JsonSchemaForm/elements/JsonSchemaPhoneForm.vue';
    import JsonSchemaTextForm
        from '@evidentid/dashboard-commons/components/JsonSchemaForm/elements/JsonSchemaTextForm.vue';
    import JsonSchemaEmailForm
        from '@evidentid/dashboard-commons/components/JsonSchemaForm/elements/JsonSchemaEmailForm.vue';
    import JsonSchemaDateForm
        from '@evidentid/dashboard-commons/components/JsonSchemaForm/elements/JsonSchemaDateForm.vue';

    @Component({
        components: { JsonSchemaEmailForm, JsonSchemaDateForm, JsonSchemaTextForm, JsonSchemaPhoneForm, FormElement },
        inheritAttrs: false,
    })
    export default class BulkImportStringField extends AbstractJsonSchemaForm<JsonFormBase<JsonSchemaString>, string> {
    }
</script>

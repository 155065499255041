import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import isEqual from 'lodash/isEqual';
import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';
import { CustomPropertyValues } from '@evidentid/tprm-portal-lib/models/dashboard/CustomProperty.model';
import { Entity } from '@evidentid/tprm-portal-lib/models/dashboard';

export function isNullishOrEmptyValue(val: any): boolean {
    return typeof val === 'object' ? isEmpty(val) : val == null || val === '';
}

export function isDeletingInsureFieldItem(
    diffKey: string,
    diffVal: any,
    customProperties: CustomPropertyValues | null): boolean {
    if (!customProperties) {
        return false;
    }
    const originalValue = customProperties[diffKey];
    const isDeleteItem = Array.isArray(originalValue)
        && Array.isArray(diffVal)
        && diffVal.length < originalValue.length;
    const isDeleteLastItem = Array.isArray(originalValue) && diffVal === null;
    return isDeleteItem || isDeleteLastItem;
}

export function editingRequiresPutOperation(objToCheck: Record<string, any>, diff: Record<string, any>): boolean {
    return Object.entries(diff).some(([ key, newVal ]) => {
        const addNew = isNullishOrEmptyValue(objToCheck[key]) && !isNullishOrEmptyValue(newVal);
        const clear = !isNullishOrEmptyValue(objToCheck[key]) && isNullishOrEmptyValue(newVal);
        return addNew || clear;
    });
}

export function getEntityFieldsDiff(oldVal: Record<string, any>, newVal: Record<string, any>): Record<string, any> {
    const diffOfChangeOrRemove = Object.entries(oldVal).reduce((acc, [ key, val ]) => {
        const change = !isNil(newVal[key]) && !isEqual(newVal[key], val);
        const clear = !isNullishOrEmptyValue(val) && isNullishOrEmptyValue(newVal[key]);
        return { ...acc, ...((change || clear) && { [key]: newVal[key] }) };
    }, {});
    const diffOfAddNew = Object.entries(newVal).reduce((acc, [ key, val ]) => {
        const add = isNil(oldVal[key]) && !isNullishOrEmptyValue(newVal[key]);
        return { ...acc, ...(add && { [key]: val }) };
    }, {});
    return { ...diffOfChangeOrRemove, ...diffOfAddNew };
}

import { IconDefinition } from '@fortawesome/fontawesome-svg-core'

export const faShareNodes = {
    prefix: 'fa',
    iconName: 'faShareNodes',
    icon: [
        16,
        13,
        [],
        'e002',
        'M3.71875 0.625C4.53906 0.625 5.25 1.33594 5.25 2.15625V2.59375H10.5V2.15625C10.5 1.33594 11.1836 0.625 12.0312 0.625H14.2188C15.0391 0.625 15.75 1.33594 15.75 2.15625V4.34375C15.75 5.19141 15.0391 5.875 14.2188 5.875H12.0312C11.1836 5.875 10.5 5.19141 10.5 4.34375V3.90625H5.25V4.34375C5.25 4.5625 5.19531 4.75391 5.11328 4.94531L7.19141 7.70703C7.32812 7.65234 7.49219 7.625 7.65625 7.625H9.84375C10.6641 7.625 11.375 8.33594 11.375 9.15625V11.3438C11.375 12.1914 10.6641 12.875 9.84375 12.875H7.65625C6.80859 12.875 6.125 12.1914 6.125 11.3438V9.15625C6.125 8.96484 6.15234 8.77344 6.23438 8.58203L4.15625 5.82031C4.01953 5.875 3.85547 5.875 3.71875 5.875H1.53125C0.683594 5.875 0 5.19141 0 4.34375V2.15625C0 1.33594 0.683594 0.625 1.53125 0.625H3.71875ZM3.71875 1.9375H1.53125C1.39453 1.9375 1.3125 2.04688 1.3125 2.15625V4.34375C1.3125 4.48047 1.39453 4.5625 1.53125 4.5625H3.71875C3.82812 4.5625 3.9375 4.48047 3.9375 4.34375V2.15625C3.9375 2.04688 3.82812 1.9375 3.71875 1.9375ZM9.84375 8.9375H7.65625C7.51953 8.9375 7.4375 9.04688 7.4375 9.15625V11.3438C7.4375 11.4805 7.51953 11.5625 7.65625 11.5625H9.84375C9.95312 11.5625 10.0625 11.4805 10.0625 11.3438V9.15625C10.0625 9.04688 9.95312 8.9375 9.84375 8.9375ZM11.8125 2.15625V4.34375C11.8125 4.48047 11.8945 4.5625 12.0312 4.5625H14.2188C14.3281 4.5625 14.4375 4.48047 14.4375 4.34375V2.15625C14.4375 2.04688 14.3281 1.9375 14.2188 1.9375H12.0312C11.8945 1.9375 11.8125 2.04688 11.8125 2.15625Z',
    ],
} as any as IconDefinition;

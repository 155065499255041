<template>
    <div class="SetRemindersFormItem">
        <div
            v-if="label != null"
            class="SetRemindersFormItem__label"
        >
            {{ label }}
        </div>
        <div class="SetRemindersFormItem__content">
            <slot />
        </div>
    </div>
</template>

<style lang="scss">
    .SetRemindersFormItem {
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: flex-end;

        &__label {
            margin-right: 15px;
            color: #2d3e50;
            font-size: 0.9em;
        }

        &__content {
            display: flex;
            flex-flow: column;
            align-items: flex-end;
        }

        .FormInput {
            max-width: 220px;
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';

    @Component
    export default class SetRemindersFormItem extends Vue {
        @Prop({ type: String, default: null })
        private label!: string | null;
    }
</script>

<template>
    <SettingsPage
        title="Branding Settings"
        :schema="schema"
        :initial-value="currentSettings"
        :loading="loading"
        :updating="updating"
        @save="save"
    >
        <template #preview="{ value }">
            <h3>Email Preview</h3>

            <ActionEmailPreview :value="value" :defaults="currentSettings" />

            <div class="SettingsPage__note">
                Note: The text displayed in the email above is for preview purposes only.
                The actual text used in your email is controlled on a per-template basis.
            </div>
        </template>

        <template #default="{ fields }">
            <h3>Branding Settings</h3>
            <h5 class="mt-0">
                Upload a logo and select your brand colors below to customize notifications and the Evident Network where your entities complete their requirements.
            </h5>

            <div class="SettingsPage__formRow">
                <JsonSchemaCustomFormElement :field="fields.displayName" />
            </div>

            <div class="SettingsPage__formRow">
                <JsonSchemaCustomFormElement :field="fields.wordmarkUrl" />
            </div>

            <div class="SettingsPage__formRow">
                <JsonSchemaCustomFormElement :field="fields.primaryColor" />
            </div>

            <div class="SettingsPage__formRow">
                <JsonSchemaCustomFormElement :field="fields.secondaryColor" />
            </div>
        </template>
    </SettingsPage>
</template>

<script lang="ts">
    import { Component, Vue, Watch } from '@evidentid/vue-property-decorator';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import { JsonSchemaCustomFormElement } from '@evidentid/dashboard-commons/components/JsonSchemaForm';
    import { buildBrandingSettingsSchema } from '../schemas/buildBrandingSettingsSchema';
    import ActionEmailPreview from '../components/ActionEmailPreview.vue';
    import SettingsPage from '../components/SettingsPage.vue';
    import { BrandingConfiguration, NotificationsConfiguration, NotificationsConfigurationState } from '../types';
    import { RelyingPartySignature } from '@evidentid/rpweb-api-client/models';
    import { UserState } from '@evidentid/dashboard-commons/modules/auth/types';

    @Component({
        components: {
            SettingsPage,
            ActionEmailPreview,
            JsonSchemaCustomFormElement,
        },
    })
    export default class BrandingSettings extends Vue {
        private schema = buildBrandingSettingsSchema();

        private get rpName(): string {
            return this.$route.params.rpId;
        }

        private get currentRp(): RelyingPartySignature | null {
            return (this.$store.state.user as UserState)
                .relyingParties?.find(({ name }) => (name === this.rpName)) || null;
        }

        private get externalConfigurationEnabled(): boolean {
            return Boolean(this.currentRp?.externalConfigurationEnabled);
        }

        private get state(): NotificationsConfigurationState {
            return this.$store.state.notificationsConfiguration;
        }

        private get status(): OperationStatus {
            return this.state.loadSettings[this.rpName] || OperationStatus.uninitialized;
        }

        private get loading(): boolean {
            return ![ OperationStatus.success, OperationStatus.error ].includes(this.status);
        }

        private get updateStatus(): OperationStatus {
            return this.state.updateSettings[this.rpName] || OperationStatus.uninitialized;
        }

        private get updating(): boolean {
            return this.updateStatus === OperationStatus.loading;
        }

        private get currentSettings(): NotificationsConfiguration | null {
            return this.state.settings[this.rpName] || null;
        }

        private async save(settings: BrandingConfiguration): Promise<void> {
            await this.$store.actions.notificationsConfiguration.updateSettings({
                rpName: this.rpName,
                settings,
            });

            if (this.updateStatus === OperationStatus.success) {
                this.$store.actions.snackbar.displaySnackbar({
                    message: 'Branding settings updated successfully.',
                    success: true,
                });
            }
        }

        @Watch('status', { immediate: true })
        private loadSettings(): void {
            if (this.externalConfigurationEnabled && this.status === OperationStatus.uninitialized) {
                this.$store.actions.notificationsConfiguration.loadSettings({ rpName: this.rpName });
            }
        }

        @Watch('externalConfigurationEnabled', { immediate: true })
        private onExternalConfigurationStatus(): void {
            if (!this.externalConfigurationEnabled && this.$store.state.user?.data) {
                this.$router.replace({ name: 'error', params: { reason: 'page-not-found' } });
            }
        }
    }
</script>

<template>
    <div class="JsonSchemaNumberView">
        <div class="JsonSchemaNumberView__title">
            {{ form.schema.title }}
        </div>
        <div class="JsonSchemaNumberView__valueWithError">
            <div class="JsonSchemaNumberView__value" :class="displayedValueAdditionalClass">
                {{ formattedValue }}
            </div>
            <EvaluationError
                v-if="evaluationError && !evaluationError.isMultiFieldCriterion"
                :evaluation-error="evaluationError"
                v-on="$listeners"
            />
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Watch } from '@evidentid/vue-property-decorator';
    import { JsonFormBase } from '@evidentid/json-schema/interfaces/JsonForm';
    import { JsonSchemaNumber, JsonSchemaString } from '@evidentid/json-schema/interfaces/JsonSchema';
    import EvaluationError from '@/modules/entity-details/components/EvaluationError/EvaluationError.vue';
    import AbstractJsonSchemaView
        from '@/modules/entity-details/components/JsonSchemaView/elements/AbstractJsonSchemaView';

    type JsonFormNumber = JsonFormBase<JsonSchemaNumber>;
    type JsonFormString = JsonFormBase<JsonSchemaString>;
    @Component({
        components: { EvaluationError },
        inheritAttrs: false,
    })
    export default class JsonSchemaNumberView
        extends AbstractJsonSchemaView<(JsonFormString | JsonFormNumber), (string | number)> {
        private currencyFormatter = Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
        });

        @Watch('currency', { immediate: true })
        private onCurrencyChange(): void {
            this.currencyFormatter = Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: this.currency,
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            });
        }

        private get formattedValue(): string {
            const shouldBeDisplayedAsCurrency = this.form.schema.title?.toLowerCase().endsWith('limit')
                || this.form.schema.title?.toLowerCase().endsWith('deductible') || false;
            return typeof this.value === 'number' && shouldBeDisplayedAsCurrency
                ? this.currencyFormatter.format(this.value)
                : this.value.toString();
        }
    }
</script>

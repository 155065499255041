<template>
    <ErrorScreen :warning="warning" :has-rp-panel="rpOnly" has-user-panel>
        <h3>{{ title }}</h3>
        <p v-if="html" v-html="message" />
        <p v-else>
            {{ message }}
        </p>

        <Button
            v-if="allowRefresh"
            :icon="faSyncAlt"
            type="primary"
            @click.prevent="$emit('refresh')"
        >
            Refresh page
        </Button>
    </ErrorScreen>
</template>

<style lang="scss">
    .Error__homeButton {
        text-decoration: none;
        border-radius: 1.25em;
        padding: 0.5em 1.5em;
        background: #2ab496;
        color: #fff;
    }
</style>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import ErrorScreen from '@/components/error-screen/ErrorScreen.vue';

    @Component({
        components: { ErrorScreen, Button },
    })
    export default class ErrorPage extends Vue {
        private faSyncAlt = faSyncAlt;

        @Prop({ type: Boolean, default: false })
        private allowRefresh!: boolean;

        @Prop({ type: Boolean, default: false })
        private warning!: boolean;

        @Prop({ type: Boolean, default: false })
        private html!: boolean;

        @Prop({ type: Boolean, default: false })
        private rpOnly!: boolean;

        @Prop({ type: String })
        private title!: string;

        @Prop({ type: String })
        private message!: string;
    }
</script>

import { createModuleBuilder } from '@evidentid/dashboard-commons/app';
import RpWebApiClient from '@evidentid/rpweb-api-client';
import vuex from './vuex';
import ApiSettings from '@/modules/api-settings/views/ApiSettings.vue';
import Tracker from '@evidentid/tracker';
import Logger from '@evidentid/logger';
import {
    addCommonFailTracker,
    addCommonFinishTracker,
    addCommonStartTracker,
} from '@/utils/vuex-common/addCommonRequestTracker';
import ConfigApiClient from '@evidentid/config-api-client';
import { observeTrackAndLog } from '@/utils/vuex-common/observeTrackAndLog';

const legacyRoutesRedirect = [
    {
        path: '/:rpId?/admin/api-settings',
        redirect: '/:rpId?/configuration/api-settings',
    },
];

function trackAndLog(app: any): void {
    const module = 'Api Settings';
    observeTrackAndLog(app,
        { start: 'startLoadingApiKey', finish: 'finishLoadingApiKey', fail: 'failLoadingApiKey' },
        { module, title: 'Api Key', action: 'Load' },
    );
    observeTrackAndLog(app,
        { start: 'startLoadingWebhooks', finish: 'finishLoadingWebhooks', fail: 'failLoadingWebhooks' },
        { module, title: 'Webhooks', action: 'Load' },
    );
    observeTrackAndLog(app,
        { start: 'startLoadingWebhookTypes', finish: 'finishLoadingWebhookTypes', fail: 'failLoadingWebhookTypes' },
        { module, title: 'WebhookTypes', action: 'Load' }
    );
    observeTrackAndLog(app,
        { start: 'startCreatingWebhook', finish: 'finishCreatingWebhook', fail: 'failCreatingWebhook' },
        { module, title: 'Webhook', action: 'Create' },
    );
    observeTrackAndLog(app,
        { start: 'startPatchingWebhook', finish: 'finishPatchingWebhook', fail: 'failPatchingWebhook' },
        { module, title: 'Webhook', action: 'Patch' },
    );
    observeTrackAndLog(app,
        { start: 'startDeletingWebhook', finish: 'finishDeletingWebhook', fail: 'failDeletingWebhook' },
        { module, title: 'Webhook', action: 'Delete' },
    );
}

export const apiSettingsModule = createModuleBuilder()
    .registerRoutes(() => [
        {
            path: '/:rpId?/configuration/api-settings',
            name: 'apiSettings',
            component: ApiSettings as any,
            meta: { title: 'API Settings' },
        },
        ...legacyRoutesRedirect,
    ])
    .demandContext<{
        rpweb: RpWebApiClient;
        configClient: ConfigApiClient;
        tracker?: Tracker<any>;
        logger?: Logger<any>;
    }>()
    .registerVuex({ apiSettings: vuex.instantiateModule })
    .execute((app) => {
        trackAndLog(app);
    })
    .end();

<template>
    <div class="CriterionTextInput">
        <FormElement :label="hideTitle ? null : schema.title">
            <template v-if="schema.description" #labelHint>
                <div
                    v-tooltip="schema.description"
                    class="CriterionEnumInput__hintIcon"
                >
                    <FontAwesomeIcon :icon="faQuestionCircle" />
                </div>
            </template>
            <FormInput :invalid="(showError || accessed) && hasError" force-error-slot>
                <input
                    type="text"
                    :value="value"
                    :maxlength="schema.maxLength || 1024"
                    @input="onInput"
                    @blur="touch"
                    @focus="onFocus"
                    @change="onChange"
                >
                <template v-if="(showError || accessed) && hasError" #errorMessage>
                    {{ errorMessage }}
                </template>
                <template v-if="deletable" #actionButtons>
                    <div class="JsonSchemaForm__deleteIcon" @click="$emit('delete')">
                        <EidIcon
                            alt="delete"
                            :icon-src="tranIcon"
                            :svg-props="{color: alertRedColor}"
                        />
                    </div>
                </template>
            </FormInput>
        </FormElement>
    </div>
</template>

<script lang="ts">
    import { Component } from '@evidentid/vue-property-decorator';
    import tranIcon from '@/assets/icons/bin-icon.svg';
    import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { FormElement, FormInput } from '@evidentid/dashboard-commons/components/Form';
    import AbstractCriterionInput from './AbstractCriterionInput';
    import { JsonSchemaString } from '@evidentid/json-schema/interfaces/JsonSchema';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import { tailwindColors } from '@/styles/variables/tailwind-colors/tailwindColors';

    @Component({
        components: { EidIcon, FormInput, FontAwesomeIcon, FormElement },
    })
    export default class CriterionTextInput extends AbstractCriterionInput<JsonSchemaString, string> {
        private tranIcon = tranIcon;
        private faQuestionCircle = faQuestionCircle;
        private alertRedColor = tailwindColors.eidDangerRed.DEFAULT;

        private onInput(event: InputEvent) {
            const inputValue = (event.target as HTMLInputElement).value;
            this.$emit('input', inputValue);
        }

        private onChange(event: Event): void {
            const inputValue = (event.target as HTMLInputElement).value;
            this.$emit('change', inputValue);
        }

        private beforeMount() {
            this.setSchemaVisible?.(this.schemaPath, true);
        }

        private beforeDelete() {
            this.setSchemaVisible?.(this.schemaPath, false);
        }
    }
</script>

<template>
    <div class="PopoverStandardFilters">
        <div class="PopoverStandardFilters__checkboxBlock">
            <div class="PopoverStandardFilters__paused">
                <Checkbox :value="paused" @input="onPausedChange">
                    Paused
                </Checkbox>
            </div>
            <div class="PopoverStandardFilters__showDeactivated">
                <Checkbox :value="showDeactivated" @input="onShowDeactivatedChange">
                    Show deactivated
                </Checkbox>
            </div>
        </div>
        <FormElement class="PopoverStandardFilters__containWords" label="Contains">
            <FormInput>
                <input v-model="localFilters.search" type="text" placeholder="Type">
            </FormInput>
        </FormElement>

        <FormElement class="PopoverStandardFilters__displayName" label="Display Name">
            <FormInput>
                <input v-model="localFilters.displayName" type="text" placeholder="Type Display Name">
            </FormInput>
        </FormElement>

        <FormElement class="PopoverStandardFilters__legalName" label="Legal Name">
            <FormInput>
                <input v-model="localFilters.legalName" type="text" placeholder="Type Legal Name">
            </FormInput>
        </FormElement>

        <FormElement class="PopoverStandardFilters__doingBusinessAs" label="DBA Name(s)">
            <FormInput>
                <input v-model="localFilters.doingBusinessAs" type="text" placeholder="Type DBA Name">
            </FormInput>
        </FormElement>

        <FormElement class="PopoverStandardFilters__contactName" label="Primary Contact Name">
            <FormInput>
                <input v-model="localFilters.contactName" type="text" placeholder="Type Primary Contact Name">
            </FormInput>
        </FormElement>

        <FormElement class="PopoverStandardFilters__contactEmail" label="Primary Contact Email">
            <FormInput>
                <input v-model="localFilters.contactEmail" type="text" placeholder="Type Primary Contact Email">
            </FormInput>
        </FormElement>

        <FormElement class="PopoverStandardFilters__contactPhoneNumber" label="Contact Phone Number">
            <FormInput>
                <input
                    v-model="localFilters.contactPhoneNumber"
                    type="text"
                    placeholder="Type Contact Number"
                    @paste="onPastePhoneNumber"
                    @keydown="numberKeysOnly"
                >
            </FormInput>
        </FormElement>

        <FormElement
            ref="complianceStatusFormElement"
            class="PopoverStandardFilters__complianceStatus"
            label="Compliance Status"
        >
            <Dropdown
                multi-select
                :options="complianceStatusOptions"
                :selected="selectedComplianceStatusOptions"
                @input="onComplianceStatusChanged"
                @open="scrollToElement('complianceStatusFormElement')"
            />
        </FormElement>

        <FormElement
            ref="verificationStatusFormElement"
            class="PopoverStandardFilters__verificationStatus"
            label="Verification Status"
        >
            <Dropdown
                multi-select
                :options="verificationStatusOptions"
                :selected="selectedVerificationStatusOptions"
                @input="onVerificationStatusChanged"
                @open="scrollToElement('verificationStatusFormElement')"
            />
        </FormElement>

        <FormElement class="PopoverStandardFilters__expiration" label="Expiration Date">
            <FormInput class="PopoverStandardFilters__expiresAfter">
                <DatePicker
                    :value="localFilters.expiresAfterOrOn"
                    placeholder="From"
                    enable-clear
                    @input="onAfterDateChange"
                />
            </FormInput>
            <div class="PopoverStandardFilters__expirationSeparator" />
            <FormInput class="PopoverStandardFilters__expiresBefore">
                <DatePicker
                    :value="localFilters.expiresBeforeOrOn"
                    :min-date="localFilters.expiresAfterOrOn"
                    placeholder="To"
                    enable-clear
                    @input="onBeforeDateChange"
                />
            </FormInput>
        </FormElement>

        <FormElement
            ref="effectiveRiskProfileFormElement"
            class="PopoverStandardFilters__effectiveRiskProfile"
            label="Effective Risk Profile"
        >
            <Dropdown
                multi-select
                :options="effectiveRiskProfileOptions"
                :selected="selectedEffectiveRiskProfiles"
                @input="onEffectiveRiskProfilesChanged"
                @open="scrollToElement('effectiveRiskProfileFormElement')"
            />
        </FormElement>

        <FormElement
            ref="riskProfileFormElement"
            class="PopoverStandardFilters__riskProfile"
            label="Risk Profiles"
        >
            <RiskProfileFilter
                class="PopoverStandardFilters__riskProfile"
                :options="riskProfileOptions"
                :selected="selectedRiskProfiles"
                :filter-type="riskProfileFilterType"
                @clear="onRiskProfileFilterClear"
                @input="clearCoverageCriteriaFilterTypeIfNeeded"
                @select="onRiskProfilesChange"
                @changeFilterType="onRiskProfileFilterTypeChange"
                @open="scrollToElement('riskProfileFormElement')"
            />
        </FormElement>
    </div>
</template>

<script lang="ts">
    import moment from 'moment';
    import pickBy from 'lodash/pickBy';
    import isEqual from 'lodash/isEqual';
    import omit from 'lodash/omit';
    import intersection from 'lodash/intersection';
    import sortBy from 'lodash/sortBy';
    import { Component, Vue, Prop, Watch } from '@evidentid/vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import Dropdown from '@evidentid/dashboard-commons/components/Dropdown/Dropdown.vue';
    import { FormElement, FormInput } from '@evidentid/dashboard-commons/components/Form';
    import { FilterType, EntityFilters } from '@/modules/entity-filtering/types';
    import { DropdownOption } from '@evidentid/dashboard-commons/components/Dropdown/types';
    import {
        ComplianceStatus,
        ComplianceStatusLabel, VerificationStatus,
        VerificationStatusLabel,
    } from '@evidentid/tprm-portal-lib/models/entity-details';
    import { DatePicker } from '@evidentid/dashboard-commons/components/DatePicker';
    import { Checkbox } from '@evidentid/dashboard-commons/components/Checkbox';
    import RiskProfileFilter
        from '@/modules/entity-filtering/components/RiskProfileFilter/RiskProfileFilter.vue';
    import {
        RiskProfile,
    } from '@evidentid/tprm-portal-lib/models/decisioning/RiskProfile.model';
    import {
        EffectiveRiskProfiles,
    } from '@evidentid/tprm-portal-lib/models/decisioning/EffectiveRiskProfiles.model';

    @Component({
        components: {
            FontAwesomeIcon, Button, DatePicker, Dropdown, FormElement, FormInput, Checkbox,
            RiskProfileFilter,
        },
    })
    export default class PopoverStandardFilters extends Vue {
        @Prop({ type: Object, default: () => ({}) })
        private filters!: EntityFilters;

        @Prop({ type: Array, default: () => [] })
        private riskProfiles!: RiskProfile[];

        @Prop({ type: Array, default: () => [] })
        private effectiveRiskProfiles!: EffectiveRiskProfiles[];

        private riskProfilesMap: Record<string, RiskProfile> = {};
        private localFilters: EntityFilters = { ...this.filters };
        private complianceStatusLabelMap: Record<ComplianceStatus, ComplianceStatusLabel> =
            Object.entries(ComplianceStatus).reduce((acc, [ key, value ]) => ({
                ...acc,
                [value]: (ComplianceStatusLabel as Record<string, ComplianceStatusLabel>)[key],
            }), {} as Record<ComplianceStatus, ComplianceStatusLabel>);
        private verificationStatusLabelMap: Record<VerificationStatus, VerificationStatusLabel> =
            Object.entries(VerificationStatus).reduce((acc, [ key, value ]) => ({
                ...acc,
                [value]: (VerificationStatusLabel as Record<string, VerificationStatusLabel>)[key],
            }), {} as Record<VerificationStatus, VerificationStatusLabel>);

        private complianceStatusOptions: DropdownOption[] = [];
        private verificationStatusOptions: DropdownOption[] = [];
        private readonly effectiveRiskProfilesLabelDelimiter = ' + ';
        private readonly effectiveRiskProfilesQueryDelimiter = '+';

        private get selectedComplianceStatusOptions(): DropdownOption[] {
            const statusStrings = this.localFilters.complianceStatus?.split(',') || [];
            const filteredStatus =
                intersection(statusStrings, Object.values(ComplianceStatus)) as ComplianceStatus[];
            return this.localFilters.complianceStatus
                ? this.getComplianceStatusDropdownOptions(filteredStatus)
                : [];
        }

        private get selectedVerificationStatusOptions(): DropdownOption[] {
            const statusStrings = this.localFilters.verificationStatus?.split(',') || [];
            const filteredStatus = intersection(statusStrings, Object.values(VerificationStatus)) as
                VerificationStatus[];
            return this.localFilters.verificationStatus
                ? this.getVerificationStatusDropdownOptions(filteredStatus)
                : [];
        }

        private get showDeactivated() {
            return this.filters.showDeactivated === 'true';
        }

        private get paused() {
            return this.filters.paused === 'true';
        }

        private get riskProfileOptions(): DropdownOption[] {
            return this.riskProfiles
                .map((riskProfile) => ({ label: riskProfile.displayName, value: riskProfile.id }))
                .sort((a, b) => a.label.localeCompare(b.label));
        }

        private get selectedRiskProfiles(): DropdownOption[] {
            const riskProfileIds = this.localFilters.coverageCriteriaGroupId?.split(',') || [];
            return riskProfileIds.reduce((options, id) => {
                const riskProfile = this.riskProfilesMap[id];
                if (riskProfile) {
                    return [
                        ...options,
                        { label: riskProfile.displayName, value: riskProfile.id },
                    ];
                }
                return options;
            }, [] as DropdownOption[]);
        }

        private get effectiveRiskProfileOptions(): DropdownOption[] {
            const options = this.effectiveRiskProfiles.map((riskProfiles) => {
                const riskProfileNames =
                    riskProfiles.map((riskProfileId) => this.riskProfilesMap[riskProfileId].displayName || '');
                const sortedRiskProfileNames =
                    sortBy(
                        riskProfileNames,
                        (name) => name.toLowerCase()).join(this.effectiveRiskProfilesLabelDelimiter,
                        );
                return { label: sortedRiskProfileNames, value: riskProfiles };
            });
            return sortBy(options, (option) => option.label.toLowerCase());
        }

        private get selectedEffectiveRiskProfiles(): DropdownOption[] {
            const effectiveRiskProfiles = this.localFilters.effectiveGroup?.split(',') || [];
            return effectiveRiskProfiles.map((ids) => {
                const effectiveRiskProfile = ids.split(this.effectiveRiskProfilesQueryDelimiter);
                return {
                    label: this.buildEffectiveRiskProfileLabel(effectiveRiskProfile),
                    value: effectiveRiskProfile,
                };
            });
        }

        private get riskProfileFilterType(): FilterType {
            return this.filters.coverageCriteriaGroupFilterType || 'OR';
        }

        @Watch('filters', { immediate: true })
        private onFiltersChanged() {
            if (!isEqual(this.filters, this.localFilters)) {
                this.localFilters = { ...this.filters };
            }
        }

        @Watch('localFilters', { deep: true })
        private onLocalFiltersChanged() {
            if (!isEqual(this.filters, this.localFilters)) {
                this.$emit('input', { ...this.localFilters });
            }
        }

        @Watch('riskProfiles', { immediate: true })
        private onRiskProfilesChanged() {
            this.riskProfilesMap = this.riskProfiles.reduce((map, riskProfile) => ({
                ...map,
                [riskProfile.id]: riskProfile,
            }), {} as Record<string, RiskProfile>);
        }

        private mounted() {
            this.complianceStatusOptions =
                this.getComplianceStatusDropdownOptions(Object.values(ComplianceStatus))
                    .sort((a, b) => a.label.localeCompare(b.label));
            this.verificationStatusOptions =
                this.getVerificationStatusDropdownOptions(Object.values(VerificationStatus)
                    .filter((status: string) => status !== VerificationStatus.complete))
                    .sort((a, b) => a.label.localeCompare(b.label));
        }

        private getComplianceStatusDropdownOptions(statusList: ComplianceStatus[]): DropdownOption[] {
            return statusList.map((status) => ({ label: this.complianceStatusLabelMap[status], value: status }));
        }

        private getVerificationStatusDropdownOptions(statusList: VerificationStatus[]): DropdownOption[] {
            return statusList.map((status) => ({ label: this.verificationStatusLabelMap[status], value: status }));
        }

        private onComplianceStatusChanged(options: DropdownOption[]): void {
            const complianceStatus = options.map((option) => option.value).join(',');
            this.localFilters = pickBy({ ...this.localFilters, complianceStatus }, Boolean);
        }

        private onVerificationStatusChanged(options: DropdownOption[]): void {
            const verificationStatus = options.map((option) => option.value).join(',');
            this.localFilters = pickBy({ ...this.localFilters, verificationStatus }, Boolean);
        }

        private onBeforeDateChange(date: string): void {
            this.localFilters = { ...this.localFilters, ...(date && { expiresBeforeOrOn: date }) };
        }

        private onAfterDateChange(date: string): void {
            if (moment(date).isAfter(moment(this.localFilters.expiresBeforeOrOn))) {
                this.localFilters = omit({ ...this.localFilters, ...(date && { expiresAfterOrOn: date }) }, 'expiresBeforeOrOn');
            } else {
                this.localFilters = { ...this.localFilters, ...(date && { expiresAfterOrOn: date }) };
            }
        }

        private numberKeysOnly(inputEvent: KeyboardEvent): void {
            const comboKey = inputEvent.metaKey || inputEvent.ctrlKey || inputEvent.altKey;
            if (!comboKey && inputEvent.key?.length === 1 && !/^[\d]$/.test(inputEvent.key)) {
                inputEvent.preventDefault();
            }
        }

        private onPastePhoneNumber(inputEvent: ClipboardEvent): void {
            inputEvent.preventDefault();
            if (inputEvent.clipboardData) {
                const filtered = inputEvent.clipboardData.getData('text/plain').replace(/[^\d]/g, '');
                (inputEvent.target as HTMLInputElement).value = filtered;
                this.localFilters.contactPhoneNumber = filtered;
            }
        }

        private onShowDeactivatedChange(): void {
            this.localFilters =
                { ...this.localFilters, showDeactivated: this.localFilters.showDeactivated ? undefined : 'true' };
        }

        private onPausedChange(): void {
            this.localFilters =
                { ...this.localFilters, paused: this.localFilters.paused ? undefined : 'true' };
        }

        private onRiskProfilesChange(options: DropdownOption[]): void {
            const coverageCriteriaGroupId = options.map((option) => option.value).join(',');
            this.localFilters = pickBy({ ...this.localFilters, coverageCriteriaGroupId }, Boolean);
            if (!this.localFilters.coverageCriteriaGroupFilterType) {
                this.localFilters = pickBy({ ...this.localFilters, coverageCriteriaGroupFilterType: 'OR' }, Boolean);
            }
        }

        private onEffectiveRiskProfilesChanged(options: DropdownOption[]): void {
            const effectiveGroup = options.map((option) => option.value.join('+')).join(',');
            this.localFilters = pickBy({ ...this.localFilters, effectiveGroup }, Boolean);
        }

        private onRiskProfileFilterTypeChange(coverageCriteriaGroupFilterType: FilterType): void {
            this.localFilters = pickBy({ ...this.localFilters, coverageCriteriaGroupFilterType }, Boolean);
        }

        private onRiskProfileFilterClear(): void {
            this.localFilters = omit(this.localFilters, [ 'coverageCriteriaGroupId', 'coverageCriteriaGroupFilterType' ]);
        }

        private clearCoverageCriteriaFilterTypeIfNeeded(): void {
            if (!this.localFilters.coverageCriteriaGroupId) {
                this.localFilters = omit(this.localFilters, [ 'coverageCriteriaGroupFilterType' ]);
            }
        }

        private scrollToElement(refName: string): void {
            this.$nextTick(() => {
                const ref = (this.$refs[refName] as FormElement);
                if (ref) {
                    this.$emit('scrollTo', (ref.$el as HTMLElement).offsetTop);
                }
            });
        }

        private buildEffectiveRiskProfileLabel(effectiveRiskProfile: EffectiveRiskProfiles): string {
            return effectiveRiskProfile.map((id) => this.riskProfilesMap[id]?.displayName || '')
                .sort((a, b) => a.localeCompare(b)).join(this.effectiveRiskProfilesLabelDelimiter);
        }
    }
</script>

import { createModuleBuilder } from '@evidentid/dashboard-commons/app';
import RpWebApiClient from '@evidentid/rpweb-api-client';
import Tracker from '@evidentid/tracker';
import Logger from '@evidentid/logger';
import vuex from '@/modules/decisioning-criteria/vuex';
import Severity from '@evidentid/logger/Severity';
import DecisioningCriteria from '@/modules/decisioning-criteria/views/DecisioningCriteria.vue';
import RiskProfileView from '@/modules/decisioning-criteria/views/RiskProfileView.vue';
import { observeTrackAndLog } from '@/utils/vuex-common/observeTrackAndLog';
import { camelCase, startCase, subtract, upperFirst } from 'lodash';
import { observeTrackAndLogPerModule, TrackAndLogConfig } from '@/utils/vuex-common/observeTrackAndLogPerModule';

const legacyRoutesRedirect = [
    {
        path: '/:rpId?/decisioningCriteria',
        redirect: '/:rpId?/decisioning',
    }, {
        path: '/:rpId?/decisioningCriteria/group/',
        redirect: '/:rpId?/decisioning/riskProfile/',
    }, {
        path: '/:rpId?/decisioningCriteria/group/:id',
        redirect: '/:rpId?/decisioning/riskProfile/:id',
    },
];

function trackAndLog(app: any): void {
    const config: TrackAndLogConfig = {
        module: 'Decisioning',
        entries: [
            { subjectName: 'Criteria', action: 'Load' },
            { subjectName: 'Criteria', action: 'Patch' },
            { subjectName: 'CriterionMessage', action: 'Load' },
            { subjectName: 'CriterionTemplates', action: 'Load' },
            { subjectName: 'CurrentRiskProfile', action: 'Load' },
            { subjectName: 'RequirementModels', action: 'Load' },
            { subjectName: 'RequirementTypesPerCountry', action: 'Load' },
            { subjectName: 'RiskProfile', action: 'Create' },
            { subjectName: 'RiskProfile', action: 'Delete' },
            { subjectName: 'RiskProfile', action: 'Update' },
            { subjectName: 'RiskProfiles', action: 'Load' },
        ],
    };
    observeTrackAndLogPerModule(app, config);
}

export const decisioningCriteriaModule = createModuleBuilder()
    .registerRoutes(() => [
        {
            path: '/:rpId?/decisioning',
            name: 'decisioning',
            component: DecisioningCriteria as any,
            meta: { title: 'Decisioning' },
        },
        {
            path: '/:rpId?/decisioning/riskProfile/',
            name: 'createDecisioningRiskProfile',
            component: RiskProfileView as any,
            meta: { title: 'Decisioning' },
        },
        {
            path: '/:rpId?/decisioning/riskProfile/:id',
            name: 'decisioningRiskProfile',
            component: RiskProfileView as any,
            meta: { title: 'Decisioning' },
        },
        ...legacyRoutesRedirect,
    ])
    .demandContext<{
        rpweb: RpWebApiClient;
        tracker?: Tracker<any>;
        logger?: Logger<any>;
    }>()
    .registerVuex({ decisioningCriteria: vuex.instantiateModule })
    .execute((app) => {
        trackAndLog(app);
    })
    .end();

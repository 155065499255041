import { GatewayApiClient } from '@evidentid/gateway-api-client';
import { createModuleBuilder } from '@evidentid/dashboard-commons/app';

export function createGatewayApiClientModule() {
    return createModuleBuilder()
        .demandOptions<{ gatewayApiUrl: string }>()
        .demandContext<{ auth: { getTokens(): Promise<{ accessToken: string | null, idToken: string | null }> } }>()
        .inject(async (app) => {
            const gatewayClient = new GatewayApiClient(app.options.gatewayApiUrl);
            gatewayClient.setTokens(() => app.auth.getTokens());
            return { gatewayClient };
        })
        .end();
}

export type GatewayApiClientModule = ReturnType<typeof createGatewayApiClientModule>;

<template>
    <div class="EndorsementReview">
        <MatchingSelectionReviewBase
            provided-subject-title="Form Number"
            matching-subject-title="Category"
            no-matches-text="Additional Insured Endorsement, Category not listed"
            :provided="actionDetails.formNumber"
            :action-review="actionReview"
            :matching-list="endorsementCategories"
            @resolve="onResolve"
        />
    </div>
</template>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import {
        ActionReview,
        ActionType, EndorsementFormNumberActionDetails,
        EndorsementFormNumberActionResolveInput,
    } from '@evidentid/tprm-portal-lib/models/entity-actions-review';
    import { typeNarrowing } from '@evidentid/dashboard-commons/utils/typeNarrowing';
    import { WRONG_ACTION_DETAILS_TYPE_MESSAGE } from '@/modules/entity-actions-review/constants';
    import MatchingSelectionReviewBase
        from '@/modules/entity-actions-review/components/MatchingSelectionReviewBase/MatchingSelectionReviewBase.vue';

    @Component({
        components: { MatchingSelectionReviewBase },
    })
    export default class EndorsementReview extends Vue {
        @Prop({ type: Object, default: () => ({}) })
        private actionReview!: ActionReview;

        @Prop({ type: Array, default: () => [] })
        private endorsementCategories!: string[];

        private get actionDetails(): EndorsementFormNumberActionDetails {
            return typeNarrowing(
                this.actionReview.action,
                this.actionReview.action.$action === ActionType.endorsementFormNumberV1,
                WRONG_ACTION_DETAILS_TYPE_MESSAGE,
            );
        }

        private onResolve(val: string|null): void {
            const data: EndorsementFormNumberActionResolveInput = {
                $action: ActionType.endorsementFormNumberV1,
                category: val,
            };
            this.$emit('resolve', data);
        }
    }
</script>

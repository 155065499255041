import JsonSchema from '../common/JsonSchema.model';
import { EntityAddress } from './EntityAddress.model';

export enum CustomPropertyType {
    integer = 'INTEGER',
    number = 'NUMBER',
    boolean = 'BOOLEAN',
    collaterals = 'COLLATERALS',
    string = 'STRING',
    listString = 'LIST_STRING',
    listEmail = 'LIST_EMAIL',
    address = 'ADDRESS',
    credentialTypes = 'CREDENTIAL_TYPES',
}

export interface CustomProperty {
    id: string;
    key: string;
    name: string;
    required: boolean;
    description: string | null;
    schema: JsonSchema;
    createdAt: string;
    type: CustomPropertyType;
}

export interface CustomPropertyCollateral {
    description: string;
    category: string;
    limitRequired: number;
    uniqueIdentifier?: string;
    maximumDeductible?: number;
    id?: string;
}

export type CustomPropertyValue =
    string | number | boolean | object | undefined | string[] | CustomPropertyCollateral[] | EntityAddress;

export type CustomPropertyValues = Record<string, CustomPropertyValue>;

<template>
    <RadioOption
        class="FieldRadioOption"
        :disabled="!schema.enum.includes(value) || field.disabled"
        :name="field.key"
        :value="value"
        :checked="field.value === value"
        @click="onClick"
    >
        <span class="FieldRadioOption__description">
            {{ displayedLabel }}
        </span>
        <InfoCircle v-if="tooltip" :message="tooltip" />
    </RadioOption>
</template>

<style lang="scss">
    .FieldRadioOption {
        &__description {
            display: inline-block;
            min-width: 90px;
        }

        .RadioOption--disabled {
            pointer-events: none;
            opacity: 0.5;
        }

        .InfoCircle {
            font-size: 0.75em;
        }

        & + & {
            margin-top: 10px;
        }
    }
</style>

<script lang="ts">
    import { Component, Prop, Vue } from '@evidentid/vue-property-decorator';
    import { Pointer } from '@evidentid/json-schema/FormController';
    import { JsonSchemaEnum } from '@evidentid/json-schema/interfaces/JsonSchema';
    import { RadioOption } from '@evidentid/dashboard-commons/components/RadioOption';
    import InfoCircle from './InfoCircle.vue';

    @Component({
        components: {
            InfoCircle,
            RadioOption,
        },
    })
    export default class FieldRadioOption extends Vue {
        @Prop({ type: String })
        private value!: string;

        @Prop({ type: Object })
        private field!: Pointer;

        @Prop({ type: String, default: '' })
        private tooltip!: string;

        @Prop({ type: String, default: null })
        private label!: string | null;

        private get schema(): JsonSchemaEnum {
            return this.field.form.schema as JsonSchemaEnum;
        }

        private get defaultLabel(): string {
            return this.schema.enumLabels?.[this.schema.enum.indexOf(this.value)] || this.value;
        }

        private get displayedLabel(): string {
            return this.label ?? this.defaultLabel;
        }

        private onClick(): void {
            this.field.value = this.value;
            this.field.touch();
        }
    }
</script>

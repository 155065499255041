<template>
    <!-- @FIXME: a div container here is to apply grid display as a workaround for MainLayout to have a width based on
      browser size, remove this workaround when we have a proper fix for InsuredTable overflow issue -->
    <div class="Page__container">
        <MainLayout
            collapsible-sidebar
            :sidebar-closed="!menuExpanded"
            :form="form"
            :loading="loading"
            :non-destructive-on-loading="nonDestructiveOnLoading"
            show-menu-icon
            v-on="$listeners"
            @menuClick="onMenuIconClicked"
            @sidebarMouseover="onHover"
            @sidebarMouseleave="onUnhover"
        >
            <template #logo>
                <img alt="Evident ID" :src="logoUrl">
            </template>

            <template #title>
                <slot name="title">
                    <h1>
                        {{ title }}
                    </h1>
                </slot>
            </template>

            <template v-if="$scopedSlots.toolbar" #toolbar>
                <slot name="toolbar" />
            </template>

            <template v-if="$scopedSlots.toolbarEnd" #toolbarEnd>
                <slot name="toolbarEnd" />
            </template>

            <template #navigation>
                <RelyingPartySelection
                    v-if="rps.length > 1"
                    :options="rps"
                    :value="currentRp"
                    @input="changeRp"
                />
                <UserBox>
                    <MenuLink label="Customer Support" @click="openSite('support')" />
                    <MenuLink label="Help Articles" @click="openSite('help')" />
                </UserBox>
            </template>

            <template #sidebar>
                <SidebarMenu />
                <img
                    v-if="menuExpanded && shouldDisplayPoweredBy"
                    class="PoweredByEvidentLogo"
                    alt="Powered by Evident"
                    :src="powerByEvidentLogo"
                >
            </template>
            <slot />
        </MainLayout>
        <OnlineChat v-if="isChatAvailable" class="hidden md:block" />
    </div>
</template>

<style lang="scss">
    .Page {
        &__container {
            display: grid;
        }

        .MainLayout--closed {
            // override min width of mainLayout closed state due to Insurance facing using big icon
            min-width: 75px;
        }
    }

    .PoweredByEvidentLogo {
        position: fixed;
        bottom: 10px;
        left: 35px;
    }
</style>

<script lang="ts">
    import {
        PartnersSettingsService,
    } from '@evidentid/iam-client/services/partners-settings-service/partnersSettingsService';
    import { Component, Vue, Prop, Watch } from '@evidentid/vue-property-decorator';
    import { RawLocation } from 'vue-router';
    import noop from 'lodash/noop';
    import logoUrl from '@evidentid/assets-bridge/evident-wordmark.svg';
    import UserBox from '@evidentid/dashboard-commons/modules/auth/components/UserBox.vue';
    import { MainLayout } from '@evidentid/dashboard-commons/layouts/MainLayout';
    import SidebarMenu from '@/components/sidebar-menu/SidebarMenu.vue';
    import RelyingPartySelection from '@/components/replying-party-selection/RelyingPartySelection.vue';
    import { UserPreference } from '@/modules/local-storage/vuex';
    import { PendingScreen } from '@evidentid/dashboard-commons/screens/PendingScreen';
    import { MenuLink } from '@evidentid/dashboard-commons/components/Menu';
    import OnlineChat from '@evidentid/dashboard-commons/modules/online-chat/components/OnlineChat/OnlineChat.vue';
    import powerByEvidentLogo from '@/assets/images/powered_by_evident.svg';

    @Component({
        components: {
            OnlineChat,
            UserBox,
            RelyingPartySelection,
            PendingScreen,
            SidebarMenu,
            MenuLink,
            MainLayout,
        },
    })
    export default class Page extends Vue {
        private prevRpName: string | null = null;
        private logoUrl = logoUrl;
        private hoverTimeOut: any;
        private powerByEvidentLogo = powerByEvidentLogo;
        private shouldDisplayPoweredBy = false;

        @Prop({ type: String, default: null })
        private title!: string;

        @Prop({ type: Boolean, default: false })
        private form!: boolean;

        @Prop({ type: Boolean, default: false })
        private loading!: boolean;

        @Prop({ default: null })
        private buildRouteAfterRpChange!: ((rpId: string) => RawLocation) | null;

        @Prop({ type: Boolean, default: false })
        private nonDestructiveOnLoading!: boolean;

        private menuExpanded: boolean = true;
        private menuAnchored: boolean = true;

        private mounted() {
            this.$store.actions.localStorage.loadUserPreference({ email: this.userEmail });
            this.menuExpanded = this.userPreference.sidenavOpened ?? this.menuExpanded;
            this.menuAnchored = this.menuExpanded;
            PartnersSettingsService.fetchPartnerSettings()
                .then((response) => {
                    if (response) {
                        this.shouldDisplayPoweredBy = true;
                    }
                    if (response?.wordmark_url) {
                        this.logoUrl = response.wordmark_url;
                    }
                });
        }

        private get rps() {
            return this.$rp.available;
        }

        private get currentRp() {
            return this.$rp.current;
        }

        private get userEmail(): string {
            return this.$store.state.user?.data?.email || '';
        }

        @Watch('currentRp', { immediate: true })
        private onRpChanges(): void {
            this.prevRpName = this.currentRp;
        }

        private changeRp(rpId: string) {
            if (this.buildRouteAfterRpChange) {
                this.$router.push(this.buildRouteAfterRpChange(rpId)).catch(noop);
            } else {
                this.$rp.change(rpId);
            }
        }

        private onMenuIconClicked(): void {
            this.menuAnchored = !this.menuAnchored;
            this.menuExpanded = this.menuAnchored;
            this.saveUserPreference();
        }

        private onHover(): void {
            if (this.hoverTimeOut) {
                clearTimeout(this.hoverTimeOut);
            }
            this.hoverTimeOut = setTimeout(() => {
                this.menuExpanded = true;
            }, 200);
        }

        private onUnhover(): void {
            clearTimeout(this.hoverTimeOut);
            if (!this.menuAnchored) {
                this.menuExpanded = false;
            }
        }

        private get userPreference(): UserPreference {
            return this.$store.state.localStorage.userPreferences[this.userEmail] || {};
        }

        private saveUserPreference(): void {
            this.$store.actions.localStorage.saveUserPreference({
                email: this.userEmail,
                preference: { sidenavOpened: this.menuExpanded },
            });
        }

        private openSite(name: 'support' | 'help'): void {
            const urls = {
                support: 'https://www.evidentid.com/support/insurance/',
                help: 'https://evidentidsupport.zendesk.com/hc/en-us/categories/360005070814-Evident-Insurance-Verification-Solution',
            };
            if (urls[name]) {
                window.open(urls[name], '_blank');
            }
        }

        private get isChatAvailable(): boolean {
            return !this.$support.isMobileBrowser && !this.loading;
        }

        private destroyed() {
            clearTimeout(this.hoverTimeOut);
        }
    }
</script>

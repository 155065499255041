<template>
    <div class="JsonSchemaObjectView">
        <template v-if="form.schema.title">
            <div class="JsonSchemaObjectView__header">
                <span class="JsonSchemaObjectView__headerName">
                    {{ form.schema.title }}
                </span>
                <EvaluationError v-if="evaluationError" :evaluation-error="evaluationError" v-on="$listeners" />
            </div>
        </template>
        <div
            v-for="(property, index) in properties"
            :key="property.form.key"
            :class="{'JsonSchemaObjectView--withIndex': showIndex(index)}"
            :data-test-id="`JsonSchemaObjectView__${property.name}`"
        >
            <span
                v-if="showIndex(index)"
                class="JsonSchemaObjectView__index"
                :class="getAdditionalIndexClass(properties)"
            >
                {{ index + startIndex }}
            </span>
            <v-component
                :is="FormElementComponent"
                v-if="!hiddenTypes.includes(property.name)"
                :id="`${id}/${property.name}`"
                :key="property.form.key"
                :depth="depth + 1"
                :form="property.form"
                :form-element-component="FormElementComponent"
                :currency="currency"
                :custom-component-input="customComponentInput"
                :value="value[property.name]"
                :evaluation-errors="evaluationErrors"
                v-on="$listeners"
            />
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Prop } from '@evidentid/vue-property-decorator';
    import { JsonFormObject, JsonFormProperty, JsonFormType } from '@evidentid/json-schema/interfaces/JsonForm';
    import EvaluationError from '@/modules/entity-details/components/EvaluationError/EvaluationError.vue';
    import AbstractJsonSchemaView
        from '@/modules/entity-details/components/JsonSchemaView/elements/AbstractJsonSchemaView';

    @Component({
        components: { EvaluationError },
        inheritAttrs: false,
    })
    export default class JsonSchemaObjectView extends AbstractJsonSchemaView<JsonFormObject, object> {
        @Prop({ type: Number, default: 1 })
        private startIndex !: number;

        @Prop({ type: Boolean, default: false })
        private noObjectIndex !: boolean;

        private hiddenTypes = [ '$objectType' ];

        private get properties() {
            return this.form.getProperties(this.value);
        }

        private getAdditionalIndexClass(property: JsonFormProperty): string | null {
            const evaluationError = this.evaluationErrors[`${this.id}/${property.name}`];
            if (evaluationError) {
                return evaluationError.complianceStatusMetadata.length > 0
                    ? 'JsonSchemaObjectView--exception'
                    : 'JsonSchemaObjectView--error';
            }
            return null;
        }

        private showIndex(index: number): boolean {
            return !this.noObjectIndex && this.depth === 0 && this.properties[index].form.type === JsonFormType.object;
        }
    }
</script>

<template>
    <div
        class="text-sm cursor-pointer hover:opacity-70 flex items-center gap-2"
        :class="{'pointer-events-none': disable}"
        data-test-id="DocumentViewButton"
        @click="$emit('click', $event)"
    >
        <EidIcon
            class="text-base"
            :icon-src="iconViewDocument"
            alt="view document"
        />
        <span class="inline-block">View Document</span>
    </div>
</template>

<script lang="ts">
    import Vue from 'vue';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';

    export default Vue.extend({
        name: 'DocumentViewButton',
        components: { EidIcon },
        props: {
            disable: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                iconViewDocument: faArrowUpRightFromSquare,
            };
        },
    });
</script>

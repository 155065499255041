import {
    EntityFilterCategory,
    EntityFilters,
    EntityStandardFiltersObject,
} from '@/modules/entity-filtering/types';
import { ComplianceStatus } from '@evidentid/tprm-portal-lib/models/entity-details';
import moment from 'moment';
import isEqual from 'lodash/isEqual';
import JsonSchema, { JsonSchemaObject, JsonSchemaType, RegularJsonSchema } from '@evidentid/json-schema/interfaces/JsonSchema';
import { EntityCollateralFieldFilters } from '@/modules/entity-filtering/models/EntityCollateralFieldFilters.model';
import { isArray, isEnum, isObject, isString } from '@evidentid/json-schema/schemaChecks';
import { CustomProperty } from '@evidentid/tprm-portal-lib/models/dashboard';

const EXPIRING_SOON_DAYS = 30;
const IF_PREFIX = 'if_';

export const categoryFilterMap = {
    [EntityFilterCategory.compliantAndExpiringSoon]: {
        complianceStatus: ComplianceStatus.compliant,
        expiresAfterOrOn: moment().format('YYYY-MM-DD'),
        expiresBeforeOrOn: moment().add(EXPIRING_SOON_DAYS, 'days').format('YYYY-MM-DD'),
    },
    [EntityFilterCategory.compliantAndNotExpiringSoon]: {
        complianceStatus: ComplianceStatus.compliant,
        expiresAfterOrOn: moment().add(EXPIRING_SOON_DAYS + 1, 'days').format('YYYY-MM-DD'),
    },
    [EntityFilterCategory.nonCompliant]: {
        complianceStatus: ComplianceStatus.nonCompliant,
    },
    [EntityFilterCategory.pendingAndNew]: {
        complianceStatus: `${ComplianceStatus.pending},${ComplianceStatus.new}`,
    },
};

export function getCustomPropertyQueryKey(customPropertyKey: string): string {
    return `${IF_PREFIX}${customPropertyKey}`;
}

export function getObjCustomPropertySubKeys(customProperty: CustomProperty): string[] {
    return Object.keys((customProperty.schema as JsonSchemaObject).properties)
        .map((propKey) => `${IF_PREFIX}${customProperty.key}.${propKey}`);
}

export function getCustomPropertyKeyFromQueryKey(queryKey: string): string {
    return queryKey.replace(IF_PREFIX, '');
}

export function getEntityCollateralFieldQueryKey(customPropertyCollaterals: string, filterKey: string): string {
    return `${IF_PREFIX}${customPropertyCollaterals}.${filterKey}`;
}

export function getEntityCollateralFieldFromQueryKey(queryKey: string, customPropertyCollaterals: string): string {
    return queryKey.replace(`${IF_PREFIX}${customPropertyCollaterals}.`, '');
}

export function getCategoryByFilters(filter: EntityFilters): EntityFilterCategory | null {
    const { search, ...filterWithoutSearch } = filter;
    const foundEntry = Object.entries(categoryFilterMap).find(([ , value ]) => isEqual(value, filterWithoutSearch));
    return foundEntry ? foundEntry[0] as EntityFilterCategory : null;
}

export function getEntityStandardFilterKeys(): string[] {
    return Object.keys(new EntityStandardFiltersObject());
}

export function getCustomPropertyFilterKeys(customProperties: CustomProperty[]): string[] {
    return customProperties.flatMap((field) => (
        isObject(field.schema)
            ? getObjCustomPropertySubKeys(field)
            : [ getCustomPropertyQueryKey(field.key) ]),
    );
}

class EntityCollateralsFiltersObject implements EntityCollateralFieldFilters {
    public description = undefined;
    public uniqueIdentifier = undefined;
    public category = undefined;
    public limitRequired = undefined;
    public maximumDeductible = undefined;
}

export function getEntityCollateralFieldFiltersKeys(customPropertyCollaterals: string): string[] {
    if (customPropertyCollaterals === '') {
        return [];
    }

    const filterKeys = Object.keys(new EntityCollateralsFiltersObject());
    return filterKeys.map((filterKey) => getEntityCollateralFieldQueryKey(customPropertyCollaterals, filterKey));
}

export function parseFiltersForApi(
    filters: EntityFilters,
    customProperties: CustomProperty[],
    collateralCustomPropertyKey: string,
    customPropertyCollaterals: CustomProperty | null,
): EntityFilters {
    const { customPropertyFilters, collateralFieldFilters, ...standardFilters } = filters;
    const parsedCustomPropertyFilters = Object.entries(customPropertyFilters || {})
        .reduce((acc, [ key, val ]) => {
            const schema = customProperties.find((field) =>
                field.key === getCustomPropertyKeyFromQueryKey(key))?.schema as RegularJsonSchema;
            const stringSchema = isString(schema);
            // checking not enum because Enum could also be typed string, the diff is enum type has prop call enum
            const arrayStringSchema = isArray(schema) &&
                isString((schema.items as JsonSchema)) &&
                !isEnum((schema.items as JsonSchema));
            let value = val;
            if (!schema || stringSchema || arrayStringSchema) {
                value = JSON.stringify(value);
            }
            return { ...acc, [key]: value };
        }, {} as Record<string, string>);
    const parsedEntityCollateralFieldFilters = Object.entries(collateralFieldFilters || {})
        .reduce((acc, [ key, val ]) => {
            const schema = (customPropertyCollaterals as any)?.schema.items.properties[
                getEntityCollateralFieldFromQueryKey(key, collateralCustomPropertyKey)
            ];
            let value = val;

            if (!schema || schema.type === JsonSchemaType.string || schema.type === JsonSchemaType.array) {
                value = JSON.stringify(value);
            }

            acc[key] = value;
            return acc;
        }, {} as Record<string, string>);
    const parsedFilters = { ...standardFilters };

    if (Object.keys(parsedCustomPropertyFilters).length > 0) {
        Object.assign(parsedFilters, { customPropertyFilters: parsedCustomPropertyFilters });
    }

    if (Object.keys(parsedEntityCollateralFieldFilters).length > 0) {
        Object.assign(parsedFilters, { collateralFieldFilters: parsedEntityCollateralFieldFilters });
    }

    return parsedFilters;
}

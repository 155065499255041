import { EntityAddress } from '@evidentid/tprm-portal-lib/models/dashboard';
import isNil from 'lodash/isNil';

function countryLabel(schema: any, value: EntityAddress, customLabels?: Record<string, any>): string {
    const countryCode = value?.country || '';
    let label;
    if (customLabels) {
        label = customLabels?.countryLabels?.[countryCode]?.label;
    } else {
        const indexCountryCode = schema.properties.country.enum?.findIndex((code: string) => code === countryCode);
        if (indexCountryCode !== undefined && indexCountryCode !== -1) {
            label = schema.properties.country.enumLabels?.[indexCountryCode];
        }
    }
    return label || countryCode;
}

function isAddressValueEmpty(value?: EntityAddress | null): boolean {
    const { street, aptSuiteOrUnit, city, state, zipCode, country } = value || {};
    return !street && !aptSuiteOrUnit && !city && !state && !zipCode && !country;
}

const appendAddressPortion = (currentAddress = '', newPortion = '', separator = ', ') => {
    if (currentAddress && newPortion) {
        return `${currentAddress}${separator}${newPortion}`;
    } else {
        return `${currentAddress}${newPortion}`;
    }
};

export function getDisplayAddress({
    schema,
    value,
    customLabels,
}: {
    schema: any;
    value?: EntityAddress | null;
    customLabels?: Record<string, any>;
}): string {
    const isEmptyAddress = isAddressValueEmpty(value);
    if (!value || isEmptyAddress) {
        return '-';
    }
    let address = '';
    address = appendAddressPortion(address, value.street);
    address = appendAddressPortion(address, value.aptSuiteOrUnit);
    address = appendAddressPortion(address, value.city);
    address = appendAddressPortion(address, value.state);
    address = appendAddressPortion(address, value.zipCode, ' ');
    address = appendAddressPortion(address, countryLabel(schema, value, customLabels));
    return address || '-';
}

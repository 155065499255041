import startCase from 'lodash/startCase';
import {
    ActionDetails,
    ActionMatchType,
    ActionType,
    ComplianceWithCategoryActionDetails,
    EntityNameMatchingActionDetails,
    NonExtractionResolutionActionDetails,
} from '@evidentid/tprm-portal-lib/models/entity-actions-review';

function extractFieldName(field: string): string {
    const tokens = field.split('.');
    return tokens[tokens.length - 1];
}

export function getEntityMatchFieldName(actionDetails: EntityNameMatchingActionDetails): string {
    switch (actionDetails.matchType) {
        case ActionMatchType.insured:
            return 'Entity Name';
        case ActionMatchType.relyingParty:
            return 'Relying Party';
        case ActionMatchType.custom:
            return extractFieldName(actionDetails.field);
        default:
            return '';
    }
}

function getEntityNameMatching(actionDetails: EntityNameMatchingActionDetails): string {
    const prefix = 'Entity Name Matching';
    const fieldNameLabel = startCase(getEntityMatchFieldName(actionDetails));
    const hyphen = fieldNameLabel ? ' - ' : '';
    return `${prefix}${hyphen}${fieldNameLabel}`;
}

function getNonExtractionCriterionResolution(actionDetails: NonExtractionResolutionActionDetails): string {
    switch (actionDetails.field) {
        case 'exclusions':
            return 'Exclusion Review';
        case 'projectRequirements':
            return 'Project Requirement Review';
        case 'documentVerification':
            return 'Valid Document Review';
        default:
            return `${startCase(actionDetails.field)} Review`;
    }
}

function getComplianceWithCategoryResolution(actionDetails: ComplianceWithCategoryActionDetails) {
    const fieldName = extractFieldName(actionDetails.field);
    const fieldNameLabel = startCase(fieldName);
    return `Form Number Matching - ${fieldNameLabel}`;
}

export function getActionRequired(actionDetails: ActionDetails): string {
    switch (actionDetails.$action) {
        case ActionType.endorsementFormNumberV1:
            return 'Endorsement Review';
        case ActionType.evaluatedEntityMappingV1:
            return 'Collateral mapping';
        case ActionType.credentialTypeMappingResolution:
            return 'Credential Type Review';
        case ActionType.entityNameMatchingV1:
            return getEntityNameMatching(actionDetails);
        case ActionType.nonExtractionCriterionResolutionV1:
            return getNonExtractionCriterionResolution(actionDetails);
        case ActionType.complianceWithCategoryResolution:
            return getComplianceWithCategoryResolution(actionDetails);
        default:
            return '';
    }
}

<template>
    <div class="EntityDetails">
        <!-- this may eventually be removed and implemented as tab level loader instead -->
        <PendingScreen v-show="loading" />
        <JsonSchemaCustomForm
            v-show="!loading"
            v-slot="{ fields }"
            v-model="value"
            class="EntityDetails__content"
            :controller="controller"
        >
            <ActionableAlert
                v-if="alertConfig"
                class="EntityDetails__alert"
                :title="alertConfig.title"
                :name="alertConfig.name"
                :type="alertConfig.type"
                :icon="alertConfig.icon"
                :action-title="alertConfig.actionTitle"
                @action="onAlertAction"
            />
            <InvalidChangesModal
                v-if="showConfirmationModal"
                @input="onModalAction"
            />
            <div class="EntityDetails__header">
                <div class="EntityDetails__headerDisplayNameBlock">
                    <div class="EntityDetails__backButton" @click="onCloseButtonClick">
                        <FontAwesomeIcon
                            class="EntityDetails__headerBackIcon"
                            :icon="faChevronLeft"
                        />
                    </div>
                    <JsonSchemaCustomFormElement
                        class="EntityDetailsDisplayNameForm"
                        :field="fields.displayName"
                        :disabled="!entity.active"
                        @change="onDisplayNameChange"
                    />
                </div>
                <EntityDetailsStatusBar
                    :updating-entity="updatingEntity"
                    :entity="entity"
                    :is-loading-submission-link="isLoadingSubmissionLink"
                    :is-collateral-enabled="isCollateralsFeaturesEnabled"
                    :submission-link-per-entity-id="submissionLinkPerEntityId"
                    :entity-exception="entityLevelException"
                    @grantExceptions="grantExceptions"
                    @removeExceptions="removeExceptions"
                    @open-collateral-mapping-modal="openCollateralMappingModal"
                    @open-historic-document-modal="openHistoricDocumentModal"
                    v-on="statusBarUnhandledEvents"
                />
            </div>
            <Tabs initial-tab="entityDetailsRequirementTypes">
                <Tab id="entityDetailsOverview" name="Properties">
                    <JsonSchemaCustomFormElement
                        class="EntityDetails__form"
                        touched
                        :field="fields.body"
                        :depth="0"
                        :custom-component-input="getCustomComponent"
                        :disabled="!entity.active"
                        :strings="jsonSchemaElementStrings"
                        @change="onBodyChange"
                    />
                    <EntityDetailsCollateralSection
                        v-if="customPropertyCollaterals !== null"
                        :custom-property-collaterals="customPropertyCollaterals"
                        :collaterals="collaterals"
                        :entity-display-name="entity.displayName"
                        :updating-entity="updatingEntity"
                        @input="onCollateralsChange"
                        @on-collaterals-change="onCollateralsChange"
                    />
                </Tab>
                <Tab
                    id="entityDetailsRequirementTypes"
                    class="EntityDetails__requirementTypesTab"
                    name="Current Status"
                >
                    <EmptyRequirementTypes v-if="sortedDetailsList.length === 0" />
                    <div v-else>
                        <Policy
                            v-for="requirementDetails in sortedDetailsList"
                            :key="getUniqueRequirementDetailsId(requirementDetails)"
                            :requirement-details="requirementDetails"
                            :all-requirements="sortedDetailsList"
                            :evaluation-errors="getEvaErrorMapForRequirement(requirementDetails)"
                            :entity="entity"
                            :entity-risk-profiles="entityRiskProfiles"
                            :requirement-models="requirementModels"
                            :credential-types="credentialTypes"
                            :collateral-entities="collateralEntities"
                            @goToCriterion="goToCriterion"
                            @grantExceptions="grantExceptions($event, requirementDetails.coverage)"
                            @removeExceptions="removeExceptions"
                        />
                    </div>
                </Tab>
                <Tab
                    id="entityDetailsRiskProfiles"
                    class="EntityDetails__requirementGroupsTab"
                    name="Risk Profiles"
                >
                    <EntityRiskProfiles
                        :entity-risk-profiles="sortedEntityCriteriaGroups"
                        :entity="entity"
                        @goToRiskProfileList="navigateToRiskProfileList"
                        @goToRiskProfile="navigateToRiskProfile"
                    />
                </Tab>
                <Tab
                    v-if="isCollateralsFeaturesEnabled"
                    id="entityDetailsCollaterals"
                    class="EntityDetails__collateralsTab"
                    name="Collaterals"
                >
                    <CollateralTab
                        :collaterals="collateralEntities"
                        :sorted-details-list="sortedDetailsList"
                        :entity="entity"
                        @grant-exceptions="grantExceptions($event)"
                        @remove-exceptions="removeExceptions"
                    />
                </Tab>
                <template #headerEnd>
                    <EffectiveRiskProfilesTooltipLabel :risk-profile-names="effectiveRiskProfileNames" />
                </template>
            </Tabs>
        </JsonSchemaCustomForm>
    </div>
</template>

<script lang="ts">
    import { PropType } from 'vue';
    import { Component, Prop, Vue, Watch } from '@evidentid/vue-property-decorator';
    import { isEmpty, isEqual, noop, omit, omitBy, pick, sortBy, startCase } from 'lodash';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import { faCheckCircle, faChevronLeft, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { PatchOperationType } from '@evidentid/rpweb-api-client';
    import { EntityRiskProfile } from '@evidentid/tprm-portal-lib/models/decisioning';
    import {
        TprmRequirementType,
        ComplianceStatusOverride,
        ExceptionInput,
        ExceptionLevel,
    } from '@evidentid/tprm-portal-lib/models/entity-details';

    import createForm from '@evidentid/json-schema/createForm';
    import { JsonFormObject, JsonFormType } from '@evidentid/json-schema/interfaces/JsonForm';
    import { Alert } from '@evidentid/dashboard-commons/components/Alert';
    import { Badge } from '@evidentid/dashboard-commons/components/Badge';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import {
        JsonSchemaCustomForm,
        JsonSchemaCustomFormElement,
        JsonSchemaFormStrings,
    } from '@evidentid/dashboard-commons/components/JsonSchemaForm';
    import { Tab, Tabs } from '@evidentid/dashboard-commons/components/Tabs';
    import ActionsButton from '@/modules/dashboard/components/ActionsButton/ActionsButton.vue';
    import ComplianceStatusBadge from '@/modules/dashboard/components/ComplianceStatusBadge/ComplianceStatusBadge.vue';
    import { buildEntityDetailsJsonSchema } from '@/modules/entity-management/utils/buildEntityDetailsJsonSchema';
    import { BatchUpdateEntitiesStatus, PatchEntitiesStatus } from '@/modules/entity-management/vuex';
    import ActionableAlert from '@/modules/entity-details/components/ActionableAlert/ActionableAlert.vue';
    import EntityDetailsBooleanForm
        from '@/modules/entity-details/components/CustomJsonForms/EntityDetailsBooleanForm.vue';
    import EntityDetailsStringForm
        from '@/modules/entity-details/components/CustomJsonForms/EntityDetailsStringForm.vue';
    import InvalidChangesModal from '@/modules/entity-details/components/InvalidChangesModal/InvalidChangesModal.vue';
    import EntityDetailsStatusBar
        from '@/modules/entity-details/components/EntityDetailsStatusBar/EntityDetailsStatusBar.vue';
    import Policy from '@/modules/entity-details/components/Policy/Policy.vue';
    import { ActionableAlertConfig } from '@/modules/entity-details/components/ActionableAlert/types';
    import {
        editingRequiresPutOperation,
        getEntityFieldsDiff,
        isDeletingInsureFieldItem,
        isNullishOrEmptyValue,
    } from '@/modules/entity-details/utils/entityEditing';
    import { FormController, Pointer } from '@evidentid/json-schema/FormController';
    import { PendingScreen } from '@evidentid/dashboard-commons/screens/PendingScreen';
    import EntityRiskProfiles
        from '@/modules/entity-details/components/EntityRiskProfiles/EntityRiskProfiles.vue';
    import EffectiveRiskProfilesTooltipLabel
        from '@/modules/entity-details/components/EffectiveRiskProfilesTooltipLabel/EffectiveRiskProfilesTooltipLabel.vue';
    import EmptyRequirementTypes from '@/modules/entity-details/components/EmptyRequirementTypes/EmptyRequirementTypes.vue';
    import EntityDetailsCollateralSection
        from './components/EntityDetailsCollateralSection/EntityDetailsCollateralSection.vue';
    import { FieldEvaluationResultError } from '@/modules/decisioning-criteria/types';
    import { mapEvaluationErrors } from '@/modules/entity-details/utils/mapEvaluationErrors';
    import {
        CollateralEntity,
    } from '@evidentid/tprm-portal-lib/models/entity-details/Collateral/CollateralEntity.model';
    import CollateralTab from './components/CollateralTab/CollateralTab.vue';
    import { isCollateralCustomProperty } from '@/utils/isCollateralCustomProperty';
    import EntityDetailsAddressForm
        from '@/modules/entity-details/components/CustomJsonForms/EntityDetailsAddressForm/EntityDetailsAddressForm.vue';
    import JsonSchemaCountryEnumForm
        from '@evidentid/dashboard-commons/components/JsonSchemaForm/customElements/JsonSchemaCountryEnumForm.vue';
    import { getJsonSchemaCompFunction } from '@/utils/getJsonSchemaCustomCompFuntion';
    import { standardizeCriterionSchema } from '@/modules/decisioning-criteria/utils/standardizeCriterionSchema';
    import { CategorizedEnumLabels } from '@/modules/dashboard/models/CategorizedEnumLabels.model';
    import cloneDeep from 'lodash/cloneDeep';
    import {
        filterOutIsPresentUsedFields,
    } from '@/modules/entity-details/utils/filterOutIsPresentUsedFields/filterOutIsPresentUsedFields';
    import { CustomProperty, Entity, EntityAddress } from '@evidentid/tprm-portal-lib/models/dashboard';
    import {
        CustomPropertyCollateral,
        CustomPropertyValues,
    } from '@evidentid/tprm-portal-lib/models/dashboard/CustomProperty.model';
    import {
        TprmSchemaDisplayFormat,
    } from '@evidentid/tprm-portal-lib/models/common/TprmSchemaDisplayFormat.model';
    import {
        EntityRequirement,
        EntityRequirementDetails,
    } from '@evidentid/tprm-portal-lib/models/entity-details/EntityRequirement.model';
    import {
        TprmRequirementModel,
    } from '@evidentid/tprm-portal-lib/models/entity-details/TprmRequirementModel.model';
    import { TprmCredentialType } from '@evidentid/tprm-portal-lib/models/common';
    import CriterionEnumInput
        from '@/modules/decisioning-criteria/components/CriterionInputComponents/CriterionEnumInput.vue';
    import {
        getEvaluationErrorsMapKey,
    } from '@/modules/entity-details/utils/getEvaluationErrorsMapKey/getEvaluationErrorsMapKey';

    interface EntityDetailsValue {
        displayName: string;
        body: {
            profile: {
                legalName?: string | null;
                doingBusinessAs?: string[] | null;
                contactEmail: string;
                contactName?: string;
                contactPhoneNumber?: string | null;
                address?: EntityAddress | null;
            };
            insuredFields?: CustomPropertyValues;
        };
    }

    function buildEntityDetailsController(customProperties: CustomProperty[]): FormController {
        const schema = standardizeCriterionSchema(buildEntityDetailsJsonSchema(
            customProperties,
            [ 'required', (x) => x.name ],
            [ 'desc', 'asc' ],
        ));
        return new FormController(createForm(schema));
    }

    function concatCollateralsCustomProperties(
        customPropertyValues?: CustomPropertyValues,
        collaterals?: CustomPropertyCollateral[],
        collateralKey?: string,
    ): CustomPropertyValues | null {
        if (!customPropertyValues && !collaterals) {
            return null;
        }
        return {
            ...customPropertyValues,
            ...(collaterals && collateralKey && { [collateralKey]: collaterals }),
        };
    }

    function getCollateralsOmittingNullProps(
        collaterals?: CustomPropertyCollateral[],
    ): CustomPropertyCollateral[] | undefined {
        if (!collaterals) {
            return collaterals;
        }

        return collaterals
            .map((collateral) => omitBy(collateral, isNullishOrEmptyValue) as CustomPropertyCollateral);
    }

    function getCustomPropertiesOmittingCollateralsNullProps(
        customProperties: CustomPropertyValues | null,
    ): CustomPropertyValues | null {
        if (!customProperties) {
            return customProperties;
        }

        if (customProperties.collaterals) {
            return {
                ...customProperties,
                collaterals: getCollateralsOmittingNullProps(
                    customProperties.collaterals as CustomPropertyCollateral[],
                ),
            };
        }

        return customProperties;
    }

    @Component({
        components: {
            ActionableAlert, ActionsButton, Alert, Badge, Button, ComplianceStatusBadge,
            EffectiveRiskProfilesTooltipLabel, EmptyRequirementTypes, FontAwesomeIcon, EntityRiskProfiles,
            EntityDetailsStatusBar, InvalidChangesModal, JsonSchemaCustomForm, JsonSchemaCustomFormElement,
            PendingScreen, Policy, Tab, Tabs, EntityDetailsCollateralSection, CollateralTab,
        },
    })
    export default class EntityDetails extends Vue {
        @Prop({ type: Object, required: true })
        private entity!: Entity;

        @Prop({ type: Array, default: () => [] })
        private customProperties!: CustomProperty[];

        @Prop({ type: Array, default: () => [] })
        private requirementDetailsList!: EntityRequirementDetails[];

        @Prop({ type: Array, default: () => [] })
        private collateralEntities!: CollateralEntity[];

        @Prop({ type: Array, default: () => [] })
        private entityRiskProfiles!: EntityRiskProfile[];

        @Prop({ type: Array, default: () => [] })
        private currentlyUpdatedEntitiesIds!: string[];

        @Prop({ type: Boolean, default: false })
        private isLoadingSubmissionLink!: boolean;

        @Prop({ type: Boolean, default: false })
        private loading!: boolean;

        @Prop({ type: Object, default: () => ({}) })
        private submissionLinkPerEntityId!: Record<string, string>;

        @Prop({ type: Boolean, default: false })
        private isCollateralEnabled!: boolean;

        @Prop({ type: Array, default: () => [] })
        private requirementModels!: TprmRequirementModel[];

        @Prop({ type: Array, default: () => [] })
        private credentialTypes!: TprmCredentialType[];

        @Prop({ type: Object, default: () => ({}) })
        private externalAlertConfig!: ActionableAlertConfig;

        @Prop({ type: String as PropType<string>, default: '' })
        private collateralCustomPropertyKey!: string;

        @Prop({ type: Object as PropType<Record<string, any>>, default: '' })
        private enumLabels!: CategorizedEnumLabels;

        private showConfirmationModal: boolean = false;
        private value: EntityDetailsValue | null = null;
        private controller: FormController = buildEntityDetailsController([]);
        private faChevronLeft = faChevronLeft;
        private faCheckCircle = faCheckCircle;
        private faExclamationTriangle = faExclamationTriangle;
        private undoInfo: {
            insuredId: string;
            customPropertyKey: string;
            previousValue: any;
            currentValue: any;
        } | null = null;
        private alertTimeout: any = null;
        private startedUpdatingEntity: boolean = false;
        private sortedDetailsList: EntityRequirementDetails[] = [];
        private sortedEntityCriteriaGroups: EntityRiskProfile[] = [];

        // TODO: needs to rename CriterionEnumInput more generic as UX prefer it for most of dropdown input
        private customComponents = {
            [JsonFormType.boolean]: EntityDetailsBooleanForm,
            [JsonFormType.string]: EntityDetailsStringForm,
            [JsonFormType.enum]: CriterionEnumInput,
            [TprmSchemaDisplayFormat.address]: EntityDetailsAddressForm,
            [TprmSchemaDisplayFormat.country]: JsonSchemaCountryEnumForm,
        };

        private getCustomComponent = getJsonSchemaCompFunction(this.customComponents);

        private localAlertConfig: ActionableAlertConfig | null = null;

        private statusBarUnhandledEvents = omit(this.$listeners, [
            'grantExceptions', 'removeExceptions', 'open-collateral-mapping-modal', 'open-historic-document-modal',
        ]);

        private get customPropertiesOther(): CustomProperty[] {
            return this.customProperties.filter((customProperty) =>
                !isCollateralCustomProperty(customProperty),
            );
        }

        private get customPropertyCollaterals(): CustomProperty | null {
            return this.customProperties.find((customProperty) =>
                isCollateralCustomProperty(customProperty),
            ) || null;
        }

        private get collaterals(): CustomPropertyCollateral[] | undefined {
            const key = this.collateralCustomPropertyKey;
            return this.entity.insuredFields && key
                ? this.entity.insuredFields[key] as CustomPropertyCollateral[]
                : undefined;
        }

        private get fields(): Record<string, Pointer> {
            return this.controller.structure.properties!;
        }

        private get deleteAlertTitle(): string {
            return `${startCase(this.undoInfo?.customPropertyKey || '')} Deleted`;
        }

        private get rpName(): string {
            return this.$rp.current!;
        }

        private get updatingEntity(): boolean {
            return (
                this.updateEntitiesStatus.status === OperationStatus.loading &&
                this.startedUpdatingEntity
            ) || this.currentlyUpdatedEntitiesIds.includes(this.entity.id);
        }

        private get isCollateralsFeaturesEnabled(): boolean {
            return this.isCollateralEnabled && this.collateralEntities.length > 0;
        }

        private get updateEntitiesStatus(): BatchUpdateEntitiesStatus {
            return this.$store.state.entityManagement.updateEntitiesStatus[this.rpName] || {
                status: OperationStatus.uninitialized,
                successCount: 0,
                failureCount: 0,
                totalCount: 0,
                successes: [],
                failures: [],
            };
        }

        private get patchEntitiesStatus(): PatchEntitiesStatus {
            return this.$store.state.entityManagement.patchEntitiesStatus[this.rpName] || {
                status: OperationStatus.uninitialized,
                successCount: 0,
                failureCount: 0,
                totalCount: 0,
                successes: [],
                failures: [],
                currentlyUpdated: [],
                isBulk: false,
            };
        }

        private get grantExceptionStatus(): OperationStatus {
            return this.$store.state.entityDetails.grantExceptionStatus[this.rpName] || OperationStatus.uninitialized;
        }

        private get removeExceptionStatus(): OperationStatus {
            return this.$store.state.entityDetails.removeExceptionStatus[this.rpName] || OperationStatus.uninitialized;
        }

        private get entityLevelException(): ComplianceStatusOverride | null {
            const details = this.requirementDetailsList.find(
                (details) => details.complianceStatusMetadata &&
                    details.complianceStatusMetadata.statusOverride &&
                    details.complianceStatusMetadata.statusOverride.level === ExceptionLevel.insured);
            return details?.complianceStatusMetadata?.statusOverride || null;
        }

        private get effectiveRiskProfileNames(): string[] {
            return this.sortedEntityCriteriaGroups
                .filter((group) => group.active)
                .map((group) => group.displayName);
        }

        private get alertConfig(): ActionableAlertConfig {
            return this.externalAlertConfig || this.localAlertConfig;
        }

        private get allRequirementTypeEvaluationErrors(): Record<string, Record<string, FieldEvaluationResultError>> {
            return this.sortedDetailsList.reduce((acc, requirementDetails) => {
                const key = getEvaluationErrorsMapKey(requirementDetails.coverageType, requirementDetails.instanceId);
                if (requirementDetails.coverage) {
                    acc[key] = mapEvaluationErrors(
                        requirementDetails.evaluationResults ?? [],
                        this.entityRiskProfiles,
                        requirementDetails,
                    );
                } else {
                    acc[key] = {};
                }
                return acc;
            }, {} as Record<string, Record<string, FieldEvaluationResultError>>);
        }

        private get jsonSchemaElementStrings(): Partial<JsonSchemaFormStrings> {
            return { customLabels: { countryLabels: this.enumLabels.countryCode } };
        }

        @Watch('entity', { immediate: true })
        private onEntityChange(): void {
            if (!this.value) {
                this.value = {
                    displayName: this.entity.displayName,
                    body: {
                        profile: {
                            legalName: this.entity.legalName,
                            doingBusinessAs: this.entity.doingBusinessAs,
                            contactEmail: this.entity.contactEmail,
                            contactName: this.entity.contactName,
                            contactPhoneNumber: this.entity.contactPhoneNumber,
                            address: this.entity.address,
                        },
                        insuredFields: omit({ ...this.entity.insuredFields }, this.collateralCustomPropertyKey),
                    },
                };
            }
        }

        @Watch('customPropertiesOther', { immediate: true })
        private onCustomPropertiesChange(): void {
            this.controller = buildEntityDetailsController(this.customPropertiesOther);
        }

        @Watch('updateEntitiesStatus', { immediate: true })
        private onUpdateEntityChange(): void {
            this.showUpdateErrorIfEncountered();
            this.showAlertIfDeleteOnUpdate();
            this.resetUpdatingEntityStatusIfDone();
        }

        @Watch('patchEntitiesStatus', { immediate: true })
        private onPatchEntityChange(): void {
            this.showPatchErrorIfEncountered();
            this.showAlertIfDeleteOnPatch();
        }

        @Watch('requirementDetailsList', { immediate: true })
        private onRequirementDetailsChange(): void {
            /*  currently we are only supporting credential types as multi instance requirements. however once we
             * support other types, we need to update this logic. hopefully we can have one single api to fetch
             * all instance models and use instanceId to find labels within that list rather than checking what
             * all categories we have and then find labels for them by calling separate apis per category.
             */
            const requirementDetailsListNew = cloneDeep(sortBy(this.requirementDetailsList, [
                (details) =>
                    this.requirementModels.find((model) => model.coverageType === details.coverageType)?.label ||
                    details.coverageType,
                (details) =>
                    this.credentialTypes.find(
                        (model) => model.id === details.instanceId,
                    )?.displayName || details.instanceId,
            ]));
            requirementDetailsListNew.forEach((details) => {
                details.evaluationResults =
                    details.evaluationResults
                        .map((evaluationResult) => filterOutIsPresentUsedFields(evaluationResult));
            });
            this.sortedDetailsList = requirementDetailsListNew;
        }

        @Watch('entityRiskProfiles', { immediate: true })
        private onEntityRiskProfilesChange(): void {
            this.sortedEntityCriteriaGroups =
                sortBy(this.entityRiskProfiles, [ (group) => group.displayName.toLowerCase() ]);
        }

        private onModalAction(confirmed: boolean): void {
            if (confirmed) {
                this.showConfirmationModal = false;
            } else {
                this.$emit('close');
            }
        }

        private onAlertAction(alertName: string) {
            if (alertName === 'undo') {
                this.undoDeletion();
            }
        }

        private onCloseButtonClick(): void {
            if (!this.fields.displayName.valid || !this.fields.body.valid) {
                this.showConfirmationModal = true;
            } else {
                this.checkAndEditCustomProperties();
                this.$emit('close');
            }
        }

        private onDisplayNameChange(): void {
            if (this.value && this.fields.displayName.valid) {
                const diff = getEntityFieldsDiff(
                    { displayName: this.entity.displayName },
                    { displayName: this.value.displayName },
                );
                this.editStandardFieldsIfDiff(diff);
            }
        }

        private onBodyChange(): void {
            this.checkAndEditStandardFields();
            this.checkAndEditCustomProperties();
        }

        private onCollateralsChange(collaterals: CustomPropertyCollateral[]): void {
            this.editCustomPropertiesIfDiff({
                ...this.entity,
                insuredFields: concatCollateralsCustomProperties(
                    this.value?.body.insuredFields,
                    collaterals,
                    this.collateralCustomPropertyKey,
                ),
            } as Entity);
        }

        private checkAndEditStandardFields(): void {
            if (this.value && this.fields.displayName.valid && this.fields.body.properties!.profile.valid) {
                const editableStandardFieldNames =
                    (this.fields.body.properties!.profile.form as JsonFormObject).getProperties().map((x) => x.name);
                const originalValue = pick(this.entity, editableStandardFieldNames);
                const diff = getEntityFieldsDiff(originalValue, this.value.body.profile);
                this.editStandardFieldsIfDiff(diff);
            }
        }

        private checkAndEditCustomProperties(): void {
            if (this.fields.body.properties!.insuredFields?.valid) {
                this.editCustomPropertiesIfDiff({
                    ...this.entity,
                    insuredFields:
                        concatCollateralsCustomProperties(
                            this.value?.body.insuredFields, this.collaterals, this.collateralCustomPropertyKey,
                        ),
                } as Entity);
            }
        }

        private editStandardFieldsIfDiff(diff: Partial<Entity>): void {
            if (!isEmpty(diff)) {
                if (editingRequiresPutOperation(this.entity, diff)) {
                    // TODO(PRODUCT-15000): Revisit it when PATCH protocol will support create Custom Property action.
                    this.updateEntity({ ...this.entity, ...diff });
                    this.startedUpdatingEntity = true;
                } else {
                    this.patchStandardFieldsWithDiff(diff);
                }
            }
        }

        private editCustomPropertiesIfDiff(entity: Entity) {
            if (this.entity.insuredFields) {
                const diff = getEntityFieldsDiff(this.entity.insuredFields, entity.insuredFields || {});
                if (!isEmpty(diff)) {
                    this.updateUndoInfoIfDelete(this.entity, diff);
                    if (editingRequiresPutOperation(this.entity.insuredFields, diff)) {
                        this.updateEntity({
                            ...this.entity,
                            insuredFields:
                                omitBy({ ...this.entity.insuredFields, ...diff }, isNullishOrEmptyValue),
                        });
                        this.startedUpdatingEntity = true;
                    } else {
                        this.patchCustomPropertiesWithDiff(diff);
                    }
                }
            }
        }

        private updateEntity(entity: Entity) {
            const entityToEmit = entity;

            if (entityToEmit?.insuredFields?.collaterals) {
                entityToEmit.insuredFields = getCustomPropertiesOmittingCollateralsNullProps(
                    entityToEmit.insuredFields,
                );
            }
            this.$emit('updateEntities', [ entityToEmit ]);
        }

        private patchStandardFieldsWithDiff(diff: Record<string, any>): void {
            const entityData = [
                Object.entries(diff).reduce((p, [ k, v ]) => {
                    if (k === 'insuredFields') {
                        const oldCustomProperties = getCustomPropertiesOmittingCollateralsNullProps(this.entity[k]);
                        const newCustomProperties = getCustomPropertiesOmittingCollateralsNullProps(v);

                        p[k] = {
                            op: PatchOperationType.update,
                            oldValue: oldCustomProperties,
                            newValue: newCustomProperties,
                        };
                    } else {
                        p[k] = {
                            op: PatchOperationType.update,
                            oldValue: (this.entity as Record<string, any>)[k],
                            newValue: v,
                        };
                    }
                    return p;
                }, { id: this.entity.id } as Record<string, unknown>),
            ];
            this.$emit('patchEntity', entityData);
        }

        private patchCustomPropertiesWithDiff(diff: Record<string, any>): void {
            const customProperties = Object
                .entries(omitBy(diff, isNullishOrEmptyValue))
                .reduce((p, [ k, v ]) => {
                            const oldValue = this.entity.insuredFields
                                ? this.entity.insuredFields[k]
                                : undefined;
                            const op = oldValue === undefined
                                ? PatchOperationType.replace
                                : PatchOperationType.update;
                            if (k === this.collateralCustomPropertyKey) {
                                p[k] = {
                                    op,
                                    oldValue: getCollateralsOmittingNullProps(
                                        oldValue as (undefined | CustomPropertyCollateral[]),
                                    ),
                                    newValue: getCollateralsOmittingNullProps(v),
                                };
                            } else {
                                p[k] = {
                                    op,
                                    oldValue,
                                    newValue: v,
                                };
                            }
                            return p;
                        },
                        {} as any,
                );
            this.$emit('patchEntity', [ {
                id: this.entity.id,
                insuredFields: customProperties,
            } ]);
        }

        private updateUndoInfoIfDelete(entity: Entity, diff: Record<string, any>): void {
            Object.entries(diff).forEach(([ key, value ]) => {
                if (isDeletingInsureFieldItem(key, value, entity.insuredFields)) {
                    this.undoInfo = {
                        insuredId: entity.id,
                        customPropertyKey: key,
                        previousValue: entity.insuredFields![key],
                        currentValue: value,
                    };
                }
            });
        }

        private undoDeletion() {
            if (this.undoInfo) {
                clearTimeout(this.alertTimeout);
                this.alertTimeout = null;
                this.localAlertConfig = null;
                this.$emit('updateEntities', [ {
                    ...this.entity,
                    insuredFields: {
                        ...this.entity.insuredFields,
                        [this.undoInfo.customPropertyKey]: this.undoInfo.previousValue,
                    },
                } ]);
                this.undoInfo = null;
            }
        }

        private displayDeleteAlert() {
            if (this.alertTimeout) {
                clearTimeout(this.alertTimeout);
                this.alertTimeout = null;
            }
            this.localAlertConfig = {
                type: 'success',
                name: 'undo',
                title: this.deleteAlertTitle,
                actionTitle: 'UNDO',
                icon: this.faCheckCircle,
            };
            this.alertTimeout = setTimeout(() => {
                this.localAlertConfig = null;
                this.undoInfo = null;
            }, 5000);
        }

        private async showPatchErrorIfEncountered(): Promise<void> {
            if (this.patchEntitiesStatus.failureCount > 0) {
                if (this.patchEntitiesStatus.failures.some((failure) =>
                    // We don't have custom error code for oldValue mismatch, so we need to check error message.
                    failure.error.message.includes('oldValue did not match current value'))) {
                    await this.$store.actions.snackbar.displaySnackbar({
                        message: 'Please reload the page to synchronize data.',
                        permanent: true,
                        success: false,
                    });
                } else {
                    this.setTimedAlert({ type: 'danger', title: `Something went wrong!` });
                }
            }
        }

        private showUpdateErrorIfEncountered(): void {
            if (this.updateEntitiesStatus.status === OperationStatus.success
                && this.updateEntitiesStatus.failures.length > 0) {
                this.setTimedAlert({
                    type: 'danger',
                    title: 'Cannot update the entity, an unknown error occurred.',
                });
            } else if (this.updateEntitiesStatus.status === OperationStatus.error) {
                this.setTimedAlert({
                    type: 'danger',
                    title: 'Sorry, something went wrong during update entity. Please try again later.',
                });
            }
        }

        private showAlertIfDeleteOnPatch(): void {
            if (this.patchEntitiesStatus.successCount > 0 && this.undoInfo) {
                const matchedEntity =
                    this.patchEntitiesStatus.successes.find((x) => x.id === this.undoInfo?.insuredId);
                const newValue = matchedEntity?.insuredFields?.[this.undoInfo?.customPropertyKey]?.newValue;
                const oldValue = matchedEntity?.insuredFields?.[this.undoInfo?.customPropertyKey]?.oldValue;
                if (
                    matchedEntity?.insuredFields
                    && isEqual(newValue, this.undoInfo?.currentValue)
                    && isEqual(oldValue, this.undoInfo?.previousValue)
                ) {
                    this.displayDeleteAlert();
                }
            }
        }

        private showAlertIfDeleteOnUpdate(): void {
            if (this.updateEntitiesStatus.status === OperationStatus.success && this.undoInfo) {
                const matchedEntity =
                    this.updateEntitiesStatus.successes.find((x) => x.id === this.undoInfo?.insuredId);
                // since update means add new from nothing or delete to empty. so empty here means delete
                if (matchedEntity && matchedEntity.insuredFields &&
                    !matchedEntity.insuredFields[this.undoInfo.customPropertyKey]) {
                    this.displayDeleteAlert();
                }
            }
        }

        private resetUpdatingEntityStatusIfDone(): void {
            if (this.updateEntitiesStatus.successes.some((x) => x.id === this.entity.id) ||
                (this.updateEntitiesStatus.failures as Entity[])?.some((x) => x.id === this.entity.id)
            ) {
                this.startedUpdatingEntity = false;
            }
        }

        private hideAlert(): void {
            this.localAlertConfig = null;
            clearTimeout(this.alertTimeout);
        }

        private setTimedAlert(config: ActionableAlertConfig, delay = 5000) {
            this.hideAlert();
            this.localAlertConfig = { ...config };
            this.alertTimeout = setTimeout(() => {
                this.hideAlert();
            }, delay);
        }

        private goToCriterion(riskProfileId: string, coverageType: TprmRequirementType): void {
            this.$emit('goToCriterion', riskProfileId, coverageType);
        }

        private finishGrantExceptionProcedure = (modified: boolean, entityName: string): void => {
            if (!modified) {
                return;
            }
            if (this.grantExceptionStatus === OperationStatus.success) {
                this.setTimedAlert({
                    icon: this.faCheckCircle,
                    type: 'success',
                    title: `Exception granted for ${entityName}`,
                });
                this.$emit('evaluationUpdated');
            } else {
                this.setTimedAlert({
                    icon: this.faExclamationTriangle,
                    type: 'danger',
                    title: `Failed to grant exception for ${entityName}`,
                });
            }
        };

        private grantExceptions(exceptions: ExceptionInput[], requirement?: EntityRequirement | null) {
            /*
             * we could have no coverage passed for 2 cases,
             * entity level or entity exception (collateral) from collateral tab
             * while exceptions can be array, at the moment the only time there will be more than one is when grant all
             * for all non-compliant criteria. in this case all exception are associated with one single coverage type
             * hence it's safe to grab coverage type from the first index of exceptions.
             * */
            let associatedCoverage = requirement;
            if (!associatedCoverage && exceptions[0].coverageType) {
                associatedCoverage =
                    this.requirementDetailsList.find(
                        (detail) => detail.coverageType === exceptions[0].coverageType,
                    )?.coverage;
            }
            this.$procedures.execute('grantExceptionProcedure', {
                exception: {
                    rpName: this.rpName,
                    insured: this.entity,
                    data: exceptions,
                },
                requirement: associatedCoverage,
            }, this.finishGrantExceptionProcedure);
        }

        private finishRemoveExceptionProcedure = (modified: boolean, entityName: string): void => {
            if (!modified) {
                return;
            }
            if (this.removeExceptionStatus === OperationStatus.success) {
                this.setTimedAlert({
                    icon: this.faCheckCircle,
                    type: 'success',
                    title: `Exception removed for ${entityName}`,
                });
                this.$emit('evaluationUpdated');
            } else {
                this.setTimedAlert({
                    icon: this.faExclamationTriangle,
                    type: 'danger',
                    title: `Failed to remove exception for ${entityName}`,
                });
            }
        };

        private removeExceptions(exceptionIds: string[]): void {
            this.$procedures.execute('removeExceptionProcedure', {
                rpName: this.rpName,
                entity: this.entity,
                exceptionIds,
            }, this.finishRemoveExceptionProcedure);
        }

        private openCollateralMappingModal(): void {
            this.$procedures.execute('collateralMappingProcedure', {
                collaterals: this.collateralEntities,
                entityContactEmail: this.entity.contactEmail,
                entityId: this.entity.id,
            });
        }

        private openHistoricDocumentModal(): void {
            this.$emit('open-historic-document-modal', this.entity);
        }

        private navigateToRiskProfileList(): void {
            this.$router.push({
                name: 'decisioning',
                params: {
                    rpId: this.rpName,
                },
            }).catch(noop);
        }

        private navigateToRiskProfile(id: string): void {
            this.$router.push({
                name: 'decisioningRiskProfile',
                params: {
                    rpId: this.rpName,
                    id,
                },
            }).catch(noop);
        }

        private getUniqueRequirementDetailsId(requirement: EntityRequirementDetails): string {
            return requirement.instanceId
                ? `${requirement.coverageType} - ${requirement.instanceId}`
                : requirement.coverageType;
        }

        private getEvaErrorMapForRequirement(
            details: EntityRequirementDetails,
        ): Record<string, FieldEvaluationResultError> {
            const key = getEvaluationErrorsMapKey(details.coverageType, details.instanceId);
            return this.allRequirementTypeEvaluationErrors[key] || {};
        }

        private destroyed() {
            clearTimeout(this.alertTimeout);
        }
    }
</script>

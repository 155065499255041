<template>
    <div class="EidIcon" @click="$emit('click', $event)">
        <inline-svg
            v-if="iconSrc && iconType === 'svg'"
            class="EidIcon__svg"
            v-bind="svgProps"
            :src="iconSrc"
        />
        <FontAwesomeIcon
            v-else-if="iconSrc && iconType === 'fontAwesome'"
            v-bind="iconProps"
            :icon="iconSrc"
        />
        <img
            v-else
            :src="iconSrc"
            :alt="alt"
        >
    </div>
</template>
<style lang="scss">
    @import "./index.scss";
</style>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import InlineSvg from 'vue-inline-svg';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
    import { EidIconFontAwesomeProps, EidIconInlineSvgProps } from './types';

    type iconType = 'image' | 'svg' | 'fontAwesome';

    export default Vue.extend({
        name: 'EidIcon',
        components: {
            InlineSvg,
            FontAwesomeIcon,
        },
        props: {
            iconSrc: {
                type: [ String, Object ] as PropType<string | IconDefinition>,
                default: '',
            },
            alt: {
                type: String,
                default: 'icon',
            },
            iconProps: {
                type: Object as PropType<EidIconFontAwesomeProps>,
                default: () => ({}),
            },
            svgProps: {
                type: Object as PropType<EidIconInlineSvgProps>,
                default: () => ({}),
            },
        },
        computed: {
            iconType(): iconType {
                if (typeof this.iconSrc !== 'string') {
                    return 'fontAwesome';
                }
                return this.iconSrc.endsWith('.svg') ? 'svg' : 'image';
            },
        },
    });
</script>

<template>
    <Modal
        open
        :additional-class-name="additionalClasses"
        @close="close"
    >
        <template #header>
            Submission History for {{ entity.displayName }}
        </template>
        <LogoLoader v-if="loading" class="h-full w-full flex items-center justify-center" />
        <div v-else class="InsuranceInformationalModal__bodyText">
            <DocumentSubmissionHistoryTable :submissions="historicDocumentSubmissions" @show-document="showDocument" />
        </div>
    </Modal>
</template>

<script lang="ts">
    import { Vue } from '@evidentid/vue-property-decorator';
    import { PropType } from 'vue';
    import orderBy from 'lodash/orderBy';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import { TprmRequirementType } from '@evidentid/tprm-portal-lib/models/entity-details';
    import { LogoLoader } from '@evidentid/dashboard-commons/components/LogoLoader';
    import { Modal } from '@evidentid/dashboard-commons/components/Modal';
    import DocumentSubmissionHistoryTable
        from '@/modules/entity-details/components/DocumentSubmissionHistoryTable/DocumentSubmissionHistoryTable.vue';
    import { HistoricDocumentSubmission } from '@/models/HistoricDocumentSubmission.model';
    import difference from 'lodash/difference';
    import { Entity } from '@evidentid/tprm-portal-lib/models/dashboard';
    import { EntityRequirementDetails } from '@evidentid/tprm-portal-lib/models/entity-details/EntityRequirement.model';
    import {
        VerificationRequest,
    } from '@evidentid/tprm-portal-lib/models/notification-configuration/VerificationRequest.model';
    import { TprmRequirementModel } from '@evidentid/tprm-portal-lib/models/entity-details/TprmRequirementModel.model';
    import { TprmRequirementCategory, TprmRequirementDefinition } from '@evidentid/tprm-portal-lib/models/common';
    import {
        TprmRequirementInstanceModel,
    } from '@evidentid/tprm-portal-lib/models/common/TprmRequirementInstanceModel.model';
    import { getRequirementInstanceLabel } from '@/utils/get-requirement-instance-label/getRequirementInstanceLabel';

    export default Vue.extend({
        name: 'ViewHistoricDocumentProcedure',
        components: {
            DocumentSubmissionHistoryTable,
            LogoLoader,
            Modal,
        },
        props: {
            entity: {
                type: Object as PropType<Entity>,
                required: true,
            },
            requirementModels: {
                type: Array as PropType<TprmRequirementModel[]>,
                required: true,
            },
        },
        data() {
            return {
                open: true,
                showingDocument: false,
                onEntityDetailsView: false,
            };
        },
        computed: {
            rpName(): string {
                // TODO(PRODUCT-18675): remove any after the migration to the newer version. apply to whole file
                return (this as any).$rp.current!;
            },
            store(): any {
                // TODO(PRODUCT-18675): remove any after the migration to the newer version. apply to whole file
                return (this as any).$store;
            },
            loading(): boolean {
                return this.loadingVerifications ||
                    this.loadingRequirementDetails;
            },
            loadingVerifications(): boolean {
                return this.verificationsStatus.status === OperationStatus.loading;
            },
            loadingRequirementDetails(): boolean {
                return this.requirementDetailsStatus.status === OperationStatus.loading;
            },
            requirementDefinitions(): Record<TprmRequirementType, TprmRequirementDefinition> {
                return this.store.state.dashboard.tprmRequirementsDefinitions || {};
            },
            requirementInstanceModels(): Record<TprmRequirementCategory, TprmRequirementInstanceModel[]> {
                return this.store.state.dashboard.requirementInstanceModels || {};
            },
            verificationsStatus(): { status: OperationStatus, list: VerificationRequest[] } {
                return this.store.state.entityDetails.verificationRequestsStatus[this.rpName] || {
                    status: OperationStatus.uninitialized,
                    list: [],
                };
            },
            requirementDetailsStatus(): { status: OperationStatus, list: EntityRequirementDetails[] } {
                return this.store.state.entityDetails.requirementDetailsListStatus[this.rpName] || {
                    status: OperationStatus.uninitialized,
                    list: [],
                };
            },
            historicDocumentSubmissions(): HistoricDocumentSubmission[] {
                const submissions = [] as HistoricDocumentSubmission[];
                this.verificationsStatus.list.forEach((verification) => {
                    const pendingSubmission = !verification.inputReceivedAt && !verification.timeoutAt;
                    if (!pendingSubmission) {
                        const notProvidedRequirementTypes = this.getNotProvidedRequirementTypes(verification);
                        const currentMap: Record<string, TprmRequirementType[]> =
                            this.requirementDetailsStatus.list.reduce((accu, details) => {
                                // coverage details' request id IS verification id
                                const verificationId = details.coverage?.requestId;
                                const types = verificationId && accu[verificationId] || [];
                                return {
                                    ...accu,
                                    ...(verificationId && { [verificationId]: [ ...types, details.coverageType ] }),
                                };
                            }, {} as Record<string, TprmRequirementType[]>);
                        verification.reasons.forEach(
                            (reason) => {
                                const isCurrentSubmission =
                                    currentMap[verification.verificationId]?.includes(reason.coverageType) || false;
                                submissions.push(this.constructSubmission({
                                    verification, requirementType: reason.coverageType, notProvidedRequirementTypes,
                                    isCurrentSubmission, requirementInstanceId: reason.instanceId,
                                }));
                            },
                        );
                    }
                });
                return orderBy(
                    submissions,
                    [ 'requestDate', 'coverageTypeLabel', 'requirementInstanceLabel' ],
                    [ 'desc', 'asc', 'asc' ],
                );
            },
            additionalClasses(): string {
                const hide = this.showingDocument ? 'invisible' : '';
                return `ViewHistoricDocumentModal ${hide}`;
            },
        },
        mounted(): void {
            this.onEntityDetailsView = Boolean(this.$route.name === 'dashboard' && this.$route.query.entity);
            this.store.actions.entityDetails.loadVerificationRequests({
                rpName: this.rpName,
                entityId: this.entity.id,
            });
            if (!this.onEntityDetailsView) {
                this.store.actions.entityDetails.loadRequirementDetailsList({
                    rpName: this.rpName,
                    entityId: this.entity.id,
                });
            }
        },
        destroyed(): void {
            this.store.actions.entityDetails.clearVerificationRequests({ rpName: this.rpName });
            if (!this.onEntityDetailsView) {
                this.store.actions.entityDetails.clearRequirementDetailsList({ rpName: this.rpName });
            }
        },
        methods: {
            close(): void {
                this.$emit('abort');
                this.open = false;
            },
            showDocument(submission: HistoricDocumentSubmission): void {
                this.showingDocument = true;
                // TODO(PRODUCT-18675): remove "as any" after the migration to the newer version. apply to whole file
                (this as any).$procedures.execute('showEntityRequirementRprDocuments', {
                    rpName: this.rpName,
                    email: this.entity?.contactEmail || '',
                    requirementType: submission.coverageType,
                    requirementTypeLabel: submission.coverageTypeLabel,
                    requirementInstanceId: submission.requirementInstanceId,
                    requirementInstanceLabel: submission.requirementInstanceLabel,
                    rprId: submission.rprId,
                    submissionDate: submission.submissionDate,
                }, this.onDocumentClosed);
            },
            onDocumentClosed(): void {
                this.showingDocument = false;
            },
            getNotProvidedRequirementTypes(verification: VerificationRequest): TprmRequirementType[] {
                const requestedRequirementTypes = difference(
                    verification.reasons.map((x) => x.coverageType),
                    verification.cancelledCoverageTypes,
                );
                return difference(
                    requestedRequirementTypes,
                    verification.extractedCoverageTypes,
                );
            },
            constructSubmission(
                {
                    verification, requirementType, notProvidedRequirementTypes, isCurrentSubmission,
                    requirementInstanceId,
                }: {
                    verification: VerificationRequest;
                    requirementType: TprmRequirementType;
                    notProvidedRequirementTypes: TprmRequirementType[];
                    isCurrentSubmission: boolean;
                    requirementInstanceId?: string | null;
                },
            ): HistoricDocumentSubmission {
                const requirementInstanceLabel = requirementInstanceId
                    ? getRequirementInstanceLabel(
                        requirementType,
                        this.requirementDefinitions,
                        requirementInstanceId,
                        this.requirementInstanceModels,
                    )
                    : null;
                // this model is passed externally, we need to prefer due to dashboard.vue pass in country specific
                // labels, we should eventually add such into to the model definitions on the BE and hanle all here
                const preferredLabel = this.requirementModels.find(
                    (x) => x.coverageType === requirementType,
                )?.label;
                const labelByDefinition = this.requirementDefinitions[requirementType]?.defaultLabel;
                const requirementTypeLabel = preferredLabel || labelByDefinition || requirementType;
                return {
                    requestDate: verification.createdAt,
                    submissionDate: verification.inputReceivedAt,
                    coverageType: requirementType,
                    coverageTypeLabel: requirementTypeLabel,
                    rprId: verification.requestId,
                    verificationId: verification.verificationId,
                    insuredId: this.entity.id,
                    isNoResponse: Boolean(verification.timeoutAt) || !verification.inputReceivedAt,
                    isNotProvided: notProvidedRequirementTypes.includes(requirementType),
                    isCurrentSubmission,
                    declineReason: verification.declinedCoverageTypes?.[requirementType],
                    submissionMethod: verification.submissionMethod,
                    requirementInstanceId,
                    requirementInstanceLabel,
                };
            },
        },
    });
</script>

<template>
    <div class="CustomPropertiesConfiguration">
        <div class="CustomPropertiesConfiguration__description">
            <div class="CustomPropertiesConfiguration__body">
                <span>Add up to </span>
                <strong>3 additional properties </strong>
                <span>to the table displayed on the dashboard. The properties will display in order on the table. Drag and drop the fields you’ve added below to customize the order in which they’ll appear on the dashboard.</span>
            </div>
        </div>
        <div class="CustomPropertiesConfiguration__actions">
            <div class="CustomPropertiesConfiguration__insuredFieldSelectors">
                <Draggable
                    v-model="customPropertySelectors"
                    handle=".DraggableItem__handle"
                    @input="updateSelectedProperty"
                >
                    <DraggableItem v-for="(selector, index) in customPropertySelectors" :key="selector.id">
                        <Dropdown
                            :options="selector.options"
                            :selected="selector.selected ? [selector.selected] : []"
                            empty-message="No more Properties"
                            placeholder="Select Property"
                            @input="selectProperty(index, $event)"
                        />
                        <template #actionable>
                            <div
                                class="CustomPropertiesConfiguration__deleteIcon"
                                @click="deleteSelector(index)"
                            >
                                <FontAwesomeIcon :icon="faTimes" />
                            </div>
                        </template>
                    </DraggableItem>
                </Draggable>
            </div>

            <div v-tooltip="noCustomPropertyMsg">
                <Button
                    v-if="showAddButton"
                    class="CustomPropertiesConfiguration__addButton"
                    :icon="faPlus"
                    :disabled="customProperties.length === 0"
                    @click="addCustomPropertySelector"
                >
                    <strong>ADD</strong>
                </Button>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import { Component, Vue, Prop, Watch } from '@evidentid/vue-property-decorator';
    import differenceWith from 'lodash/differenceWith';
    import intersectionWith from 'lodash/intersectionWith';
    import isEqual from 'lodash/isEqual';
    import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import Dropdown from '@evidentid/dashboard-commons/components/Dropdown/Dropdown.vue';
    import Draggable from '@evidentid/dashboard-commons/components/Draggable/Draggable.vue';
    import DraggableItem from '@evidentid/dashboard-commons/components/Draggable/DraggableItem.vue';
    import { DropdownOption } from '@evidentid/dashboard-commons/components/Dropdown/types';
    import { CustomProperty } from '@evidentid/tprm-portal-lib/models/dashboard';

    const customPropertyToOption = (field: CustomProperty): DropdownOption => ({
        value: field.id,
        label: field.name,
    });
    const customPropertiesToOptions =
        (fields: CustomProperty[]): DropdownOption[] => fields.map(customPropertyToOption);

    const randomId = (): string => `${Math.random()}.${Math.random()}`;

    interface CustomPropertySelector {
        id: string;
        selected?: DropdownOption;
        options: DropdownOption[];
    }

    @Component({
        components: { Button, Dropdown, Draggable, DraggableItem, FontAwesomeIcon },
    })
    export default class CustomPropertiesConfiguration extends Vue {
        @Prop({ type: Array, default: () => [] })
        private customProperties!: CustomProperty[];

        @Prop({ type: Array, default: () => [] })
        private selectedCustomPropertyIds!: string[];

        private faPlus = faPlus;
        private faTimes = faTimes;
        private customPropertySelectors: CustomPropertySelector[] = [];
        private localSelectedProperties: (CustomProperty | null)[] = [];
        private customPropertyMap: Record<string, CustomProperty> = {};

        @Watch('customProperties', { immediate: true })
        private onCustomPropertiesChange(): void {
            this.customPropertyMap = this.customProperties.reduce((acc, field) => ({ ...acc, [field.id]: field }), {});
            this.initLocalVariables();
        }

        @Watch('selectedCustomProperties', { immediate: true })
        private onSelectedCustomPropertyIdsChange(): void {
            this.initLocalVariables();
        }

        private get availableCustomProperties(): CustomProperty[] {
            return differenceWith(this.customProperties, this.localSelectedProperties, isEqual);
        }

        private get availableCustomFieldOptions(): DropdownOption[] {
            return this.availableCustomProperties.map(customPropertyToOption);
        }

        private get noCustomPropertyMsg(): string {
            return this.customProperties.length === 0 ? 'There are no available Custom Properties' : '';
        }

        private get showAddButton(): boolean {
            const maxCount = Math.min(this.customProperties.length, 3);
            // showing add button when custom properties === 0 as there is disabled style and tooltip to handle it
            return this.customProperties.length === 0 || this.customPropertySelectors.length < maxCount;
        }

        private customPropertyToSelector(field: CustomProperty | null): CustomPropertySelector {
            if (field === null) {
                return this.createCustomPropertySelector();
            }
            const fields = intersectionWith(
                this.customProperties, [ field, ...this.availableCustomProperties ], isEqual);
            return {
                id: randomId(),
                selected: customPropertyToOption(field),
                options: customPropertiesToOptions(fields),
            };
        }

        private createCustomPropertySelector(): CustomPropertySelector {
            return { id: randomId(), options: [ ...this.availableCustomFieldOptions ] };
        }

        private addCustomPropertySelector() {
            if (this.customPropertySelectors.length < 3) {
                this.customPropertySelectors = [ ...this.customPropertySelectors, this.createCustomPropertySelector() ];
                this.localSelectedProperties = [ ...this.localSelectedProperties, null ];
            }
        }

        private selectProperty(index: number, option: DropdownOption): void {
            const modifiedSelector = { ...this.customPropertySelectors[index], selected: option };
            this.customPropertySelectors = [
                ...this.customPropertySelectors.slice(0, index),
                modifiedSelector,
                ...this.customPropertySelectors.slice(index + 1) ];
            this.updateSelectedProperty();
        }

        private updateSelectedProperty(): void {
            this.localSelectedProperties = this.customPropertySelectors
                .map((selector) => this.customPropertyMap[selector.selected?.value] || null);
            this.$emit('input', this.localSelectedProperties.map((field) => (field ? field.id : '')));
        }

        private deleteSelector(index: number): void {
            this.customPropertySelectors = [
                ...this.customPropertySelectors.slice(0, index),
                ...this.customPropertySelectors.slice(index + 1),
            ];
            this.updateSelectedProperty();
        }

        private initLocalVariables(): void {
            this.localSelectedProperties = this.selectedCustomPropertyIds
                .map((id) => this.customPropertyMap[id] || null);
            this.customPropertySelectors = this.localSelectedProperties.map(this.customPropertyToSelector);
        }
    }
</script>

<template>
    <Row class="BulkImportCorrectionTableRow">
        <BulkImportCorrectionTableCell
            v-for="property in properties"
            :id="`${property.form.key}_${property.name}_${index}`"
            :key="property.name"
            :entity-prop-extension="getPropertyExtension(property.name)"
            :data-test-id="`BulkImportCorrectionTableRow__${property.name}Cell`"
            :form-property="property"
            :value="value"
            @input="onFieldChanged($event, property.name)"
        />
    </Row>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import { Row } from '@evidentid/dashboard-commons/components/Table';
    import { JsonFormObject, JsonFormProperty } from '@evidentid/json-schema/interfaces/JsonForm';
    import BulkImportCorrectionTableCell
        from '@/modules/entity-management/components/BulkImportCorrectionTableCell/BulkImportCorrectionTableCell.vue';
    import { CustomPropertyValues } from '@evidentid/tprm-portal-lib/models/dashboard/CustomProperty.model';
    import { EntityInput } from '@evidentid/tprm-portal-lib/models/dashboard';
    import { EntityException } from '@evidentid/tprm-portal-lib/models/entity-details';

    enum EntityPropExtension {
        insuredFields = 'insuredFields',
        exceptions = 'exceptions',
    }

    export default Vue.extend({
        name: 'BulkImportCorrectionTableRow',
        components: {
            Row,
            BulkImportCorrectionTableCell,
        },
        props: {
            form: {
                type: Object as PropType<JsonFormObject>,
                default: () => ({}) as JsonFormObject,
            },
            value: {
                type: Object as PropType<EntityInput>,
                default: () => ({}) as EntityInput,
            },
            index: {
                type: Number as PropType<number>,
                default: 0,
            },
            properties: {
                type: Array as PropType<JsonFormProperty[]>,
                default: () => [] as JsonFormProperty[],
            },
            listOfCustomPropertiesPropNames: {
                type: Array as PropType<string[]>,
                default: () => [] as string[],
            },
            listOfExceptionPropNames: {
                type: Array as PropType<string[]>,
                default: () => [] as string[],
            },
        },
        methods: {
            update(data: Partial<EntityInput>): void {
                this.$emit('input', this.form.getValue({ ...this.value, ...data }, true));
            },
            onFieldChanged(value: any, propertyName: string): void {
                const extensionKey = this.getPropertyExtension(propertyName) as keyof EntityInput;
                if (extensionKey) {
                    const extensionValue =
                        this.value[extensionKey] as EntityException | CustomPropertyValues;
                    this.update({ [extensionKey]: { ...extensionValue, [propertyName]: value } });
                } else {
                    this.update({ [propertyName]: value });
                }
            },
            getPropertyExtension(propertyName: string): EntityPropExtension | null {
                if (this.listOfExceptionPropNames.includes(propertyName)) {
                    return EntityPropExtension.exceptions;
                } else if (this.listOfCustomPropertiesPropNames.includes(propertyName)) {
                    return EntityPropExtension.insuredFields;
                } else {
                    return null;
                }
            },
        },
    });
</script>

<template>
    <LoadingModal
        v-if="loading"
        header="Bulk Import Entities"
        open
        @close="close"
    />
    <ConfirmUpdateBulkEntitiesModal
        v-else-if="existedEntities.length > 0"
        :entities="existedEntities"
        :saving="saving"
        :saving-percentage="updateEntitiesStatus.progress"
        :success-count="successCount"
        :external-error="updateError"
        @close="abortUpdate"
        @updateEntities="updateEntities"
    />
    <BulkImportEntitiesModal
        v-else-if="opened"
        :custom-properties="customPropertiesStatus.list"
        :requirement-types="nonMultiInstanceRequirementTypes"
        :saving="saving"
        :saving-percentage="addEntitiesStatus.progress"
        :external-alert-config="alertConfig"
        @close="close"
        @submit="addEntities"
        @input="clearAlert"
    />
</template>
<style lang="scss">
    .BulkImportEntitiesModal, .BulkImportCorrectionTable, .ConfirmUpdateBulkEntitiesModal {
        .Alert {
            min-height: 50px;
            padding: 12px 20px;
            display: flex;
            align-items: center;
            justify-content: center;

            > h4:first-child {
                font-size: 1em;
            }

            svg {
                font-size: 1em;
                margin-right: 5px;
            }

            &__description {
                font-weight: 500;
            }
        }
    }
</style>
<script lang="ts">
    import { Component, Prop, Vue, Watch } from '@evidentid/vue-property-decorator';
    import { OperationError } from '@evidentid/rpweb-api-client/models';
    import { OperationStatus } from '@evidentid/vue-commons/store/OperationStatus';
    import { AlertType } from '@evidentid/dashboard-commons/components/Alert/types';
    import LoadingModal from '@/components/loading-modal/LoadingModal.vue';
    import { BatchAddEntitiesStatus, BatchUpdateEntitiesStatus } from '@/modules/entity-management/vuex';
    import BulkImportEntitiesModal from '../components/BulkImportEntitiesModal/BulkImportEntitiesModal.vue';
    import ConfirmUpdateBulkEntitiesModal
        from '@/modules/entity-management/components/ConfirmUpdateBulkEntitiesModal/ConfirmUpdateBulkEntitiesModal.vue';
    import { convertEntityInputDataToView } from '@/modules/entity-management/utils/entityProcessing';
    import { CustomProperty, EntityInput } from '@evidentid/tprm-portal-lib/models/dashboard';
    import { TprmRequirementDefinition } from '@evidentid/tprm-portal-lib/models/common';

    @Component({
        components: { BulkImportEntitiesModal, LoadingModal, ConfirmUpdateBulkEntitiesModal },
    })

    export default class BulkImportEntitiesProcedure extends Vue {
        @Prop({ type: String, default: '' })
        private rpName!: string;

        private opened: boolean = true;
        private saving: boolean = false;
        private successCount: number = 0;
        private failedOnRequestCount: number = 0;
        private alertConfig: { type: AlertType, title: string } | null = null;
        private existedEntities: EntityInput[] = [];
        private sampleEntities: EntityInput[] = [];
        private updateError: string = '';
        private needCollectEvidence: boolean = false;

        private clearAlert(): void {
            this.alertConfig = null;
        }

        private showUploadModalAlert(title: string): void {
            this.alertConfig = { type: 'danger', title };
        }

        @Watch('addEntitiesStatus')
        private async onAddEntitiesStatusChange(addEntitiesStatus: BatchAddEntitiesStatus) {
            this.saving = addEntitiesStatus.status === OperationStatus.loading;
            if (addEntitiesStatus.status === OperationStatus.success) {
                if (addEntitiesStatus.failures.length === 0) {
                    this.$emit('finish', {
                        sampleEntities: this.sampleEntities,
                        collectEvidence: this.needCollectEvidence,
                    });
                } else if (addEntitiesStatus.failures.every(
                    (failure) => failure.error.error === OperationError.unknown)) {
                    this.showUploadModalAlert('Cannot connect the entities, unknown errors occurred.');
                } else {
                    this.existedEntities = addEntitiesStatus.failures
                        .filter((failure) => failure.error.error === OperationError.keyConflict)
                        .map((failure) => convertEntityInputDataToView(failure.element));
                    this.successCount = addEntitiesStatus.successCount;
                }
                this.failedOnRequestCount = addEntitiesStatus.failedOnRequestCount;
                if (this.failedOnRequestCount > 0) {
                    const entityWord = this.failedOnRequestCount > 1 ? 'entities' : 'entity';
                    this.updateError = `${this.failedOnRequestCount} ${entityWord} cannot be connected due to unknown errors.`;
                }
            } else if (addEntitiesStatus.status === OperationStatus.error) {
                this.showUploadModalAlert('Sorry, something went wrong during add entities. Please try again later.');
            }
        }

        @Watch('updateEntitiesStatus')
        private async onUpdateEntitiesStatusChange(updateEntitiesStatus: BatchAddEntitiesStatus) {
            this.saving = updateEntitiesStatus.status === OperationStatus.loading;
            if (updateEntitiesStatus.status === OperationStatus.success) {
                if (updateEntitiesStatus.failures.length > 0 &&
                    updateEntitiesStatus.failures.every((x) => x.error.error === OperationError.unknown)) {
                    this.updateError = 'Cannot update the entities, unknown errors occurred.';
                } else {
                    this.$emit('finish', {
                        sampleEntities: this.sampleEntities,
                        collectEvidence: this.needCollectEvidence,
                    });
                }
            } else if (updateEntitiesStatus.status === OperationStatus.error) {
                this.updateError = 'Sorry, something went wrong during update entities. Please try again later.';
            }
        }

        private get addEntitiesStatus(): BatchAddEntitiesStatus {
            return this.$store.state.entityManagement.addEntitiesStatus[this.rpName] || {
                status: OperationStatus.uninitialized,
                progress: 0,
                count: 0,
                successCount: 0,
                successes: [],
                failures: [],
            };
        }

        private get updateEntitiesStatus(): BatchUpdateEntitiesStatus {
            return this.$store.state.entityManagement.updateEntitiesStatus[this.rpName] || {
                status: OperationStatus.uninitialized,
                progress: 0,
                successCount: 0,
                failureCount: 0,
                totalCount: 0,
                successes: [],
                failures: [],
            };
        }

        private get customPropertiesStatus(): { status: OperationStatus, list: CustomProperty[] } {
            return this.$store.state.dashboard.customProperties[this.rpName] || {
                status: OperationStatus.uninitialized,
                list: [],
            };
        }

        private get clientRequirementTypes(): { status: OperationStatus, list: string[] } {
            return this.$store.state.dashboard.clientRequirementTypes[this.rpName] || {
                status: OperationStatus.uninitialized,
                list: [],
            };
        }

        private get requirementDefinitions(): Record<string, TprmRequirementDefinition> {
            return this.$store.state.dashboard.tprmRequirementsDefinitions || {};
        }

        private get nonMultiInstanceRequirementTypes(): string[] {
            return this.clientRequirementTypes.list.filter(
                (type) => !this.requirementDefinitions[type]?.multiInstance,
            );
        }

        private get loading(): boolean {
            return this.customPropertiesStatus.status === OperationStatus.loading
                || this.clientRequirementTypes.status === OperationStatus.loading;
        }

        private mounted() {
            if (this.customPropertiesStatus.status !== OperationStatus.loading) {
                this.$store.actions.dashboard.loadCustomProperties({ rpName: this.rpName });
            }
            if (this.clientRequirementTypes.status !== OperationStatus.loading) {
                this.$store.actions.dashboard.loadClientRequirementTypes({ rpName: this.rpName });
            }
        }

        private close(): void {
            this.opened = false;
            this.$emit('abort');
        }

        private abortUpdate(): void {
            this.$emit('finish', this.sampleEntities);
        }

        private updateEntities(entities: EntityInput[]) {
            this.$store.actions.entityManagement.updateEntities({
                rpName: this.rpName,
                entities,
            });
        }

        private addEntities(
            entities: EntityInput[],
            sampleEntities: EntityInput[],
            hasPastExpiration: boolean,
        ) {
            this.sampleEntities = sampleEntities;
            this.needCollectEvidence = hasPastExpiration;
            this.$store.actions.entityManagement.addEntities({ rpName: this.rpName, entities });
        }
    }
</script>

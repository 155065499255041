<template>
    <div class="PopoverCustomPropertyFilters">
        <div class="PopoverCustomPropertyFilters__sectionTitle">
            Custom Properties
        </div>
        <div>
            <div
                v-for="{ id, name, schema, key, description } in customPropertiesWithQueryKey"
                :key="id"
                class="PopoverCustomPropertyFilters__container"
                :class="{'PopoverCustomPropertyFilters__container--full': schema.type === 'object'}"
            >
                <CustomPropertyFilterField
                    :key="id"
                    :data-test-id="`PopoverCustomPropertyFilters__${getCustomPropertyKeyFromQueryKey(key)}`"
                    :value="getFilterValue(key, schema)"
                    :type="schema.type"
                    :label="name"
                    :placeholder="description || `Type ${name}`"
                    :schema="schema"
                    :filter-key="key"
                    @input="inputFilter"
                    @scroll-to="emitScrollDistance"
                />
            </div>
        </div>
    </div>
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue';
    import {
        getCustomPropertyKeyFromQueryKey,
        getCustomPropertyQueryKey,
    } from '@/modules/entity-filtering/utils/entityFilterUtils';
    import CustomPropertyFilterField
        from '@/modules/entity-filtering/components/EntityFiltersPopover/CustomPropertyFilterField/CustomPropertyFilterField.vue';
    import JsonSchema from '@evidentid/json-schema/interfaces/JsonSchema';
    import { isObject } from '@evidentid/json-schema/schemaChecks';
    import { CustomPropertyFilterInputData } from '@/modules/entity-filtering/models/CustomPropertyFilterInputData.model';
    import { CustomProperty, CustomPropertyType } from '@evidentid/tprm-portal-lib/models/dashboard';

    export default Vue.extend({
        name: 'PopoverCustomPropertyFilters',
        components: {
            CustomPropertyFilterField,
        },
        props: {
            filters: {
                type: Object as PropType<Record<string, string>>,
                default: () => ({}) as Record<string, string>,
            },
            customProperties: {
                type: Array as PropType<CustomProperty[]>,
                default: () => [] as CustomProperty[],
            },
        },
        data() {
            return {
                customPropertiesWithQueryKey: [] as CustomProperty[],
            };
        },
        watch: {
            customProperties: {
                immediate: true,
                handler(newValue: CustomProperty[]) {
                    // TODO: remove filter once BE SUPPORTS ListEnum filtering
                    this.customPropertiesWithQueryKey = newValue.map((field) => ({
                        ...field,
                        key: getCustomPropertyQueryKey(field.key),
                    })).filter((field) => field.type !== CustomPropertyType.credentialTypes);
                },
            },
        },
        methods: {
            getCustomPropertyKeyFromQueryKey,
            inputFilter({ filterKey, value }: CustomPropertyFilterInputData): void {
                this.$emit('input', { ...this.filters, [filterKey]: value });
            },
            emitScrollDistance(distance: number): void {
                this.$emit('scroll-to', distance);
            },
            getFilterValue(key: string, schema: JsonSchema): any {
                if (isObject(schema)) {
                    return Object.keys(schema.properties).reduce((acc, propName) => {
                        const combinedKey = `${key}.${propName}`;
                        const val = this.filters[combinedKey];
                        if (val) {
                            acc[combinedKey] = val;
                        }
                        return acc;
                    }, {} as Record<string, any>);
                } else {
                    return this.filters[key] || null;
                }
            },
        },
    });
</script>

import { render, staticRenderFns } from "./ApiSettings.vue?vue&type=template&id=3c79196f"
import script from "./ApiSettings.vue?vue&type=script&lang=ts"
export * from "./ApiSettings.vue?vue&type=script&lang=ts"
import style0 from "./ApiSettings.vue?vue&type=style&index=0&id=3c79196f&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.13_babel-core@7.0.0-bridge.0_@babel+core@7.26.0__css_yn7p3up3wisoxbfwvppfso2v34/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
<template>
    <div class="JsonSchemaPhoneView">
        <div class="JsonSchemaPhoneView__title">
            {{ form.schema.title }}
        </div>
        <div class="JsonSchemaPhoneView__valueWithError">
            <div class="JsonSchemaPhoneView__value" :class="displayedValueAdditionalClass">
                {{ displayValue }}
            </div>
            <EvaluationError
                v-if="evaluationError && !evaluationError.isMultiFieldCriterion"
                :evaluation-error="evaluationError"
                v-on="$listeners"
            />
        </div>
    </div>
</template>

<script lang="ts">
    import { Component } from '@evidentid/vue-property-decorator';
    import { JsonSchemaString } from '@evidentid/json-schema/interfaces/JsonSchema';
    import { JsonFormBase } from '@evidentid/json-schema/interfaces/JsonForm';
    import EvaluationError from '@/modules/entity-details/components/EvaluationError/EvaluationError.vue';
    import AbstractJsonSchemaView
        from '@/modules/entity-details/components/JsonSchemaView/elements/AbstractJsonSchemaView';

    @Component({
        components: { EvaluationError },
        inheritAttrs: false,
    })
    export default class JsonSchemaPhoneView extends AbstractJsonSchemaView<JsonFormBase<JsonSchemaString>, string> {
        private get displayValue(): string {
            // @ts-ignore:
            return window.intlTelInputUtils.formatNumber(
                this.value, null, window.intlTelInputUtils.numberFormat.NATIONAL);
        }
    }
</script>

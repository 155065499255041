<template>
    <Modal :additional-class-name="className" open @close="$emit('close')">
        <template #header>
            Mapping
        </template>

        <LogoLoader v-if="progress" class="h-full w-full flex items-center justify-center" />
        <slot v-else />

        <template #footer>
            <Button
                v-if="!progress"
                class="w-32"
                data-test-id="CollateralMappingModal__saveButton"
                type="primary"
                @click="$emit('input', true)"
            >
                Save
            </Button>
        </template>
    </Modal>
</template>

<script lang="ts">
    import { Vue } from '@evidentid/vue-property-decorator';
    import { Modal } from '@evidentid/dashboard-commons/components/Modal';
    import { Button } from '@evidentid/dashboard-commons/components/Button';
    import { LogoLoader } from '@evidentid/dashboard-commons/components/LogoLoader';
    import { PropType } from 'vue';

    const componentName = 'CollateralMappingModal';
    export default Vue.extend({
        name: componentName,
        components: {
            Button,
            LogoLoader,
            Modal,
        },
        props: {
            progress: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
            additionalClassName: {
                type: String as PropType<string>,
                default: '',
            },
        },
        computed: {
            className(): string {
                const additionalClassName = this.additionalClassName ? ` ${this.additionalClassName}` : '';
                return `${componentName}${additionalClassName}`;
            },
        },
    });
</script>

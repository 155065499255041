import { TprmRequirementCategory } from '@evidentid/tprm-portal-lib/models/common';
import {
    TprmRequirementInstanceModel,
} from '@evidentid/tprm-portal-lib/models/common/TprmRequirementInstanceModel.model';
import { getInstanceModelPerCategory } from '@/utils/get-requirement-instance-label/getRequirementInstanceLabel';

export function parseFieldValueByInstanceModel(
    fieldName: string,
    fieldValue: string,
    instanceModels?: Record<TprmRequirementCategory, TprmRequirementInstanceModel[]>
): any {
    if (instanceModels) {
        if (fieldName === 'credentialType') {
            return getInstanceModelPerCategory(
                TprmRequirementCategory.credentials, fieldValue, instanceModels
            )?.displayName || fieldValue;
        }
    }
    return fieldValue;
}

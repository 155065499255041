import {
    addCommonFailTracker,
    addCommonFinishTracker,
    addCommonStartTracker,
} from '@/utils/vuex-common/addCommonRequestTracker';

export function observeTrackAndLog(
    app: any,
    mutationsToObserve: { start?: string, finish?: string, fail?: string },
    text: { module: string, title: string, action: string }
): void {
    const { module, title, action } = text;
    if (mutationsToObserve.start) {
        app.observer.onMutation(mutationsToObserve.start,
            (x: any) => addCommonStartTracker(app, x?.rpName || null, module, title, action),
        );
    }
    if (mutationsToObserve.finish) {
        app.observer.onMutation(mutationsToObserve.finish,
            (x: any) => addCommonFinishTracker(app, x?.rpName || null, module, title, action),
        );
    }
    if (mutationsToObserve.fail) {
        app.observer.onMutation(mutationsToObserve.fail,
            (x: any) => addCommonFailTracker(app, x?.rpName || null, module, title, action),
        );
    }
}

import {
    TprmCredentialType,
    TprmRequirementCategory,
    TprmRequirementDefinition,
} from '@evidentid/tprm-portal-lib/models/common';
import { TprmRequirementType } from '@evidentid/tprm-portal-lib/models/entity-details';
import {
    TprmRequirementInstanceModel,
} from '@evidentid/tprm-portal-lib/models/common/TprmRequirementInstanceModel.model';

function getCredentialNameAndAuthority(model: TprmCredentialType): string {
    return `${model.name} - ${model.issuingAuthority}`;
}

export function getInstanceModelPerCategory(
    category: TprmRequirementCategory,
    instanceId: string,
    instanceModelsMap: Record<TprmRequirementCategory, TprmRequirementInstanceModel[]>,
): TprmRequirementInstanceModel | null {
    const instanceModels = instanceModelsMap[category];
    return instanceModels?.find((x) => x.id === instanceId) || null;
}

function getInstanceLabelPerCategory(
    category: TprmRequirementCategory,
    instanceId: string,
    instanceModelsMap: Record<TprmRequirementCategory, TprmRequirementInstanceModel[]>,
): string | null {
    const instanceModel = getInstanceModelPerCategory(category, instanceId, instanceModelsMap);
    if (instanceModel) {
        switch (category) {
            case TprmRequirementCategory.credentials:
                return getCredentialNameAndAuthority(instanceModel);
            default:
                return null;
        }
    }
    return null;
}

export function getRequirementInstanceLabel(
    reqType: TprmRequirementType,
    reqDefs: Record<TprmRequirementType, TprmRequirementDefinition>,
    instanceId: string,
    instanceModelsMap: Record<TprmRequirementCategory, TprmRequirementInstanceModel[]>,
): string | null {
    return reqDefs[reqType]
        ? getInstanceLabelPerCategory(reqDefs[reqType].category, instanceId, instanceModelsMap)
        : null;
}

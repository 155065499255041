<template>
    <div class="CriterionCurrencyInput">
        <FormElement :label="hideTitle ? null : form.schema.title">
            <template v-if="form.schema.description" #labelHint>
                <div
                    v-tooltip="form.schema.description"
                    class="CriterionEnumInput__hintIcon"
                >
                    <FontAwesomeIcon :icon="faQuestionCircle" />
                </div>
            </template>
            <FormInput :invalid="(showError || accessed) && hasError" force-error-slot force-action-slot>
                <currency-input
                    class="CurrencyInput"
                    :value="value"
                    :precision="0"
                    value-as-integer
                    currency="usd"
                    locale="en-US"
                    @input="onInput"
                    @blur="touch"
                    @focus="onFocus"
                />
                <template v-if="(showError || accessed) && error" #errorMessage>
                    {{ error }}
                </template>
                <template v-if="deletable" #actionButtons>
                    <div class="JsonSchemaForm__deleteIcon" @click="$emit('delete')">
                        <EidIcon
                            :icon-src="binIcon"
                            alt="delete"
                            :svg-props="{color: alertRedColor}"
                        />
                    </div>
                </template>
            </FormInput>
        </FormElement>
    </div>
</template>

<script lang="ts">
    import { Component } from '@evidentid/vue-property-decorator';
    import { CurrencyInput } from 'vue-currency-input';
    import binIcon from '@/assets/icons/bin-icon.svg';
    import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { JsonSchemaInteger, JsonSchemaNumber } from '@evidentid/json-schema/interfaces/JsonSchema';
    import { FormElement, FormInput } from '@evidentid/dashboard-commons/components/Form';
    import AbstractCriterionInput
        from '@/modules/decisioning-criteria/components/CriterionInputComponents/AbstractCriterionInput';
    import { JsonFormBase } from '@evidentid/json-schema/interfaces/JsonForm';
    import EidIcon from '@evidentid/dashboard-commons/components/EidIcon/EidIcon.vue';
    import { tailwindColors } from '@/styles/variables/tailwind-colors/tailwindColors';

    type JsonFormNumeric = JsonFormBase<JsonSchemaNumber> | JsonFormBase<JsonSchemaInteger>;

    @Component({
        components: { EidIcon, FormInput, CurrencyInput, FontAwesomeIcon, FormElement },
    })
    export default class CriterionCurrencyInput extends AbstractCriterionInput<JsonFormNumeric, number> {
        private binIcon = binIcon;
        private faQuestionCircle = faQuestionCircle;
        private alertRedColor = tailwindColors.eidDangerRed.DEFAULT;

        private onInput(amount: number): void {
            this.$emit('input', amount);
        }
    }
</script>

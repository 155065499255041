<template>
    <v-popover
        trigger="hover"
        :delay="{ show: 100, hide: 100 }"
        placement="right"
        popover-class="EvaluationRuleRequirementIcon__tooltip"
    >
        <FontAwesomeIcon :icon="faBalanceScaleLeft" />
        <template #popover>
            <div class="EvaluationRuleRequirementIcon__popoverContent">
                <span>
                    Entities must meet the requirements for at least one of the requirements with this Evaluation Rule badge.
                </span>
                <span>
                    If requirements aren't met, all will be marked as non-compliant.
                </span>
            </div>
        </template>
    </v-popover>
</template>
<script lang="ts">
    import { Component, Vue } from '@evidentid/vue-property-decorator';
    import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
    import { faBalanceScaleLeft } from '@fortawesome/free-solid-svg-icons';

    @Component({
        components: { FontAwesomeIcon },
    })
    export default class EvaluationRuleRequirementIcon extends Vue {
        private faBalanceScaleLeft = faBalanceScaleLeft;
    }
</script>

export enum TprmRequirementType {
    autoInsurance = 'AUTO_INSURANCE',
    aviationLiability = 'AVIATION_LIABILITY',
    baileesInsurance = 'BAILEES_INSURANCE',
    barberOrHairDesign = 'BARBER_OR_HAIR_DESIGN',
    boatowners = 'BOATOWNERS',
    buildersRisk = 'BUILDERS_RISK',
    businessAutoLiability = 'BUSINESS_AUTO_LIABILITY',
    businessContactAffiliation = 'BUSINESS_CONTACT_AFFILIATION',
    businessEntityResolution = 'BUSINESS_ENTITY_RESOLUTION',
    businessRegistration = 'BUSINESS_REGISTRATION',
    businessRegulatoryComplianceOrWatchlist = 'BUSINESS_REGULATORY_COMPLIANCE_OR_WATCHLIST',
    businessWebPresence = 'BUSINESS_WEB_PRESENCE',
    cargoLiability = 'CARGO_LIABILITY',
    climbing = 'CLIMBING',
    collectivePersonalAccidentInsurance = 'COLLECTIVE_PERSONAL_ACCIDENT_INSURANCE',
    commercialAutoPhysicalDamage = 'COMMERCIAL_AUTO_PHYSICAL_DAMAGE',
    commercialCrimeLiability = 'COMMERCIAL_CRIME_LIABILITY',
    commercialGeneralLiability = 'COMMERCIAL_GENERAL_LIABILITY',
    commercialProperty = 'COMMERCIAL_PROPERTY',
    commercialTrailerInterchange = 'COMMERCIAL_TRAILER_INTERCHANGE',
    contingentAutoLiability = 'CONTINGENT_AUTO_LIABILITY',
    contingentCargoLiability = 'CONTINGENT_CARGO_LIABILITY',
    cosmetologist = 'COSMETOLOGIST',
    contract = 'CONTRACT',
    craneLiability = 'CRANE_LIABILITY',
    cropInsurance = 'CROP_INSURANCE',
    cyberLiability = 'CYBER_LIABILITY',
    declarationsPage = 'DECLARATIONS_PAGE',
    directorsAndOfficersLiability = 'DIRECTORS_AND_OFFICERS_LIABILITY',
    driversLicense = 'DRIVERS_LICENSE',
    dwelling = 'DWELLING',
    earthquakeInsurance = 'EARTHQUAKE_INSURANCE',
    employeeDishonesty = 'EMPLOYEE_DISHONESTY',
    employersLiability = 'EMPLOYERS_LIABILITY',
    employmentPracticesLiability = 'EMPLOYMENT_PRACTICES_LIABILITY',
    environmentalLiability = 'ENVIRONMENTAL_LIABILITY',
    esthetician = 'ESTHETICIAN',
    farmowners = 'FARMOWNERS',
    fleet = 'FLEET',
    floodInsurance = 'FLOOD_INSURANCE',
    flyingLicense = 'FLYING_LICENSE',
    foodServices = 'FOOD_SERVICES',
    garageKeepersLiability = 'GARAGE_KEEPERS_LIABILITY',
    garageLiability = 'GARAGE_LIABILITY',
    holdHarmlessAgreement = 'HOLD_HARMLESS_AGREEMENT',
    homeowners = 'HOMEOWNERS',
    hospitality = 'HOSPITALITY',
    hullAndMachinery = 'HULL_AND_MACHINERY',
    inlandMarineCommercialAuto = 'INLAND_MARINE_COMMERCIAL_AUTO',
    leasedOrRentedEquipment = 'LEASED_OR_RENTED_EQUIPMENT',
    lifeInsurance = 'LIFE_INSURANCE',
    liquorLiability = 'LIQUOR_LIABILITY',
    longshoreAndHarborWorkersCompensation = 'LONGSHORE_AND_HARBOR_WORKERS_COMPENSATION',
    lossOfIncome = 'LOSS_OF_INCOME',
    manicuristOrNailTech = 'MANICURIST_OR_NAIL_TECH',
    marineGeneralLiability = 'MARINE_GENERAL_LIABILITY',
    maritimeEmployersLiability = 'MARITIME_EMPLOYERS_LIABILITY',
    massageTherapist = 'MASSAGE_THERAPIST',
    mediaLiability = 'MEDIA_LIABILITY',
    medicalErrorsAndOmissions = 'MEDICAL_ERRORS_AND_OMISSIONS',
    motorcycleInsurance = 'MOTORCYCLE_INSURANCE',
    motorcycleLicense = 'MOTORCYCLE_LICENSE',
    nonTruckingLiability = 'NON_TRUCKING_LIABILITY',
    occupationalAccident = 'OCCUPATIONAL_ACCIDENT',
    personalLiability = 'PERSONAL_LIABILITY',
    privatePassengerAuto = 'PRIVATE_PASSENGER_AUTO',
    productsAndCompletedOperations = 'PRODUCTS_AND_COMPLETED_OPERATIONS',
    professionalLiability = 'PROFESSIONAL_LIABILITY',
    proofOfPayment = 'PROOF_OF_PAYMENT',
    protectionAndIndemnity = 'PROTECTION_AND_INDEMNITY',
    publicAndProductsLiability = 'PUBLIC_AND_PRODUCTS_LIABILITY',
    raftingCertification = 'RAFTING_CERTIFICATION',
    riggersLiability = 'RIGGERS_LIABILITY',
    scheduleOfFormsAndEndorsements = 'SCHEDULE_OF_FORMS_AND_ENDORSEMENTS',
    scubaCertification = 'SCUBA_CERTIFICATION',
    sexualAbuseAndMolestationLiability = 'SEXUAL_ABUSE_AND_MOLESTATION_LIABILITY',
    specialEventUse = 'SPECIAL_EVENT_USE',
    studentAccidentMedical = 'STUDENT_ACCIDENT_MEDICAL',
    suretyBond = 'SURETY_BOND',
    technologyLiability = 'TECHNOLOGY_LIABILITY',
    thirdPartyFidelityBond = '3RD_PARTY_FIDELITY_BOND',
    tourism = 'TOURISM',
    transportation = 'TRANSPORTATION',
    umbrellaExcessLiability = 'UMBRELLA_EXCESS_LIABILITY',
    w8BenForm = 'W8_BEN_FORM',
    w9Form = 'W9_FORM',
    warehouseLegalLiability = 'WAREHOUSE_LEGAL_LIABILITY',
    watercraft = 'WATERCRAFT',
    watercraftLicense = 'WATERCRAFT_LICENSE',
    weaponsLicense = 'WEAPONS_LICENSE',
    workersCompensation = 'WORKERS_COMPENSATION',
    workersCompensationWaiver = 'WORKERS_COMPENSATION_WAIVER',
    yachtInsurance = 'YACHT_INSURANCE',
}

export type RequirementTypesPerCountry = Record<string, { coverageTypes: TprmRequirementType[] }>;
